import React from 'react';

import TagTrans from '../../TagTrans';

import './HorizontalField.scss';


const HorizontalField = ({ children, label, name, errors, labelSize, width }) => {
  const labelClass = labelSize ? `label-size-${labelSize}` : '';
  const fieldWidth = width ? { width: `${width}%` } : {};

  return (
    <div className="horizontal-field" style={fieldWidth}>
      <div className="horizontal-field-upper">
        {label && <label htmlFor={name} className={labelClass}>{label}</label>}
        {children}
      </div>
      <div className="horizontal-field-errors">
        {errors && errors.map(err => (
          <div key={err} className="horizontal-field-errors-item">{err && <TagTrans tag={err} />}</div>
        ))}
      </div>
    </div>
  );
}

export default HorizontalField;
