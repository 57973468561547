import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getItem } from '../services/database';

const useTeam = (teamId, isPublic = false) => {
  const [team, setTeam] = useState(null);
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (user) {
      const { teams } = user;

      if (teams && teams.length > 0) {
        const filteredTeams = teams.filter(t => t.id === teamId);
        const selectedTeam = filteredTeams && filteredTeams.length === 1
          ? filteredTeams[0]
          : null;
        setTeam(selectedTeam);
      }
    } else if (isPublic && teamId) {
      const fetchTeam = async () => {
        const matchTeam = await getItem('teams', teamId);
        setTeam(matchTeam)
      }
      fetchTeam();
    }
  }, [teamId, user])


  return team;
};

export default useTeam;
