import React from 'react';

import Modal from '../../Modal';
import { ButtonGrayBig } from '../../Button';
import TagTrans from '../../TagTrans';
import { ModalButtons, FlexCenter } from '../../Layout';
import { MATCH_ACTIONS } from '../../../logic/football';

import './MatchOptions.scss';

const MatchOptions = ({ show, onCancel, onOptionSelected }) => {

  return (
    <Modal show={show} onCancel={onCancel}>
      <div className="match-action-select">
        <div className="match-action-select-title"><TagTrans tag="select_option" /></div>
        <div className="match-action-select-two-buttons">
          <ButtonGrayBig onClick={() => onOptionSelected(MATCH_ACTIONS.PART_FINISHED)}><TagTrans tag="finish_part" /></ButtonGrayBig>
          <ButtonGrayBig onClick={() => onOptionSelected(MATCH_ACTIONS.MATCH_FINISHED)}><TagTrans tag="finish_match" /></ButtonGrayBig>
        </div>
        <div className="match-action-select-two-buttons">
          <ButtonGrayBig onClick={() => onOptionSelected(MATCH_ACTIONS.SUSPEND)}><TagTrans tag="suspend_match" /></ButtonGrayBig>
          <ButtonGrayBig onClick={() => onOptionSelected('exit')}><TagTrans tag="exit" /></ButtonGrayBig>
        </div>
        <div className="match-action-select-one-button">
          <ButtonGrayBig onClick={() => onOptionSelected(MATCH_ACTIONS.UNDO_LAST_ACTION)}><TagTrans tag="undo_last_action" /></ButtonGrayBig>
        </div>
      </div>
      <ModalButtons>
        <FlexCenter>
          <ButtonGrayBig onClick={onCancel}><TagTrans tag="cancel" /></ButtonGrayBig>
        </FlexCenter>
      </ModalButtons>
    </Modal>
  );
}
 
export default MatchOptions;
