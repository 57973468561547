import React from 'react';

import './Logo.scss';

const Logo = () => (
  <div className="app-logo">
    <h1>footie.</h1>
    <div>beta</div>
  </div>
);

export default Logo;
