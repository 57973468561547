import React from 'react';

import './Input.scss';

const Input = ({ name, type = 'text', value, onChange, error, size, ...otherProps }) => {
  const inputClass = size ? `size-${size}` : '';
  
  return (
    <input
      className={inputClass}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      {...otherProps}
    />
  );
};

export default Input;
