const defaultLang = window.localStorage.getItem('app-lang') || 'en';


function langReducer(state = defaultLang, action) {
  switch (action.type) {
    case 'SET_LANG': {
      window.localStorage.setItem('app-lang', action.payload);
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

export default langReducer;
