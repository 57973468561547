import React from 'react';

const BASE_PATH = process.env.PUBLIC_URL;

const ResponsiveImage = ({ imageName }) => {
  const imgPath = `${BASE_PATH}/images/icons/${imageName}`;

  return (<img src={`${imgPath}.png`} srcSet={`${imgPath}@2x.png 2x, ${imgPath}@3x.png 3x`}/>);
}
 

export default ResponsiveImage;