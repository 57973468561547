import React from 'react';

import TagTrans from '../TagTrans';
import './PlayerRole.scss';

const PlayerRole = ({ role = '' }) => {
  return (
    <div className={`player-role ${role}`}>
      <TagTrans tag={`position_${role.toLowerCase()}`} />
    </div>
  );
}
 
export default PlayerRole;
