import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useForm from '@bit/iamtechnologies.iamtech-js.use-form';

import { getAllFiltered } from '../../services/database';
import { useFetch } from '../../customHooks';
import TagTrans from '../../components/TagTrans';
import {
  MATCH_STATUS,
  MODE_OPTIONS,
  ROLE_OPTIONS,
  getMatchPlayersStats,
  getHomeAwayOptions,
  getTournamentOptions,
  getPlayerNameAndNumber
} from '../../logic/football';
import { FormField, Select } from '../../components/Form';
import { AppContainer } from '../../components/Layout';
import getTranslation from '../../logic/translations';

import './Stats.scss';

const DEFAULT_SEASON = '2020-2021';

const filterData = (filters, matches) => {
  return matches
    .filter(m => filters.team === m.team)
    .filter(m => filters.season === 'all' || filters.season === m.season || (filters.season === DEFAULT_SEASON && !m.season))
    .filter(m => filters.tournament === 'all' || filters.tournament === m.tournament || (filters.tournament === 'other' && !m.tournament))
    .filter(m => filters.homeAway === 'all' || filters.homeAway === m.homeAway)
    .filter(m => filters.mode === 'all' || filters.mode === m.mode)
}

const Stats = () => {
  const lang = useSelector(state => state.lang);
  const user = useSelector(state => state.user);
  const [playersStats, setPlayersStats] = useState(null);
  const [seasonOptions, setSeasonOptions] = useState([]);

  const defaultFilters = {
    team: user.teams[0].id,
    season: 'all',
    tournament: 'all',
    homeAway: 'all',
    mode: 'all',
  };
  const { values, useInput, isValid, errors, showErrors } = useForm(defaultFilters);

  const [isLoading, matchesData, reFetch] = useFetch(async (setData) => {
    const matches = await getAllFiltered(
      'matches',
      [
        { field: 'user', condition: '==', value: user.id },
        { field: 'status', condition: '==', value: MATCH_STATUS.FINISHED },
      ]
    );
    setData(matches);
  }, [user.id]);

  useEffect(() => {
    const allSeasons = matchesData.reduce((accSeasons, match) => {
      const matchSeasonValue = match.season || DEFAULT_SEASON;
      return accSeasons.includes(matchSeasonValue) ? accSeasons : [...accSeasons, matchSeasonValue];
    }, []);
    const matchesSeasonOptions = allSeasons.map(season => ({
      value: season, label: season.split('-').join(' - ')
    }))
    setSeasonOptions([{ value: 'all', label: getTranslation('all', lang) }, ...matchesSeasonOptions]);
  }, [matchesData]);

  useEffect(() => {
    const globalStats = filterData(values, matchesData)
      .reduce((accPlayersStats, match) => {
        const matchPlayerStats = getMatchPlayersStats(match);
        const missingMatchPlayers = accPlayersStats
          .filter((accPlayer) => !matchPlayerStats.find((matchPlayer) => matchPlayer.id === accPlayer.id));

        return [...missingMatchPlayers, ...matchPlayerStats].map(player => {
          // Player in match stats but not on accumulator -> add player to accumulator
          const currentPlayerAcc = accPlayersStats.find(accPlayer => accPlayer.id === player.id);
          if (!currentPlayerAcc) return player;
          
          // Player in accumulator but not on match stats -> keep same player from accuulator
          const currentPlayerMatch = matchPlayerStats.find(matchPlayer => matchPlayer.id === player.id);
          if (!currentPlayerMatch) return player;

          const newStats = {
            chance: currentPlayerAcc.stats.chance + player.stats.chance,
            fault: currentPlayerAcc.stats.fault + player.stats.fault,
            goal: currentPlayerAcc.stats.goal + player.stats.goal,
            minutesPlayed: currentPlayerAcc.stats.minutesPlayed + player.stats.minutesPlayed,
            redCards: currentPlayerAcc.stats.redCards + player.stats.redCards,
            yellowCards: currentPlayerAcc.stats.yellowCards + player.stats.yellowCards,
            goalRival: currentPlayerAcc.stats.goalRival + player.stats.goalRival,
            chanceRival: currentPlayerAcc.stats.chanceRival + player.stats.chanceRival,
            faultRival: currentPlayerAcc.stats.faultRival + player.stats.faultRival,
            yellowCardsRival: currentPlayerAcc.stats.yellowCardsRival + player.stats.yellowCardsRival,
            redCardsRival: currentPlayerAcc.stats.redCardsRival + player.stats.redCardsRival,
          };
          return {
            ...player,
            stats: newStats
          }
        })
      }, [])
    const sortedStats = globalStats.sort((a, b) => {
      const roleA = ROLE_OPTIONS.find(option => option.value === a.role) || { order: Infinity };
      const roleB = ROLE_OPTIONS.find(option => option.value === b.role) || { order: Infinity };

      return roleA.order - roleB.order;
    })
    setPlayersStats(sortedStats);
  }, [matchesData, values]);

  const teamInput = useInput('team');
  const tournamentInput = useInput('tournament');
  const seasonInput = useInput('season');
  const homeAwayInput = useInput('homeAway');
  const modeInput = useInput('mode');

  if (isLoading || !playersStats) return <div>Loading...</div>

  const tournamentOptions = [{ value: 'all', label: getTranslation('all', lang) }, ...getTournamentOptions(lang)];
  const homeAwayOptions = [{ value: 'all', label: getTranslation('all', lang) }, ...getHomeAwayOptions(lang)];
  const modeOptions = [{ value: 'all', label: getTranslation('all', lang) }, ...MODE_OPTIONS];

  return (
    <div className="stats-page">
      <div className="stats-filters">
        <AppContainer padding>
          <FormField
            label={`${getTranslation('team', lang)}`}
            name="team"
            errors={showErrors.team ? errors.team : []}
          >
            <Select
              options={user.teams}
              {...teamInput}
              getLabel={t => t.name}
              getValue={t => t.id}
            />
          </FormField>
          <FormField
            label={`${getTranslation('season', lang)}`}
            name="season"
            errors={showErrors.season ? errors.season : []}
          >
            <Select options={seasonOptions} {...seasonInput} />
          </FormField>
          <FormField
            label={`${getTranslation('tournament', lang)}`}
            name="tournament"
            errors={showErrors.tournament ? errors.tournament : []}
          >
            <Select options={tournamentOptions} {...tournamentInput} />
          </FormField>
          <FormField
            label={`${getTranslation('home_away', lang)}`}
            name="homeAway"
            errors={showErrors.homeAway ? errors.homeAway : []}
          >
            <Select options={homeAwayOptions} {...homeAwayInput} />
          </FormField>
          <FormField
            label={`${getTranslation('mode', lang)}`}
            name="mode"
            errors={showErrors.mode ? errors.mode : []}
          >
            <Select options={modeOptions} {...modeInput} />
          </FormField>
        </AppContainer>
      </div>
      <div className="team-report-title"><TagTrans tag="players_stats" /></div>
      <AppContainer padding>
        <table className="team-report-info-table">
          <thead>
            <tr>
              <th><TagTrans tag="player" /></th>
              <th><TagTrans tag="minutes_abrv" /></th>
              <th><TagTrans tag="goals_initials" /></th>
              <th><TagTrans tag="goals_rival_initials" /></th>
              <th><TagTrans tag="chances_initials" /></th>
              <th><TagTrans tag="faults_initials" /></th>
              <th><TagTrans tag="yellowCards_initials" /></th>
              <th><TagTrans tag="redCards_initials" /></th>
            </tr>
          </thead>
          <tbody>
            {playersStats.map(player => (
              <tr key={player.id}>
                <td>{getPlayerNameAndNumber(player)}</td>
                <td className={player.stats.minutesPlayed === 0 ? 'zero-value' : ''}>{player.stats.minutesPlayed}</td>
                <td className={player.stats.goal === 0 ? 'zero-value' : ''}>{player.stats.goal}</td>
                <td className={player.stats.goalRival === 0 ? 'zero-value' : ''}>{player.stats.goalRival}</td>
                <td className={player.stats.chance === 0 ? 'zero-value' : ''}>{player.stats.chance}</td>
                <td className={player.stats.fault === 0 ? 'zero-value' : ''}>{player.stats.fault}</td>
                <td className={player.stats.yellowCards === 0 ? 'zero-value' : ''}>{player.stats.yellowCards}</td>
                <td className={player.stats.redCards === 0 ? 'zero-value' : ''}>{player.stats.redCards}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </AppContainer>
    </div>
  )
}

export default Stats;
