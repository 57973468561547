import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getItem } from '../../services/database';
import { setMatch } from '../../redux/actions/matchActions';
import { useTeamPlayers } from '../../customHooks';
import {
  getLineupLayout,
  getInitialLineup,
} from '../../logic/football';
import { exportMatchXlsReport } from '../../logic/football/exports';
import TagTrans from '../../components/TagTrans';
import {
  ScoreboardArchive,
  TabsMenu,
  Summary,
  MatchLog
} from '../../components/Match'
import { AppContainer, ModalButtons, Separator } from '../../components/Layout';
import PlayField from '../../components/PlayField';
import ButtonDarkBig from '../../components/Button/ButtonDarkBig';
import { useTeam } from '../../customHooks';


import './MatchReport.scss';
import TeamReport from '../../components/Match/TeamReport';


const MatchReport = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const lang = useSelector(state => state.lang);

  const [currentTab, setCurrentTab] = useState('my-team');
  const [lineup, setLineup] = useState([]);
  const [currentMatch, setCurrentMatch] = useState(null);
  const team = useTeam(currentMatch?.team, true);

  useEffect(() => {
    const fetchMatch = async (id) => {
      const match = await getItem('matches', id);
      const initialLineup = getInitialLineup(match);
      refreshLineup(match.mode, match.initialFormation, initialLineup);
      dispatch(setMatch(match));
      setCurrentMatch(match);
    }
    fetchMatch(params.id);
  }, []);

  const refreshLineup = (mode, formation, lineup) => {
    const defaultLineup = getLineupLayout({ mode, formation, lineup });
    setLineup(defaultLineup);
  }

  const handleTabSelect = tab => {
    document.getElementById('match-tabs-container').scrollTo(0, 0)
    setCurrentTab(tab);
  }

  const handleExitClick = () => {
    history.push('/archive');
  }

  const handleSignupClick = () => {
    history.push('/signup');
  }

  const handleExportXls = () => {
    exportMatchXlsReport({ match: currentMatch, lineup, team, lang })
  }

  return (
    <div>
      <ScoreboardArchive />
      <TabsMenu currentTab={currentTab} onTabSelect={handleTabSelect} archive />
      <div id="match-tabs-container" className="match-tabs-container archive">
        <div className={`match-tabs-content ${currentTab}`}>
          <div className="match-tabs-item">
            <div className="match-tabs-item-title"><TagTrans tag="initialFormation" /></div>
            <AppContainer>
              <PlayField lineup={lineup} onPlayerClick={(position) => { }} noBg />
            </AppContainer>
            <TeamReport match={currentMatch} />
            <Separator />
            <Separator />
          </div>
          <div className="match-tabs-item tab-summary">
            <AppContainer>
              <Summary />
            </AppContainer>
          </div>
          <div className="match-tabs-item tab-log">
            <AppContainer>
              <MatchLog />
            </AppContainer>
          </div>
        </div>
        <ModalButtons>
          <div className="match-buttons">
            {user
              ? <ButtonDarkBig onClick={handleExitClick}><TagTrans tag="exit" /></ButtonDarkBig>
              : <ButtonDarkBig onClick={handleSignupClick}><TagTrans tag="create_account" /></ButtonDarkBig>
            }
            {user && <ButtonDarkBig onClick={handleExportXls}><TagTrans tag="export_xls" /></ButtonDarkBig>}
          </div>
        </ModalButtons>
      </div>
    </div>
  );
}

export default MatchReport;