import React from 'react';

import TagTrans from '../../TagTrans';

const SummaryRow = ({ stat, statName, mode }) => {
  const homeNumber = mode === 'home' ? stat.us : stat.rival;
  const awayNumber = mode === 'away' ? stat.us : stat.rival;
  const homeWidth = stat.total <= 0 ? 0 : ((mode === 'home' ? stat.us : stat.rival) / stat.total * 100);
  const awayWidth = stat.total <= 0 ? 0 : ((mode === 'away' ? stat.us : stat.rival) / stat.total * 100);

  return (
    <div className="match-summary-stats-row">
      <div className={`match-summary-stats-row-item number ${mode === 'home' ? 'my-team-text' : ''}`}>
        {homeNumber}
      </div>
      <div className="match-summary-stats-row-item stat-name">
        <div><TagTrans tag={statName} /></div>
        <div className="stat-bar-container">
          <div className="home">
            <div style={{width: `${homeWidth}%`}} className={`stat-bar ${mode === 'home' ? 'my-team-bar' : ''}`}/>
          </div>
          <div className="away">
            <div style={{width: `${awayWidth}%`}} className={`stat-bar ${mode === 'away' ? 'my-team-bar' : ''}`} />
          </div>
        </div>
      </div>
      <div className={`match-summary-stats-row-item number ${mode === 'away' ? 'my-team-text' : ''}`}>
        {awayNumber}
      </div>
    </div>
  );
}
 
export default SummaryRow;
