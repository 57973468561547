import firebase from 'firebase/app';
import 'firebase/auth';

async function signup(email, password) {
  try {
    const result = await firebase.auth().createUserWithEmailAndPassword(email, password);
    return { success: true, data: result.user.uid };
  } catch (error) {
    console.log('IMTCHLG ~ file: auth.js ~ line 8 ~ singup ~ error', error);
    return { success: false };
  }
}

async function login(email, password) {
  const result = await firebase.auth().signInWithEmailAndPassword(email, password);
  return !!result;
}

function logout() {
  firebase.auth().signOut();
}

function registerAuthObserver(callback) {
  return firebase.auth().onAuthStateChanged(callback);
}


export {
  signup,
  logout,
  login,
  registerAuthObserver
}