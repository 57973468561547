import React from 'react';

import './Box.scss';

const Box = ({ children, isLoading = false , classModifier }) => {
  return <div className={`app-layout-box ${classModifier ? classModifier : ''} ${isLoading ? 'loading' : ''}`}>
  {isLoading ? 'Loading' : children}
  </div>;
};

export default Box;
