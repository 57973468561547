import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { formatPlayerName, getPlayerCards } from '../../../logic/football';
import { useTeam } from '../../../customHooks';
import TagTrans from '../../TagTrans';
import {
  wistle,
  ball,
  redCard,
  yellowCard,
  substitution,
  corner,
  chance
} from '../../Icon/iconSvgs'

import './MatchLog.scss';

const icons = {
  'fault': wistle,
  'goal': ball,
  'card-red': redCard,
  'card-yellow': yellowCard,
  substitution,
  corner,
  chance,
  change_pos: substitution,
  own_goal: ball,
  match_start: wistle,
  match_paused: wistle,
  match_resumed: wistle,
  match_finished: wistle,
  part_finished: wistle,
  change_formation: substitution,
  offside: wistle,
}

const getActionIcon = (action, cards) => {
  if (action.action === 'card') {
    if (action.actionInfo.card === 'yellow' && cards.yellow > 1) {
      return <div className="log-item-icon-cards">{icons['card-yellow']}&nbsp;{icons['card-yellow']}</div>
    }
    return icons[`card-${action.actionInfo.card}`];
  }
  return icons[action.action];
}

const getActionSide = (actionType, matchType) => {
  if (matchType === 'home') {
    return actionType === 'us' ? 'left' : 'right';
  } else {
    return actionType === 'us' ? 'right' : 'left';
  }
}

const getSubstitutionPlayers = matchAction => (
  <>
    <div className="log-item-extra-info"><TagTrans tag="subs_out" />: {formatPlayerName(matchAction.player)}</div>
    <div className="log-item-extra-info"><TagTrans tag="subs_in" />: {formatPlayerName(matchAction.actionInfo.substitutionPlayer)}</div>
  </>
)

const MatchLog = () => {
  const match = useSelector(state => state.match);
  const team = useTeam(match.team, true);

  if (!team || !match.log) return null;

  const log = [...match.log];
  log.reverse();
  const playerCards = {};

  return (
    <div className="match-log">
      <div className="log-line" />
      {log.map((matchAction, i) => {
        const actionKey = `${i}-${match.type}-${match.action}-${match.part}-${match.minute}`;
        const actionSide = getActionSide(matchAction.type, match.homeAway);
        const actionClass = `log-item ${actionSide}`;
        const actionPlayerId = matchAction.player ? matchAction.player.id : 0;
        const isPenalty = matchAction.action === 'goal' && matchAction.actionInfo.shoot === 'penalty';

        // TODO: remove player check and remove match from redux when leaving match and report
        if (matchAction.action === 'card' && matchAction.player) {
          if (!playerCards[matchAction.player.id]) {
            playerCards[matchAction.player.id] = { yellow: 0, red: 0 };
          }
          playerCards[matchAction.player.id][matchAction.actionInfo.type] += 1;
        }

        return (
          <div key={actionKey} className={actionClass}>
            <div className="log-item-time">
              <div>{matchAction.part}<TagTrans tag="part_name" /></div>
              <div>{matchAction.minute}'</div>
            </div>
            <div className="log-item-icon">
              {getActionIcon(matchAction, playerCards[actionPlayerId])}
            </div>
            <div className="log-item-info">
              <div className="log-item-action">
                <TagTrans tag={`action_${matchAction.action}`} />{isPenalty && ' (P)'}
              </div>
              {matchAction.action !== 'substitution' && matchAction.player && <div className="log-item-extra-info">{formatPlayerName(matchAction.player)}</div>}
              {matchAction.action === 'substitution' && getSubstitutionPlayers(matchAction)}
              {matchAction.action === 'change_formation' &&
                <div className="log-item-extra-info">
                  <TagTrans tag="change_formation_new" />: {matchAction.actionInfo.newFormation.split('').join('-')}
                </div>
              }
              {matchAction.action === 'goal' && matchAction.actionInfo.assistancePlayer &&
                <div className="log-item-extra-info">
                  <TagTrans tag="assist" />: {formatPlayerName(matchAction.actionInfo.assistancePlayer)}
                </div>
              }
            </div>
            <div className="log-item-circle" />
          </div>
        )
      })}
    </div>
  );
}

export default MatchLog;
