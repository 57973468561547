import { combineReducers } from 'redux';

import userReducer from './reducers/userReducer';
import langReducer from './reducers/langReducer';
import matchReducer from './reducers/matchReducer';
import menuReducer from './reducers/menuReducer';

const rootReducers = combineReducers({
  user: userReducer,
  lang: langReducer,
  match: matchReducer,
  menu: menuReducer
});

export default rootReducers;
