import React from 'react';

export const home = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
  <g fill="none" fillRule="evenodd">
      <circle cx="13" cy="13" r="13" fill="#D8D8D8"/>
      <path fill="#4E5152" d="M7 19v-8l6-6 6 6v8h-4v-5h-4v5z"/>
  </g>
</svg>;

export const away = <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
  <g fill="none" fillRule="evenodd">
      <circle cx="13" cy="13" r="13" fill="#D8D8D8"/>
      <path fill="#4E5152" d="M17.744 8.818l-2.75 1.587L9.951 7.51a.446.446 0 0 0-.444.002l-1.576.91a.439.439 0 0 0-.091.696l3.525 3.383-2.474 1.429-1.827-.764a.447.447 0 0 0-.395.026l-.962.556a.439.439 0 0 0-.102.686l2.237 2.123.72 2.998c.077.274.394.4.645.255l.962-.556a.447.447 0 0 0 .22-.329l.252-1.964 2.474-1.429 1.167 4.745c.07.282.393.417.649.27l1.576-.91a.446.446 0 0 0 .224-.384l.013-5.816 2.75-1.587c.85-.491 1.918-2.012 1.434-2.849-.483-.837-2.334-.673-3.184-.182z"/>
  </g>
</svg>;

export const wistle = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" viewBox="0 0 22 17">
  <path fill="#A4B3B7" fillRule="evenodd" d="M2.035.862L13.74 4.04l-.273 1.03c2.972-1.117 6.327.277 7.63 3.24 1.358 3.083-.02 6.698-3.077 8.075-3.057 1.377-6.635-.007-7.992-3.09-.65-1.477-.673-3.076-.184-4.493L.595 6.292l1.44-5.43z"/>
</svg>;

export const ball = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
  <path fill="#A4B3B7" fillRule="evenodd" d="M18.533 7.066C17.23 3.05 13.507.5 9.503.5c-.972 0-1.96.15-2.937.467-4.988 1.62-7.72 6.98-6.099 11.967C1.77 16.95 5.493 19.5 9.497 19.5c.972 0 1.96-.15 2.937-.467 4.988-1.62 7.72-6.98 6.099-11.967zm-2.251 6.62l-2.17.226-1.818-2.151 1.015-2.657 2.956-.78 1.617 1.285c-.008 1.085-.307 2.159-.902 3.151-.195.329-.445.629-.698.926zm-1.035-6.202l-2.719.84-2.47-1.774V3.735l1.824-1c1.507.496 2.823 1.45 3.765 2.724l-.4 2.025zM8.941 3.732v2.817L6.465 8.324l-2.723-.84-.39-2.004c.938-1.275 2.234-2.237 3.773-2.745l1.816.997zM2.805 14.077c-.643-.762-1.665-2.26-1.687-4.373l1.74-1.38 3.175.842 1.085 2.817-1.942 2.34-2.37-.246zm2.607 1.303l2.095-2.586h3.776l2.129 2.586-1.077 2.017c-.297.075-2.767.855-5.847 0L5.412 15.38z"/>
</svg>;

export const redCard = <svg xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24">
  <path fill="#F9654A" fillRule="evenodd" d="M97 288.5H114V311.5H97z" transform="translate(-97 -288)"/>
</svg>

export const yellowCard = <svg xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24">
  <path fill="#FCCB1B" fillRule="evenodd" d="M97 410.5H114V433.5H97z" transform="translate(-97 -410)"/>
</svg>

export const substitution = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
  <g fill="none" fillRule="evenodd">
      <path stroke="#A4B3B7" strokeWidth="2.38" d="M16.246 5.954c-2.69-2.988-7.294-3.23-10.282-.539-2.988 2.69-3.229 7.293-.538 10.281M7.274 17.566c2.69 2.988 7.294 3.23 10.282.539 2.988-2.69 3.229-7.293.538-10.281" transform="translate(-2 -1)"/>
      <path fill="#A4B3B7" d="M6.16 16.8L11.76 16.8 11.76 22.4z" transform="translate(-2 -1) rotate(-87 8.96 19.6)"/>
      <path fill="#A4B3B7" d="M12.32 1.24L17.92 1.24 17.92 6.84z" transform="translate(-2 -1) rotate(90 15.12 4.04)"/>
  </g>
</svg>

export const corner = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
  <g fill="none" fillRule="evenodd" stroke="#A4B3B7">
      <path strokeWidth="4.32" d="M0.72 20.88L0.72 0" transform="translate(2 .5)"/>
      <path strokeWidth="2.16" d="M2.16 2.16L13.68 7.56 2.16 12.96z" transform="translate(2 .5)"/>
  </g>
</svg>

export const chance = <svg xmlns="http://www.w3.org/2000/svg" width="29" height="19" viewBox="0 0 29 19">
  <g fill="none" fillRule="evenodd">
      <path fill="#A4B3B7" d="M28.68 4.492C27.79 1.746 25.242 0 22.503 0c-.666 0-1.342.102-2.01.32-3.413 1.108-5.282 4.775-4.173 8.188.89 2.746 3.438 4.492 6.177 4.492.666 0 1.342-.102 2.01-.32 3.413-1.108 5.282-4.775 4.173-8.188zm-1.54 4.53l-1.485.154-1.243-1.471.694-1.818 2.023-.534 1.106.88c-.005.742-.21 1.476-.617 2.156-.134.224-.304.43-.478.633zm-.708-4.243l-1.86.574-1.69-1.214V2.213l1.248-.684c1.031.34 1.931.992 2.576 1.864l-.274 1.386zM22.118 2.21V4.14l-1.694 1.214-1.863-.575-.267-1.37c.641-.873 1.528-1.531 2.58-1.879l1.244.682zM17.919 9.29c-.44-.522-1.139-1.547-1.154-2.992l1.191-.945 2.172.576.742 1.928-1.329 1.601-1.622-.168zm1.784.891l1.433-1.77h2.584l1.456 1.77-.736 1.38c-.204.052-1.894.585-4 0l-.737-1.38z" transform="translate(0 .5)"/>
      <path stroke="#A4B3B7" strokeWidth="3.5" d="M0 4h11v14" transform="translate(0 .5)"/>
  </g>
</svg>

export const arrowsChangePos = <svg height="49" viewBox="0 0 71 49" width="71" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" transform="translate(2 2)"><path d="m12 0v45" stroke="#1d2d62"/><path d="m0 35 12 10 12-10" stroke="#1d2d62"/><g stroke="#42cee8"><path d="m55 0v45"/><path d="m43 0 12 10 12-10" transform="matrix(-1 0 0 -1 110 10)"/></g></g></svg>;

export const arrowsSubs = <svg height="49" viewBox="0 0 71 49" width="71" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" transform="translate(2 2)"><g stroke="#4e5152"><path d="m12 0v45"/><path d="m0 35 12 10 12-10"/></g><g stroke="#57f3a5" transform="matrix(-1 0 0 -1 67 45)"><path d="m12 0v45"/><path d="m0 35 12 10 12-10"/></g></g></svg>
