import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useForm from '@bit/iamtechnologies.iamtech-js.use-form';

import { setMatch } from '../../redux/actions/matchActions';
import { getToday } from '../../logic/date';
import { insertItem } from '../../services/database';
import getTranslation from '../../logic/translations';
import {
  BOOLEAN_OPTIONS,
  MODE_OPTIONS,
  getHomeAwayOptions,
  getTournamentOptions
} from '../../logic/football';
import { Box, HorizontalLine, SectionTitle, AppContainer, FlexCenter, ModalButtons, Separator } from '../../components/Layout';
import { FormField, FormRow, Input, Select, SelectButtons } from '../../components/Form';
import TagTrans from '../../components/TagTrans';
import { ButtonGreenBig } from '../../components/Button';

const todayYear = (new Date()).getFullYear();

const SEASON_OPTIONS = [
  { value: `${todayYear - 1}-${todayYear}`, label: `${todayYear - 1} - ${todayYear}` },
  { value: `${todayYear}-${todayYear + 1}`, label: `${todayYear} - ${todayYear + 1}` }
]

const MatchData = ({ onGoNext }) => {
  const lang = useSelector(state => state.lang);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const defaultValues = {
    tournament: '',
    season: SEASON_OPTIONS[1].value,
    date: getToday(),
    stadium: '',
    rival: '',
    homeAway: 'home',
    mode: 'f7',
    halfTime: '',
    volatileChanges: false,
    team: user.teams[0].id
  };

  const { values, useInput, isValid, errors, showErrors } = useForm(defaultValues);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(values);

    if (isValid) {
      const item = {
        ...values,
        date: +(new Date(values.date)),
        user: user.id,
        volatileChanges: values.volatileChanges === 'true' ? true : false,
      };
      const matchId = await insertItem('matches', item);
      dispatch(setMatch({ ...item, id: matchId }));
      onGoNext();
    }
  };

  const teamInput = useInput('team');
  const tournamentInput = useInput('tournament');
  const seasonInput = useInput('season');

  return (
    <div className="match-data">
      <SectionTitle><TagTrans tag="match_data" /></SectionTitle>
      <AppContainer padding isMainPage>
        <Box>
          <form onSubmit={handleSubmit}>
            {user.teams.length > 0 && (
              <FormField
                label={`${getTranslation('team', lang)}*`}
                name="team"
                errors={showErrors.team ? errors.team : []}
              >
                <Select
                  options={user.teams}
                  {...teamInput}
                  getLabel={t => t.name}
                  getValue={t => t.id}
                />
              </FormField>
            )}
            <FormField
              label={`${getTranslation('tournament', lang)}*`}
              name="tournament"
              errors={showErrors.tournament ? errors.tournament : []}
            >
              <Select
                options={getTournamentOptions(lang)}
                {...tournamentInput}
              />
            </FormField>
            <FormField
              label={`${getTranslation('season', lang)}*`}
              name="season"
              errors={showErrors.season ? errors.season : []}
            >
              <Select
                options={SEASON_OPTIONS}
                {...seasonInput}
              />
            </FormField>
            <FormField
              label={`${getTranslation('date', lang)}*`}
              name="date"
              errors={showErrors.date ? errors.date : []}
            >
              <Input name="date" type="date" {...useInput('date', 'isRequired')} />
            </FormField>
            <HorizontalLine />
            <FormField
              label={`${getTranslation('stadium', lang)}`}
              name="stadium"
              errors={showErrors.stadium ? errors.stadium : []}
            >
              <Input name="stadium" {...useInput('stadium')} />
            </FormField>
            <FormField
              label={`${getTranslation('rival', lang)}*`}
              name="rival"
              errors={showErrors.rival ? errors.rival : []}
            >
              <Input name="rival" {...useInput('rival', 'isRequired')} />
            </FormField>
            <FormField
              label={`${getTranslation('home_away', lang)}*`}
              name="homeAway"
              errors={showErrors.homeAway ? errors.homeAway : []}
            >
              <SelectButtons options={getHomeAwayOptions(lang)} {...useInput('homeAway')} />
            </FormField>
            <FormRow>
              <FormField
                label={`${getTranslation('mode', lang)}*`}
                name="mode"
                errors={showErrors.mode ? errors.mode : []}
              >
                <SelectButtons options={MODE_OPTIONS} {...useInput('mode')} />
              </FormField>
              <FormField
                label={`${getTranslation('half_time', lang)}*`}
                name="halfTime"
                errors={showErrors.halfTime ? errors.halfTime : []}
              >
                <Input name="half_time" {...useInput('halfTime', 'isRequired')} />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField
                label={`${getTranslation('volatile_changes', lang)}`}
                name="volatileChanges"
                errors={showErrors.volatile_changes ? errors.volatile_changes : []}
              >
                <SelectButtons options={BOOLEAN_OPTIONS} {...useInput('volatileChanges')} />
              </FormField>
            </FormRow>
          </form>
        </Box>
        <Separator />
      </AppContainer>
      <ModalButtons>
        <FlexCenter>
          <ButtonGreenBig
            onClick={handleSubmit}
            disabled={!isValid}
          >
            <TagTrans tag="go_lineup" />
          </ButtonGreenBig>
        </FlexCenter>
      </ModalButtons>
    </div>
  );
}

export default MatchData;
