import React, { useState, useEffect } from 'react';

import Modal from '../Modal';
import { FlexCenter, ModalButtons, Separator } from '../Layout';
import { PlayerListItem } from '../ListItem';
import TagTrans from '../TagTrans';
import { ButtonGrayBig, ButtonGreenBig } from '../Button'

const PlayersList = ({ show, onCancel, onPlayerClick, players, selectedPlayers, onPlayersSelected, multiple }) => {
  const [playersSelected, setPlayersSelected] = useState(selectedPlayers || []);
  
  useEffect(() => {
    setPlayersSelected(selectedPlayers || []);
  }, [show])

  const handlePlayerClick = player => {
    if (multiple) {
      const isPlayerSelected = playersSelected.filter(p => p.id === player.id).length > 0;

      if (isPlayerSelected) {
        const newPlayersSelected = playersSelected.filter(p => p.id !== player.id)
        setPlayersSelected(newPlayersSelected);
      } else {
        setPlayersSelected([...playersSelected, player]);
      }
    } else {
      onPlayerClick(player);
    }
  }

  return (
    <Modal show={show} onCancel={onCancel}>
      {players.length 
        ? players.map(player => (
            <PlayerListItem 
              key={player.id} 
              player={player}
              highlighted={playersSelected.filter(p => p.id === player.id).length > 0} 
              onClick={() => handlePlayerClick(player)} 
              noOptions
            />)
          )
        : <FlexCenter><TagTrans tag="no_results" /></FlexCenter>
      }
      <Separator />
      <Separator />
      <Separator />
      <ModalButtons>
        <ButtonGrayBig onClick={onCancel}><TagTrans tag="cancel" /></ButtonGrayBig>
        {multiple 
          && <ButtonGreenBig onClick={() => onPlayersSelected(playersSelected)}>
            <TagTrans tag="add_players" />&nbsp;({playersSelected.length})
          </ButtonGreenBig>
        }
      </ModalButtons>
    </Modal>
  );
}
 
export default PlayersList;