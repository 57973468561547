import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
  MATCH_STATUS,
  MATCH_ACTIONS,
  getLineupPositionName,
  getPlayersFromLineup,
  defaultSummary, 
  getLineupLayout, 
  addUsOwnGoal, 
  addRivalOwnGoal, 
  addMatchStart, 
  addMatchPause,
  addMatchResume,
  addSuspendMatch,
  addFinishPart,
  addFinishMatch,
  addUsOffside,
  addRivalOffside,
  addUsCorner,
  addRivalCorner,
  addUsFault,
  addRivalFault,
  addUsChance,
  addRivalChance,
  addUsGoal,
  addRivalGoal,
  addUsCard,
  addRivalCard,
  addUsSubstitution,
  addUsChangePos,
  addRivalSubstitution,
  addRivalChangePos,
  addUsChangeFormation,
  addRivalChangeFormation,
  undoLastAction,
  getDefaultFormation,
  getEmptyLineup
} from '../../logic/football';
import { 
  Scoreboard, 
  Status, 
  TabsMenu, 
  Summary, 
  ActionSelect, 
  ActionRecord,
  MatchOptions 
} from '../../components/Match'
import AddPlayer from '../../components/AddPlayer';
import Modal from '../../components/Modal';
import { setMatch } from '../../redux/actions/matchActions';
import { getItem, upsertItem } from '../../services/database';
import { useTeamPlayers } from '../../customHooks';
import PlayField from '../../components/PlayField';
import PlayersList from '../../components/PlayersList';
import { AppContainer, ModalButtons, Separator } from '../../components/Layout';
import TagTrans from '../../components/TagTrans';
import ButtonDarkBig from '../../components/Button/ButtonDarkBig';
import TeamReport from '../../components/Match/TeamReport';
import RivalReport from '../../components/Match/RivalReport';

import './Match.scss';

const Match = () => {
  const params = useParams();
  const history = useHistory();

  const [addingPlayerPosition, setAddingPlayerPosition] = useState({});
  const [showAddPlayer, setShowAddPlayer] = useState(false);

  const [addingRivalPlayerPosition, setAddingRivalPlayerPosition] = useState({});
  const [showAddRivalPlayer, setShowAddRivalPlayer] = useState(false);

  const [showOptions, setShowOptions] = useState(false);
  const [currentTab, setCurrentTab] = useState('my-team');
  const [currentMatch, setCurrentMatch] = useState({});
  console.log("TCL: Match -> currentMatch", currentMatch)
  const [lineup, setLineup] = useState([]);
  const [rivalLineup, setRivalLineup] = useState([]);
  const [newAction, setNewAction] = useState(null);
  const dispatch =  useDispatch();
  const teamPlayers = useTeamPlayers(currentMatch.team);

  useEffect(() => {
    const fetchMatch = async (id) => {
      const databaseMatch = await getItem('matches', id);

      if (!databaseMatch.status) databaseMatch.status = MATCH_STATUS.ABOUT_START;
      if (!databaseMatch.currentPart) databaseMatch.currentPart = '1';
      if (!databaseMatch.currentPartTime) databaseMatch.currentPartTime = 0;
      if (!databaseMatch.summary) databaseMatch.summary = defaultSummary;
      if (!databaseMatch.log) databaseMatch.log = [];
      if (!databaseMatch.score) databaseMatch.score = 0;
      if (!databaseMatch.scoreRival) databaseMatch.scoreRival = 0;
      if (!databaseMatch.won) databaseMatch.won = '';
      if (!databaseMatch.rivalFormation) databaseMatch.rivalFormation = getDefaultFormation(databaseMatch.mode);
      if (!databaseMatch.rivalLineup) databaseMatch.rivalLineup = getEmptyLineup(databaseMatch.mode, databaseMatch.rivalFormation);

      refreshLineup(databaseMatch.mode, databaseMatch.formation, databaseMatch.lineup);
      refreshRivalLineup(databaseMatch.mode, databaseMatch.rivalFormation, databaseMatch.rivalLineup);
      saveMatch(databaseMatch)
    }
    fetchMatch(params.id);
  }, []);

  const refreshLineup = (mode, formation, lineup) => {
    const defaultLineup = getLineupLayout({ mode, formation, lineup });
    setLineup(defaultLineup);
  }

  const refreshRivalLineup = (mode, formation, lineup) => {
    const defaultLineup = getLineupLayout({ mode, formation, lineup });
    setRivalLineup(defaultLineup);
  }

  const saveMatch = newMatch => {
    const savingMatch = {
      ...newMatch,
      lineup: getPlayersFromLineup(newMatch.lineup),
      rivalLineup: getPlayersFromLineup(newMatch.rivalLineup)
    }
    updateMatch(savingMatch);
    upsertItem('matches', savingMatch, savingMatch.id);
    refreshLineup(savingMatch.mode, savingMatch.formation, savingMatch.lineup);
    refreshRivalLineup(savingMatch.mode, savingMatch.rivalFormation, savingMatch.rivalLineup);
  }

  const updateMatch = newMatch => {
    dispatch(setMatch(newMatch));
    setCurrentMatch(newMatch);
  }

  const handleTabSelect = tab => {
    document.getElementById('match-tabs-container').scrollTo(0,0)
    setCurrentTab(tab);
  }

  const handlePlayerClick = (position, type) => {
    const { i, j } = position;
    const player = type === 'us' ? lineup[i][j] : rivalLineup[i][j];

    if (player) {
      setNewAction({ type, player });
      return
    } 
    if (type === 'us') {
      setAddingPlayerPosition(position);
      setShowAddPlayer(true);
      return;
    }
    setAddingRivalPlayerPosition(position)
    setShowAddRivalPlayer(true);
  }

  const addPlayerToLineup = player => {
    const newLineup = lineup.map(line => (
      line.map(position => position && position.id === player.id ? null : position)
    ));
    const { i, j } = addingPlayerPosition;
    player.position = getLineupPositionName(newLineup, i, j);
    player.positionRow = i;
    player.positionColumn = j;
    newLineup[i][j] = player;
    saveMatch({
      ...currentMatch,
      lineup: newLineup
    });
    setShowAddPlayer(false);
  }
  const addRivalPlayerToLineup = player => {
    setShowAddRivalPlayer(false);
    if (!player) return;

    const newLineup = rivalLineup.map(line => (
      line.map(position => position && position.id === player.id ? null : position)
    ));
    const { i, j } = addingRivalPlayerPosition;
    player.position = getLineupPositionName(newLineup, i, j);
    player.positionRow = i;
    player.positionColumn = j;
    newLineup[i][j] = player;
    saveMatch({
      ...currentMatch,
      rivalLineup: newLineup
    });
  }

  const handleActionSelected = action => {
    if (action.action === 'own_goal') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsOwnGoal(currentMatch, newAction));
      else saveMatch(addRivalOwnGoal(currentMatch, newAction));
      
    } else if (action.action === 'corner') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsCorner(currentMatch, action));
      else saveMatch(addRivalCorner(currentMatch, action));

    } else {
      setNewAction({ action: action.action, ...newAction });
    }
  }

  const handleActionRecordSelected = action => {
    if (action.action === 'offside') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsOffside(currentMatch, action));
      else saveMatch(addRivalOffside(currentMatch, action));

    } else if (action.action === 'fault') {
      setNewAction(null);
      if (action.type === 'us') {
        const matchWithFault = addUsFault(currentMatch, action)
        saveMatch(matchWithFault);
        if (action.actionInfo.card) saveMatch(addUsCard(matchWithFault, action));
      } else {
        const matchWithFault = addRivalFault(currentMatch, action);
        saveMatch(matchWithFault);
        if (action.actionInfo.card) {
          saveMatch(addRivalCard(matchWithFault, action));
        }
      }

    } else if (action.action === 'chance') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsChance(currentMatch, action));
      else saveMatch(addRivalChance(currentMatch, action));

    } else if (action.action === 'goal') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsGoal(currentMatch, action));
      else saveMatch(addRivalGoal(currentMatch, action));
    
    } else if (action.action === 'card') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsCard(currentMatch, action));
      else saveMatch(addRivalCard(currentMatch, action));
    
    } else if (action.action === 'substitution') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsSubstitution(currentMatch, action));
      else saveMatch(addRivalSubstitution(currentMatch, action));
    
    } else if (action.action === 'change_pos') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsChangePos(currentMatch, action));
      else saveMatch(addRivalChangePos(currentMatch, action));

    } else if (action.action === 'change_formation') {
      setNewAction(null);
      if (action.type === 'us') saveMatch(addUsChangeFormation(currentMatch, action));
      else saveMatch(addRivalChangeFormation(currentMatch, action));
    }
  }
  
  const handleShowFormation = () => {
    setNewAction({ 
      type: currentTab === 'my-team' ? 'us' : 'rival', 
      action: MATCH_ACTIONS.CHANGE_FORMATION 
    });
  }

  const playPauseMatch = () => {
    const { status } = currentMatch;

    if (status === MATCH_STATUS.ABOUT_START 
      || status === MATCH_STATUS.BETWEEN_PART
      || status === MATCH_STATUS.SUSPENDED) {
      return saveMatch(addMatchStart(currentMatch));
    }
    if (status === MATCH_STATUS.PLAYING) {
      return saveMatch(addMatchPause(currentMatch));
    }
    if (status === MATCH_STATUS.PAUSED) {
      return saveMatch(addMatchResume(currentMatch));
    }
  }

  const handleOptionSelect = (option) => {    
    setShowOptions(false);
    if (option === MATCH_ACTIONS.PART_FINISHED) return saveMatch(addFinishPart(currentMatch));
    if (option === MATCH_ACTIONS.SUSPEND) return saveMatch(addSuspendMatch(currentMatch));
    if (option === MATCH_ACTIONS.MATCH_FINISHED) {
      saveMatch(addFinishMatch(currentMatch));
      history.push(`/match-report/${currentMatch.id}`);
    }
    if (option === 'exit') return history.push('/home');
    if (option === MATCH_ACTIONS.UNDO_LAST_ACTION) {
      return saveMatch(undoLastAction(currentMatch));
    }
  }

  // console.log("TCL: Match -> match", currentMatch)
  console.log("TCL: Match -> newAction", newAction)
  if (!currentMatch.id) return <div>Loading...</div>;

  const showActionRecord = newAction !== null && newAction.action && 
    (newAction.action === MATCH_ACTIONS.GOAL
    || newAction.action === MATCH_ACTIONS.FAULT
    || newAction.action === MATCH_ACTIONS.CARD
    || newAction.action === MATCH_ACTIONS.OFFSIDE
    || newAction.action === MATCH_ACTIONS.SUBSTITUTION
    || newAction.action === MATCH_ACTIONS.CHANCE
    || newAction.action === MATCH_ACTIONS.CHANGE_POS
    || newAction.action === MATCH_ACTIONS.CHANGE_FORMATION
    );
      
  return (
    <div>
      <Scoreboard />
      <Status />
      <TabsMenu currentTab={currentTab} onTabSelect={handleTabSelect} />
      <div id="match-tabs-container" className="match-tabs-container">
        <div className={`match-tabs-content ${currentTab}`}>
          <div className="match-tabs-item">
            <AppContainer>
              <PlayField lineup={lineup} onPlayerClick={(position) => handlePlayerClick(position, 'us')} noBg />
            </AppContainer>
            <TeamReport match={currentMatch} />
            <Separator />
            <Separator />
          </div>
          <div className="match-tabs-item">
            <AppContainer>
              <Summary />
            </AppContainer>
          </div>
          <div className="match-tabs-item">
            <AppContainer>
              <PlayField lineup={rivalLineup} onPlayerClick={(position) => handlePlayerClick(position, 'rival')} noBg />
            </AppContainer>
            <RivalReport match={currentMatch} />
            <Separator />
            <Separator />
          </div>
        </div>
        <ModalButtons>
          <div className="match-buttons">
            <ButtonDarkBig onClick={handleShowFormation}><TagTrans tag="formation" /></ButtonDarkBig>
            <ButtonDarkBig onClick={playPauseMatch}>
              {currentMatch.status === MATCH_STATUS.PLAYING
                ? <FontAwesomeIcon icon="pause" color="#ffffff" />
                : <FontAwesomeIcon icon="play" color="#ffffff" />
              }
              
            </ButtonDarkBig>
            <ButtonDarkBig onClick={() => setShowOptions(true)}><TagTrans tag="options" /></ButtonDarkBig>
          </div>
        </ModalButtons>
        <ActionSelect 
          action={newAction}
          show={newAction !== null && !newAction.action} 
          onCancel={() => setNewAction(null)} 
          onActionSelected={handleActionSelected}
          player={newAction && newAction.player}
        />
        <ActionRecord 
          action={newAction}
          show={showActionRecord} 
          onCancel={() => setNewAction(null)} 
          onActionSaved={handleActionRecordSelected}
          player={newAction && newAction.player}
        />
        <MatchOptions 
          show={showOptions} 
          onCancel={() => setShowOptions(false)} 
          onOptionSelected={action => handleOptionSelect(action)}
        />
        <PlayersList 
          show={showAddPlayer} 
          onCancel={() => setShowAddPlayer(false)} 
          onPlayerClick={player => addPlayerToLineup(player)}
          players={teamPlayers}
        />
        <Modal show={showAddRivalPlayer} onCancel={() => showAddRivalPlayer(false)}>
          <AddPlayer onFinish={addRivalPlayerToLineup} rival/>
        </Modal>
      </div>
    </div>
  );
};

export default Match;
