import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { toggleMenu } from '../../redux/actions/menuActions';
import AppContainer from '../Layout/AppContainer';
import getTranslation from '../../logic/translations';

import './Header.scss';

const Header = ({ history }) => {
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const CONFIG = {
    'login': { title: getTranslation('login', lang), color: 'dark' },
    'signup': { title: getTranslation('signup', lang), color: 'dark' },
    'new-report': { title: getTranslation('new_report', lang) },
    'team': { title: getTranslation('new_report', lang), back: true },
    'match': { hidden: true },
    'match-report': { hidden: true },
    'home': { hideButton: true },
    'stats': { title: 'stats' },
    '': { hideButton: true },
  }

  const page = history.location.pathname.split('/')[1];

  const goBack = () => history.goBack();
  const getTitle = () => CONFIG[page] ? getTranslation(CONFIG[page].title, lang) : '';
  const showBack = () => CONFIG[page] && !!CONFIG[page].back;
  const getColor = () => CONFIG[page] && CONFIG[page].color ? CONFIG[page].color : 'blue';
  const showMenuButton = () => !CONFIG[page] || !CONFIG[page].hideButton;

  if (CONFIG[page] && CONFIG[page].hidden) return null;

  return (
    <nav className={`header ${getColor()}`}>
      <AppContainer padding>
        <div className="header-nav">
          <div>
            {showBack() && (
              <button
                type="button"
                onClick={goBack}
                aria-label="Open menu"
              >
                <FontAwesomeIcon icon="arrow-left" color="#eef7f9" size="xs" />
              </button>
            )}
          </div>
          <div className="title">{getTitle()}</div>
          {showMenuButton() && <button
            className="menu-button"
            type="button"
            onClick={() => dispatch(toggleMenu())}
            aria-label="Open menu"
          >
            <FontAwesomeIcon icon="bars" color="#eef7f9" />
          </button>}
        </div>
      </AppContainer>
    </nav>
  );
};

export default withRouter(Header);
