import React from 'react';

import TagTrans from '../../TagTrans';

import './FormField.scss';


const FormField = ({ children, label, name, errors }) => {
  return (
    <div className="form-field">
      {label && <label htmlFor={name}>{label}</label>}
      {children}
      <div className="form-errors">
        {errors && errors.map(err => (
          <div key={err} className="form-errors-item">{err && <TagTrans tag={err} />}</div>
        ))}
      </div>
    </div>
  );
}

export default FormField;
