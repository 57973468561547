import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './Routes';
import { setUser } from './redux/actions/userActions';
import { registerAuthObserver } from './services/auth';
import { getItem, getAllFiltered } from './services/database';
import Header from './components/Header';
import MenuNav from './components/MenuNav';

import './scss/globals/index.scss';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const menu = useSelector(state => state.menu);

  useEffect(() => {
    const cancelObserver = registerAuthObserver(async (user) => {
      if (user) {
        const [profile, teams] = await Promise.all([
          getItem('profiles', user.uid),
          getAllFiltered('teams', [{ field: 'user', condition: '==', value: user.uid }])
        ]);

        if (profile) {
          profile.teams = teams;
          dispatch(setUser(profile));
        } else {
          console.log("todavía se está registrando");
        }
      } else {
        dispatch(setUser(null));
      }
      setIsLoading(false);
    });

    return () => {
      cancelObserver();
    };
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={`footie-app ${menu ? 'menu-opened' : ''}`}>
      <Router>
        <MenuNav />
        <Header />
        <Routes />
      </Router>
    </div>
  );
};

export default App;
