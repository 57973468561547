import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBars, faArrowLeft, faChevronDown, faChevronUp, faPlay, faPause, faTimes } from '@fortawesome/free-solid-svg-icons';

import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from './redux';
import config from './config';


library.add(faBars, faArrowLeft, faChevronDown, faChevronUp, faPlay, faPause, faTimes);
firebase.initializeApp(config.firebase);
const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
