import React, { useState, useEffect } from 'react';
import { useSelector,  useDispatch } from 'react-redux';

import { setMatch } from '../../../redux/actions/matchActions';
import { SectionTitle, AppContainer } from '../../Layout';
import TagTrans from '../../TagTrans';
import { getPartTimeFromSeconds, getTimePlayed } from '../../../logic/football';

import './Status.scss';

let timerInterval;

const Status = () => {
  const dispatch =  useDispatch();
  const match = useSelector(state => state.match);
  const secondsPlayed = getTimePlayed(match);
  const [currentTime, setCurrentTime] = useState(getPartTimeFromSeconds(secondsPlayed));
  
  useEffect(() => {
    if (timerInterval) clearInterval(timerInterval);

    if (match.status === 'playing') {
      timerInterval = setInterval(() => setTimer() , 1000);
    } else {
      setTimer();
    }

    return () => clearInterval(timerInterval);

  }, [match.status, match.log, match.currentPartTime]);

  const setTimer = () => {
    const secondsPlayed = getTimePlayed(match)
    const { minutes, seconds } = getPartTimeFromSeconds(secondsPlayed)
    setCurrentTime({ minutes, seconds });
    if (match.currentPartTime !== minutes) {
      dispatch(setMatch({ ...match, currentPartTime: minutes }));
    }
  }

  const { minutes, seconds } = currentTime;

  return (
    <div className="match-status">
      <SectionTitle>
        <AppContainer>
          <div className="match-status-container">
            <div className="match-status-container-section half">
              <TagTrans tag={`half_${match.currentPart}`} />
            </div>
            <div className="match-status-container-section time">
              {minutes}:{seconds} min
            </div>
            <div className={`match-status-container-section status ${match.status}`}>
              <TagTrans tag={`status_${match.status}`} />
            </div>
          </div>
        </AppContainer>
      </SectionTitle>
    </div>
  );
}
 
export default Status;
