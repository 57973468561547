import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { PlayerListItem } from '../../components/ListItem/index';
import { getAllRealTime, deleteItem } from '../../services/database';
import { ButtonDarkBig } from '../../components/Button';
import { SectionTitle, FlexCenter, AppContainer, Separator, ModalButtons } from '../../components/Layout';
import TagTrans from '../../components/TagTrans';
import Modal from '../../components/Modal/Modal';
import AddPlayer from '../../components/AddPlayer';
import { sortPlayersByRole } from '../../logic/football';

import './Team.scss';

const Team = ({ match }) => {
  const teamId = match.params.id;

  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [players, setPlayers] = useState([]);
  const [editPlayer, setEditPlayer] = useState(null);

  useEffect(() => {
    const observer = getAllRealTime({
      collection: 'players', 
      filters: {field: 'team', condition: '==', value: teamId },
      callback: teamPlayers => setPlayers( sortPlayersByRole(teamPlayers))
    })

    return () => observer && observer();
  },[teamId]);

  const handleShowAddPlayer = () => {
    setEditPlayer(null);
    setShowAddPlayer(true);
  };

  const handleShowEditPlayer = player => {
    setEditPlayer(player);
    setShowAddPlayer(true);
  };

  const handleDeletePlayer = async player => {
    deleteItem('players', player.id);
  }
  
  const hideModal = () => {
    setShowAddPlayer(false);
  }

  return (
    <div className="team-page">
      <SectionTitle><TagTrans tag="players" /></SectionTitle>
      <AppContainer padding isMainPage>
        {players.length 
          ? players.map(player => (
            <PlayerListItem 
              key={player.id} 
              player={player} 
              onDelete={() => handleDeletePlayer(player)}
              onEdit={() => handleShowEditPlayer(player)}
              onClick={() => handleShowEditPlayer(player)}
            />))
          : <FlexCenter><TagTrans tag="no_results" /></FlexCenter>
        }
        <Separator />
      </AppContainer>
      <ModalButtons>
        <FlexCenter>
          <ButtonDarkBig onClick={handleShowAddPlayer}>+ <TagTrans tag="add_player" /></ButtonDarkBig>
        </FlexCenter>
      </ModalButtons>
      <Modal show={showAddPlayer} onCancel={hideModal}>
        <AddPlayer onFinish={hideModal} team={teamId} player={editPlayer}/>
      </Modal>
    </div>
  );
};

export default withRouter(Team);
