import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setUser } from '../../redux/actions/userActions';
import { TeamListItem } from '../../components/ListItem';
import AddTeam from '../../components/AddTeam';
import TagTrans from '../../components/TagTrans';
import { getAllRealTime, deleteItem } from '../../services/database';
import { ButtonDarkBig } from '../../components/Button';
import { ListItem } from '../../components/ListItem/index';
import { FlexCenter, AppContainer, ModalButtons } from '../../components/Layout';
import Modal from '../../components/Modal/Modal';

import './MyTeams.scss';

const MyTeams = ({ history }) => {
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const user = useSelector(state => state.user);

  const [showAddTeam, setShowAddTeam] = useState(false);
  const [editTeam, setEditTeam] = useState(null);

  useEffect(() => {
    const observer = getAllRealTime({
      collection: 'teams', 
      filters: {field: 'user', condition: '==', value: user.id },
      callback: teams => {
        setTeams(teams)
        const userUpdated = { ...user, teams };
        dispatch(setUser(userUpdated));
      }
    })

    return () => observer && observer();
  }, [user.id]);

  const handleShowAddTeam = () => {
    setEditTeam(null);
    setShowAddTeam(true);
  };

  const handleShowEditTeam = team => {
    setEditTeam(team);
    setShowAddTeam(true);
  };

  const hideModal = () => {
    setShowAddTeam(false);
  }


  const handleDeleteTeam = async team => {
    deleteItem('teams', team.id);
  }


  return (
    <div className="my-teams-page">
      <AppContainer padding isMainPage>
        {teams.length 
          ? teams.map(team => (
              <TeamListItem 
                key={team.id} 
                team={team} 
                onDelete={() => handleDeleteTeam(team)}
                onEdit={() => handleShowEditTeam(team)}
                onClick={() => history.push(`/team/${team.id}`)}
              />
            ))
          : <FlexCenter><TagTrans tag="no_results" /></FlexCenter>
        }
      </AppContainer>    
      <ModalButtons>
        <FlexCenter>
          <ButtonDarkBig onClick={handleShowAddTeam}>+ <TagTrans tag="add_team" /></ButtonDarkBig>
        </FlexCenter>
      </ModalButtons>
      <Modal show={showAddTeam} onCancel={hideModal}>
        <AddTeam onFinish={hideModal} team={editTeam}/>
      </Modal> 
    </div>
  );
};

export default withRouter(MyTeams);
