import XLSX from 'sheetjs-style';

import getTranslation from '../translations';

import {
  getMatchSubstitutions,
  getMathFormationChanges,
  getMatchPlayersStats,
  getInitialLineup,
  formatPlayerNameAndNumber,
  getPlayerNameAndNumber,
  getLineupLayout,
} from './index';

const titleStyles = {
  alignment: { horizontal: "center" },
  fill: { fgColor: { rgb: '001d2d62' } },
  font: { color: { rgb: '00ffffff' }, bold: true, sz: 18 }
};

const tableHeaderStyles = {
  alignment: { horizontal: "center" },
  fill: { fgColor: { rgb: '001d2d62' } },
  font: { color: { rgb: '00ffffff' }, sz: 12 }
};

const centerStyles = {
  alignment: { horizontal: "center" },
}
const centerBoldStyles = {
  alignment: { horizontal: "center" },
  font: { bold: true, sz: 16 }
}

const boldStyles = {
  font: { bold: true, sz: 16 }
}

const centerBoldStylesSmall = {
  alignment: { horizontal: "center" },
  font: { bold: true, sz: 12 }
}

const centerStylesSmall = {
  font: { sz: 12 },
  alignment: { horizontal: "center" },
}
const stylesExtraSmall = {
  font: { sz: 9 },
}

const playerTableHeader = ['number_abrv', 'player', 'position', 'minutes_abrv', 'goals', 'chances_abrv', 'faults', 'corners', 'offsided_abrv', 'yellowCards_abrv', 'redCards_abrv', 'observations'];

export const exportMatchXlsReport = ({ match, lineup, team, lang }) => {
  const substitutions = getMatchSubstitutions(match);
  const formations = getMathFormationChanges(match);
  const playerStats = getMatchPlayersStats(match);
  const initialLineup = getInitialLineup(match);
  const rivalLineup = getLineupLayout({ mode: match.mode, formation: match.rivalFormation, lineup: match.rivalLineup });
  console.log('IMTCHLG ~ file: exports.js ~ line 56 ~ exportMatchXlsReport ~ rivalLineup', rivalLineup);
  // console.log('IMTCHLG ~ file: exports.js ~ line 53 ~ exportMatchXlsReport ~ initialLineup', initialLineup);
  // console.log('IMTCHLG ~ file: exports.js ~ line 35 ~ exportMatchXlsReport ~ playerStats', playerStats);

  const t = (key) => getTranslation(key, lang);
  // console.log('IMTCHLG ~ file: exports.js ~ line 4 ~ exportMatchXlsReport ~ lineup', lineup);
  // console.log('IMTCHLG ~ file: exports.js ~ line 4 ~ exportMatchXlsReport ~ match', match);
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Informe de partido",
    Subject: "Informe de partido",
    Author: "Footie",
    CreatedDate: new Date()
  };
  var ws = XLSX.utils.aoa_to_sheet([[]]);
  XLSX.utils.book_append_sheet(wb, ws, "Informe");

  ws["!merges"] = [
    // Match report title
    { s: { r: 1, c: 0 }, e: { r: 1, c: 18 } }, /* A2:S2 */

    // Teams names
    { s: { r: 7, c: 1 }, e: { r: 7, c: 4 } },  /* B8:F8 */
    { s: { r: 7, c: 14 }, e: { r: 7, c: 17 } },  /* N8:R8 */

    // Stat names
    { s: { r: 9, c: 8 }, e: { r: 9, c: 10 } },    /* I10:K10 */
    { s: { r: 10, c: 8 }, e: { r: 10, c: 10 } },  /* I11:K11 */
    { s: { r: 11, c: 8 }, e: { r: 11, c: 10 } },  /* I12:K12 */
    { s: { r: 12, c: 8 }, e: { r: 12, c: 10 } },  /* I13:K13 */
    { s: { r: 13, c: 8 }, e: { r: 13, c: 10 } },  /* I14:K14 */
    { s: { r: 14, c: 8 }, e: { r: 14, c: 10 } },  /* I15:K15 */
    { s: { r: 15, c: 8 }, e: { r: 15, c: 10 } },  /* I16:K16 */
    { s: { r: 16, c: 8 }, e: { r: 16, c: 10 } },  /* I17:K17 */
    { s: { r: 17, c: 8 }, e: { r: 17, c: 10 } },  /* I18:K18 */

    // Initial lineup
    { s: { r: 21, c: 1 }, e: { r: 21, c: 5 } },  /* B22:C22 */
    // Initial lineup table players
    { s: { r: 22, c: 2 }, e: { r: 22, c: 4 } },  /* B22:C22 */
    { s: { r: 23, c: 2 }, e: { r: 23, c: 4 } },  /* B22:C22 */
    { s: { r: 24, c: 2 }, e: { r: 24, c: 4 } },  /* B22:C22 */
    { s: { r: 25, c: 2 }, e: { r: 25, c: 4 } },  /* B22:C22 */
    { s: { r: 26, c: 2 }, e: { r: 26, c: 4 } },  /* B22:C22 */
    { s: { r: 27, c: 2 }, e: { r: 27, c: 4 } },  /* B22:C22 */
    { s: { r: 28, c: 2 }, e: { r: 28, c: 4 } },  /* B22:C22 */
    { s: { r: 29, c: 2 }, e: { r: 29, c: 4 } },  /* B22:C22 */
    { s: { r: 30, c: 2 }, e: { r: 30, c: 4 } },  /* B22:C22 */
    { s: { r: 31, c: 2 }, e: { r: 31, c: 4 } },  /* B22:C22 */
    { s: { r: 32, c: 2 }, e: { r: 32, c: 4 } },  /* B22:C22 */
    { s: { r: 33, c: 2 }, e: { r: 33, c: 4 } },  /* B22:C22 */
    { s: { r: 34, c: 2 }, e: { r: 34, c: 4 } },  /* B22:C22 */
    // Initial lineup table obs
    { s: { r: 22, c: 14 }, e: { r: 22, c: 17 } },  /* B22:C22 */
    { s: { r: 23, c: 14 }, e: { r: 23, c: 17 } },  /* B22:C22 */
    { s: { r: 24, c: 14 }, e: { r: 24, c: 17 } },  /* B22:C22 */
    { s: { r: 25, c: 14 }, e: { r: 25, c: 17 } },  /* B22:C22 */
    { s: { r: 26, c: 14 }, e: { r: 26, c: 17 } },  /* B22:C22 */
    { s: { r: 27, c: 14 }, e: { r: 27, c: 17 } },  /* B22:C22 */
    { s: { r: 28, c: 14 }, e: { r: 28, c: 17 } },  /* B22:C22 */
    { s: { r: 29, c: 14 }, e: { r: 29, c: 17 } },  /* B22:C22 */
    { s: { r: 30, c: 14 }, e: { r: 30, c: 17 } },  /* B22:C22 */
    { s: { r: 31, c: 14 }, e: { r: 31, c: 17 } },  /* B22:C22 */
    { s: { r: 32, c: 14 }, e: { r: 32, c: 17 } },  /* B22:C22 */
    { s: { r: 33, c: 14 }, e: { r: 33, c: 17 } },  /* B22:C22 */
    { s: { r: 34, c: 14 }, e: { r: 34, c: 17 } },  /* B22:C22 */

    // Bench
    { s: { r: 37, c: 1 }, e: { r: 37, c: 5 } },  /* B22:C22 */
    // Bench table players
    { s: { r: 38, c: 2 }, e: { r: 38, c: 4 } },  /* B22:C22 */
    { s: { r: 39, c: 2 }, e: { r: 39, c: 4 } },  /* B22:C22 */
    { s: { r: 40, c: 2 }, e: { r: 40, c: 4 } },  /* B22:C22 */
    { s: { r: 41, c: 2 }, e: { r: 41, c: 4 } },  /* B22:C22 */
    { s: { r: 42, c: 2 }, e: { r: 42, c: 4 } },  /* B22:C22 */
    { s: { r: 43, c: 2 }, e: { r: 43, c: 4 } },  /* B22:C22 */
    { s: { r: 44, c: 2 }, e: { r: 44, c: 4 } },  /* B22:C22 */
    { s: { r: 45, c: 2 }, e: { r: 45, c: 4 } },  /* B22:C22 */
    { s: { r: 46, c: 2 }, e: { r: 46, c: 4 } },  /* B22:C22 */
    { s: { r: 47, c: 2 }, e: { r: 47, c: 4 } },  /* B22:C22 */
    { s: { r: 48, c: 2 }, e: { r: 48, c: 4 } },  /* B22:C22 */
    { s: { r: 49, c: 2 }, e: { r: 49, c: 4 } },  /* B22:C22 */
    { s: { r: 50, c: 2 }, e: { r: 50, c: 4 } },  /* B22:C22 */
    { s: { r: 51, c: 2 }, e: { r: 51, c: 4 } },  /* B22:C22 */
    { s: { r: 52, c: 2 }, e: { r: 52, c: 4 } },  /* B22:C22 */
    // Bench table obs
    { s: { r: 38, c: 14 }, e: { r: 38, c: 17 } },  /* B22:C22 */
    { s: { r: 39, c: 14 }, e: { r: 39, c: 17 } },  /* B22:C22 */
    { s: { r: 40, c: 14 }, e: { r: 40, c: 17 } },  /* B22:C22 */
    { s: { r: 41, c: 14 }, e: { r: 41, c: 17 } },  /* B22:C22 */
    { s: { r: 42, c: 14 }, e: { r: 42, c: 17 } },  /* B22:C22 */
    { s: { r: 43, c: 14 }, e: { r: 43, c: 17 } },  /* B22:C22 */
    { s: { r: 44, c: 14 }, e: { r: 44, c: 17 } },  /* B22:C22 */
    { s: { r: 45, c: 14 }, e: { r: 45, c: 17 } },  /* B22:C22 */
    { s: { r: 46, c: 14 }, e: { r: 46, c: 17 } },  /* B22:C22 */
    { s: { r: 47, c: 14 }, e: { r: 47, c: 17 } },  /* B22:C22 */
    { s: { r: 48, c: 14 }, e: { r: 48, c: 17 } },  /* B22:C22 */
    { s: { r: 49, c: 14 }, e: { r: 49, c: 17 } },  /* B22:C22 */
    { s: { r: 50, c: 14 }, e: { r: 50, c: 17 } },  /* B22:C22 */
    { s: { r: 51, c: 14 }, e: { r: 51, c: 17 } },  /* B22:C22 */
    { s: { r: 52, c: 14 }, e: { r: 52, c: 17 } },  /* B22:C22 */

    // Subs
    { s: { r: 55, c: 1 }, e: { r: 55, c: 5 } },  /* B48:F48 */
    // Subs In
    { s: { r: 56, c: 3 }, e: { r: 56, c: 5 } },  /* D49:F49 */
    { s: { r: 57, c: 3 }, e: { r: 57, c: 5 } },  /* D51:F51 */
    { s: { r: 58, c: 3 }, e: { r: 58, c: 5 } },  /* D52:F52 */
    { s: { r: 59, c: 3 }, e: { r: 59, c: 5 } },  /* D53:F53 */
    { s: { r: 60, c: 3 }, e: { r: 60, c: 5 } },  /* D54:F54 */
    { s: { r: 61, c: 3 }, e: { r: 61, c: 5 } },  /* D55:F55 */
    { s: { r: 62, c: 3 }, e: { r: 62, c: 5 } },  /* D56:F56 */
    { s: { r: 63, c: 3 }, e: { r: 63, c: 5 } },  /* D57:F57 */
    { s: { r: 64, c: 3 }, e: { r: 64, c: 5 } },  /* D58:F58 */
    { s: { r: 65, c: 3 }, e: { r: 65, c: 5 } },  /* D59:F59 */
    { s: { r: 66, c: 3 }, e: { r: 66, c: 5 } },  /* D60:F60 */
    // Subs Out
    { s: { r: 56, c: 6 }, e: { r: 56, c: 8 } },  /* G49:I49 */
    { s: { r: 57, c: 6 }, e: { r: 57, c: 8 } },  /* G51:I51 */
    { s: { r: 58, c: 6 }, e: { r: 58, c: 8 } },  /* G52:I52 */
    { s: { r: 59, c: 6 }, e: { r: 59, c: 8 } },  /* G53:I53 */
    { s: { r: 60, c: 6 }, e: { r: 60, c: 8 } },  /* G54:I54 */
    { s: { r: 61, c: 6 }, e: { r: 61, c: 8 } },  /* G55:I55 */
    { s: { r: 62, c: 6 }, e: { r: 62, c: 8 } },  /* G56:I56 */
    { s: { r: 63, c: 6 }, e: { r: 63, c: 8 } },  /* G57:I57 */
    { s: { r: 64, c: 6 }, e: { r: 64, c: 8 } },  /* G58:I58 */
    { s: { r: 65, c: 6 }, e: { r: 65, c: 8 } },  /* G59:I59 */
    { s: { r: 66, c: 6 }, e: { r: 66, c: 8 } },  /* G60:I60 */

    // Formation
    { s: { r: 55, c: 10 }, e: { r: 55, c: 14 } },  /* B48:F48 */
  ]
  XLSX.utils.sheet_add_aoa(ws, [[t('match_report').toUpperCase()]], { origin: 'A2' });
  ws['A2'].s = titleStyles;

  // Teams, scores, stats
  const matchType = t(match.tournament) || t('other');
  XLSX.utils.sheet_add_aoa(ws, [[`${t('tournament')}: ${matchType}`]], { origin: 'B4' });
  XLSX.utils.sheet_add_aoa(ws, [[`${t('stadium')}: ${match.stadium}`]], { origin: 'B5' });

  const matchDate = new Date(match.date).toLocaleDateString();
  XLSX.utils.sheet_add_aoa(ws, [[`${t('date')}: ${matchDate}`]], { origin: 'Q4' });
  XLSX.utils.sheet_add_aoa(ws, [[`${t('half_time')}: ${match.halfTime} mins`]], { origin: 'Q5' });

  const homeTeam = match.homeAway === 'home' ? team.name : match.rival;
  XLSX.utils.sheet_add_aoa(ws, [[homeTeam]], { origin: 'B8' });
  ws['B8'].s = titleStyles;

  const awayTeam = match.homeAway === 'home' ? match.rival : team.name;
  XLSX.utils.sheet_add_aoa(ws, [[awayTeam]], { origin: 'O8' });
  ws['O8'].s = titleStyles;

  const homeScore = match.homeAway === 'home' ? match.score : match.scoreRival;
  XLSX.utils.sheet_add_aoa(ws, [[homeScore]], { origin: 'H8' });
  ws['H8'].s = titleStyles;

  const awayScore = match.homeAway === 'home' ? match.scoreRival : match.score;
  XLSX.utils.sheet_add_aoa(ws, [[awayScore]], { origin: 'L8' });
  ws['L8'].s = titleStyles;

  // Team players
  XLSX.utils.sheet_add_aoa(ws, [[t('position_gk')]], { origin: 'B10' });
  ws['B10'].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[t('position_df')]], { origin: 'C10' });
  ws['C10'].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[t('position_mf')]], { origin: 'D10' });
  ws['D10'].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[t('position_fw')]], { origin: 'E10' });
  ws['E10'].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[t('position_gk')]], { origin: 'O10' });
  ws['O10'].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[t('position_df')]], { origin: 'P10' });
  ws['P10'].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[t('position_mf')]], { origin: 'Q10' });
  ws['Q10'].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[t('position_fw')]], { origin: 'R10' });
  ws['R10'].s = centerBoldStyles;

  lineup[0].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'B' : 'O' }));
  lineup[1].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'C' : 'P' }));
  if (!lineup[3]) {
    lineup[2].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'E' : 'R' }));
  } else {
    lineup[2].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'D' : 'Q' }));
    lineup[3].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'E' : 'R' }));
  }

  rivalLineup[0].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'O' : 'B' }));
  rivalLineup[1].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'P' : 'C' }));
  if (!rivalLineup[3]) {
    rivalLineup[2].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'R' : 'E' }));
  } else {
    rivalLineup[2].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'Q' : 'D' }));
    rivalLineup[3].forEach((player, index) => printLineupPlayer({ ws, player, index, column: match.homeAway === 'home' ? 'R' : 'E' }));
  }

  // Stats

  XLSX.utils.sheet_add_aoa(ws, [[t('stats')]], { origin: 'I10' });
  ws['I10'].s = centerBoldStyles;

  printStat({ ws, homeAway: match.homeAway, stat: match.summary.onTarget, name: 'onTarget', row: 11, t });
  printStat({ ws, homeAway: match.homeAway, stat: match.summary.chances, name: 'chances', row: 12, t });
  printStat({ ws, homeAway: match.homeAway, stat: match.summary.faults, name: 'faults', row: 13, t });
  printStat({ ws, homeAway: match.homeAway, stat: match.summary.corners, name: 'corners', row: 14, t });
  printStat({ ws, homeAway: match.homeAway, stat: match.summary.offsides, name: 'offsides', row: 15, t });
  printStat({ ws, homeAway: match.homeAway, stat: match.summary.penalties, name: 'penalties', row: 16, t });
  printStat({ ws, homeAway: match.homeAway, stat: match.summary.yellowCards, name: 'yellowCards_abrv', row: 17, t });
  printStat({ ws, homeAway: match.homeAway, stat: match.summary.redCards, name: 'redCards_abrv', row: 18, t });

  // Lineup table
  XLSX.utils.sheet_add_aoa(ws, [[t('initialLineup')]], { origin: 'B22' });
  ws['B22'].s = boldStyles;

  printBigTableHeader({
    ws,
    fieldNames: playerTableHeader,
    row: 23,
    t
  });
  playerStats
    .filter((player) => initialLineup.find((initPlayer) => initPlayer.id === player.id))
    .forEach((player, i) => printBigTableRow({ ws, player, row: 24 + i, t }))

  // Bench table
  XLSX.utils.sheet_add_aoa(ws, [[t('bench')]], { origin: 'B38' });
  ws['B38'].s = boldStyles;

  printBigTableHeader({
    ws,
    fieldNames: playerTableHeader,
    row: 39,
    t
  })
  playerStats
    .filter((player) => !initialLineup.find((initPlayer) => initPlayer.id === player.id))
    .forEach((player, i) => printBigTableRow({ ws, player, row: 40 + i, t }))

  // Substitutions
  XLSX.utils.sheet_add_aoa(ws, [[t('substitutions')]], { origin: 'B56' });
  ws['B56'].s = boldStyles;

  ['part', 'minutes', 'subs_in', 'subs_out'].forEach((field, index) => {
    const cellAddress = `${columnsOrder[index < 3 ? index + 1 : index + 3]}57`;
    XLSX.utils.sheet_add_aoa(ws, [[t(field)]], { origin: cellAddress });
    ws[cellAddress].s = tableHeaderStyles;
  });
  substitutions.reverse().forEach((sub, index) => printSubstitutionRow({ ws, sub, row: 58 + index }));

  // Formations
  XLSX.utils.sheet_add_aoa(ws, [[t('formations_changes')]], { origin: 'K56' });
  ws['K56'].s = boldStyles;

  ['part', 'minutes', 'from', 'to'].forEach((field, index) => {
    const cellAddress = `${columnsOrder[index + 10]}57`;
    XLSX.utils.sheet_add_aoa(ws, [[t(field)]], { origin: cellAddress });
    ws[cellAddress].s = tableHeaderStyles;
  });
  printFormationRow({ ws, formChange: { part: '0', minute: '0', oldFormation: '', newFormation: match.initialFormation }, row: 58 })
  formations.reverse().forEach((formChange, index) => printFormationRow({ ws, formChange, row: 59 + index }));

  ///////////////////////////////////////////////// Write
  XLSX.writeFile(wb, 'out.xlsx', { bookSST: true });
}

const printFormationRow = ({ ws, formChange, row }) => {
  XLSX.utils.sheet_add_aoa(ws, [[formChange.part]], { origin: `K${row}` });
  ws[`K${row}`].s = centerStylesSmall;
  XLSX.utils.sheet_add_aoa(ws, [[formChange.minute]], { origin: `L${row}` });
  ws[`L${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[formChange.oldFormation.split('').join('-')]], { origin: `M${row}` });
  ws[`M${row}`].s = centerStylesSmall;
  XLSX.utils.sheet_add_aoa(ws, [[formChange.newFormation.split('').join('-')]], { origin: `N${row}` });
  ws[`N${row}`].s = centerStylesSmall;
}

const printSubstitutionRow = ({ ws, sub, row }) => {
  XLSX.utils.sheet_add_aoa(ws, [[sub.part]], { origin: `B${row}` });
  ws[`B${row}`].s = centerStylesSmall;
  XLSX.utils.sheet_add_aoa(ws, [[sub.minute]], { origin: `C${row}` });
  ws[`C${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[getPlayerNameAndNumber(sub.playerIn)]], { origin: `D${row}` });
  ws[`D${row}`].s = centerStylesSmall;
  XLSX.utils.sheet_add_aoa(ws, [[getPlayerNameAndNumber(sub.playerOut)]], { origin: `G${row}` });
  ws[`G${row}`].s = centerStylesSmall;
}

const printLineupPlayer = ({ ws, player, index, column }) => {
  const cellAddress = `${column}${11 + index}`;
  XLSX.utils.sheet_add_aoa(ws, [[formatPlayerNameAndNumber(player)]], { origin: cellAddress });
  ws[cellAddress].s = stylesExtraSmall
}

const printStat = ({ ws, homeAway, stat, name, row, t }) => {
  const homeValue = homeAway === 'home' ? stat.us : stat.rival;
  const awayValue = homeAway === 'home' ? stat.rival : stat.us;

  XLSX.utils.sheet_add_aoa(ws, [[t(name)]], { origin: `I${row}` });
  ws[`I${row}`].s = centerStyles;
  XLSX.utils.sheet_add_aoa(ws, [[homeValue]], { origin: `H${row}` });
  ws[`H${row}`].s = centerBoldStyles;
  XLSX.utils.sheet_add_aoa(ws, [[awayValue]], { origin: `L${row}` });
  ws[`L${row}`].s = centerBoldStyles;
}

const printBigTableHeader = ({ ws, fieldNames, row, t }) => {
  fieldNames.forEach((field, index) => {
    const cellAddress = `${columnsOrder[index < 2 ? index + 1 : index + 3]}${row}`;
    XLSX.utils.sheet_add_aoa(ws, [[t(field)]], { origin: cellAddress });
    ws[cellAddress].s = tableHeaderStyles;
  })
}

const printBigTableRow = ({ ws, player, row, t }) => {
  XLSX.utils.sheet_add_aoa(ws, [[player.number || '-']], { origin: `B${row}` });
  ws[`B${row}`].s = centerBoldStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[`${player.name} ${player.lastname}`]], { origin: `C${row}` });
  ws[`C${row}`].s = centerStylesSmall;

  const roleKey = player.role === 'gk' ? 'role_gk_abrv' : `role_${player.position}_abrv`;
  XLSX.utils.sheet_add_aoa(ws, [[t(roleKey)]], { origin: `F${row}` });
  ws[`F${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[player.stats.minutesPlayed]], { origin: `G${row}` });
  ws[`G${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[player.stats.goal]], { origin: `H${row}` });
  ws[`H${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[player.stats.chance]], { origin: `I${row}` });
  ws[`I${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[player.stats.fault]], { origin: `J${row}` });
  ws[`J${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[player.stats.corner]], { origin: `K${row}` });
  ws[`K${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[player.stats.offside]], { origin: `L${row}` });
  ws[`L${row}`].s = centerStylesSmall;

  XLSX.utils.sheet_add_aoa(ws, [[player.stats.yellowCards]], { origin: `M${row}` });
  ws[`M${row}`].s = centerStylesSmall;

  const redCards = player.stats.yellowCards && player.stats.yellowCards > 1 ? 1 : player.stats.redCards;
  XLSX.utils.sheet_add_aoa(ws, [[redCards]], { origin: `N${row}` });
  ws[`N${row}`].s = centerStylesSmall;
}

const columnsOrder = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
}