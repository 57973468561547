import React, { useState } from 'react';

import Modal from '../../Modal';
import TagTrans from '../../TagTrans';
import { ModalButtons } from '../../Layout';
import { ButtonDarkBig, ButtonGreenBig } from '../../Button';
import { getPlayerNameAndNumber } from '../../../logic/football';

import ActionControls from './ActionControls';
import ActionInfo from './ActionInfo';
import './ActionRecord.scss';

const hiddenControlsActions = ['offside', 'corner'];

const ActionRecord = ({ show, onCancel, player, action, onActionSaved }) => {
  const actionName = action ? action.action : '';
  const [actionRecord, setActionRecord] = useState({});

  const updateActionInfo = actionInfo => {
    setActionRecord({
      ...actionRecord,
      actionInfo: {
        ...actionRecord.actionInfo,
        ...actionInfo
      }
    })
  }

  const saveAction = () => {
    const { action: actionName } = action;
    if (actionName === 'substitution' && !actionRecord.actionInfo.substitutionPlayer) return;
    onActionSaved({ ...action, ...actionRecord })
    setActionRecord({})
  }

  const handleOnCancel = () => {
    setActionRecord({});
    onCancel();
  }

  const getActionTitle = () => {
    const type = action ? action.type : '';
    const playerName = player ? `${getPlayerNameAndNumber(player)} ` : '';

    if (type === 'us') {
      return <>{playerName}<TagTrans tag={`action_title_${actionName}`} /></>;
    } else {
      return <>{playerName}<TagTrans tag="rival" /> <TagTrans tag={`action_title_${actionName}`} /></>;
    }
  }

  return (
    <Modal show={show} onCancel={handleOnCancel}>
      <div className="match-action-record">
        <div className="match-action-record-title">
          {getActionTitle()}
        </div>
        <div className="match-action-record-subtitle">
          (<TagTrans tag={`action_subtitle_${actionName}`} />)
        </div>
        <div className="match-action-record-controls">
          {actionName && !hiddenControlsActions.includes(actionName) 
            && <ActionControls action={action} onUpdateAction={updateActionInfo} />}
        </div>
        <div className="match-action-record-info">
          <ActionInfo action={action} onUpdateAction={updateActionInfo} />
        </div>
      </div>
      <ModalButtons>
        <ButtonDarkBig onClick={handleOnCancel}>
          <TagTrans tag="cancel"/>
        </ButtonDarkBig>
        <ButtonGreenBig onClick={saveAction}>
          <TagTrans tag="save" />
        </ButtonGreenBig>
      </ModalButtons>
    </Modal>
  );
}
 
export default ActionRecord;
