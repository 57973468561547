import React from 'react';

import FlexWide from '../FlexWide';
import FlexCenter from '../FlexCenter';
import AppContainer from '../AppContainer';

import './ModalButtons.scss';

const ModalButtons = ({ children }) => {
  const buttonsNumber = Array.isArray(children) ? children.filter(c => !!c).length : 1;
  return (
    <div className="modal-buttons">
      <div className="modal-buttons-content">
        <AppContainer padding>
          {buttonsNumber === 1 
            ? <FlexCenter>{children}</FlexCenter>
            : <FlexWide>{children}</FlexWide>
          }
        </AppContainer>
      </div>
    </div>
  );
}
 
export default ModalButtons;
