import { useState, useEffect } from 'react'

const APP_MAX_WIDTH = 600;

const getWidth = () => Math.min(window.innerWidth, APP_MAX_WIDTH);

export default function useAppWidth() {
  const [width, setWidth] = useState(getWidth());

  function handleSizeChange() {
    return setWidth(getWidth());
  }

  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);

    return () => {
      window.removeEventListener("resize", handleSizeChange);
    };
  }, []);

  return width;
}
