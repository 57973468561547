import React from 'react';
import { useSelector, useDispatch, createDispatchHook } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from '../../services/auth';
import { toggleMenu } from '../../redux/actions/menuActions';
import { setLang } from '../../redux/actions/langActions';
import Logo from '../Logo';
import { AppContainer } from '../Layout';
import { ButtonGrayBig, ButtonGraySmall, ButtonTransparentSmall } from '../Button';
import TagTrans from '../TagTrans';

import './Menu.scss';

const Menu = ({ history, isHome = false }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const goTo = (page) => {
    !isHome && dispatch(toggleMenu());
    history.push(page);
  };

  const signOut = () => {
    logout();
    goTo('');
  }

  const setLanguage = lang => {
    dispatch(setLang(lang));
  }

  return (
    <AppContainer padding>
      <Logo />
      {user
        ? (
          <div className="menu-nav-options">
            <ButtonGrayBig onClick={() => goTo('/my-teams')}><TagTrans tag="my_teams" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => goTo('/new-report')}><TagTrans tag="new_report" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => goTo('/pending-reports')}><TagTrans tag="pending_report" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => goTo('/archive')}><TagTrans tag="reports_history" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => goTo('/stats')}><TagTrans tag="stats" /></ButtonGrayBig>
            <div className="menu-horizontal language-options">
              <ButtonTransparentSmall onClick={() => setLanguage('es')}>🇪🇸</ButtonTransparentSmall>
              <ButtonTransparentSmall onClick={() => setLanguage('en')}>🇬🇧</ButtonTransparentSmall>
            </div>
            <ButtonGraySmall onClick={() => signOut()}><TagTrans tag="logout" /></ButtonGraySmall>
          </div>
        ) : (
          <div className="menu-nav-options">
            <ButtonGrayBig onClick={() => goTo('/login')}><TagTrans tag="login" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => goTo('/signup')}><TagTrans tag="signup" /></ButtonGrayBig>
          </div>
        )}
    </AppContainer>
  );
}

export default withRouter(Menu);