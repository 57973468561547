import React from 'react';

import Modal from '../../Modal';
import PlayerRole from '../../PlayerRole';
import { ButtonGrayBig } from '../../Button';
import TagTrans from '../../TagTrans';
import { MATCH_ACTIONS } from '../../../logic/football';
import { ModalButtons, FlexCenter } from '../../Layout';

import './ActionSelect.scss';

const ActionSelect = ({ action, show, onCancel, player, onActionSelected }) => {
  const playerNumber = player && player.number ? `${player.number}.` : '';

  const handleActionButtonClicked = actionName => {
    onActionSelected({ ...action, action: actionName });
  }

  return (
    <Modal show={show} onCancel={onCancel}>
      <div className="match-action-select">
        <div className="match-action-select-title">
          {player 
            ? <>{`${playerNumber} ${player.name} ${player.lastname}`}<PlayerRole role={player.role}/></>
            : <TagTrans tag="add_rival_stats" />}
        </div>
        <div className="match-action-select-two-buttons">
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.OWN_GOAL)}><TagTrans tag="action_own_goal" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.GOAL)}><TagTrans tag="action_goal" /></ButtonGrayBig>
        </div>
        <div className="match-action-select-two-buttons">
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.FAULT)}><TagTrans tag="action_fault" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.CARD)}><TagTrans tag="action_card" /></ButtonGrayBig>
        </div>
        <div className="match-action-select-two-buttons">
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.CORNER)}><TagTrans tag="action_corner" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.OFFSIDE)}><TagTrans tag="action_offside" /></ButtonGrayBig>
        </div>
        <div className="match-action-select-two-buttons">
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.SUBSTITUTION)}><TagTrans tag="action_substitution" /></ButtonGrayBig>
            <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.CHANGE_POS)}><TagTrans tag="action_change_pos" /></ButtonGrayBig>
        </div>
        <div className="match-action-select-one-button">
          <ButtonGrayBig onClick={() => handleActionButtonClicked(MATCH_ACTIONS.CHANCE)}><TagTrans tag="action_chance" /></ButtonGrayBig>
        </div>
      </div>
      <ModalButtons>
        <FlexCenter>
          <ButtonGrayBig onClick={onCancel}>
            <TagTrans tag="cancel"/>
          </ButtonGrayBig>
        </FlexCenter>
      </ModalButtons>
    </Modal>
  );
}
 
export default ActionSelect;
