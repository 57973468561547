import React from 'react';

import { ButtonBlueSmall, ButtonGraySmall } from '../../Button'
import TagTrans from '../../TagTrans';

import './SelectButtons.scss';

const SelectButtons = ({ options, onChange, value, direction = 'row' }) => {

  const handleButtonClick = (value) => {
    const event = {
      target: { value }
    }
    onChange(event)
  }

  const labelDisplay = option => (
    option.labelTag 
      ? <TagTrans tag={option.labelTag} />
      : option.label
  );
  return (
    <div className={`select-buttons mode-${direction}`}>
      {options && options.map(option => (
        <div key={option.value} className="select-buttons-option">
          {option.value === value 
            ? <ButtonBlueSmall>{labelDisplay(option)}</ButtonBlueSmall>
            : <ButtonGraySmall onClick={() => handleButtonClick(option.value)}>{labelDisplay(option)}</ButtonGraySmall>
          }
        </div>
      ))}
    </div>
  );
}
 
export default SelectButtons;