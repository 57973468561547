import React, { useState } from 'react';

import { useAppWidth, useAppHeight } from '../../customHooks'
import playFieldAttackBg from '../PlayField/play-field-attack.png';
import playFieldDefenseBg from '../PlayField/play-field-defense.png';

import './ZonesField.scss';

const APP_MODAL_CONTENT_WIDTH_DIFF = 20;

const ZonesFieldAttack = ({
  onZoneSelected,
  selectedZone,
  fieldHeight,
  simpleRowHeight,
  doubleRowHeight,
  showDefenseTop,
  showDefenseBottom,
}) => {
  return (
    <div className="zones-field zones-field-attack" style={{ height: `${fieldHeight}px`, backgroundImage: `url(${playFieldAttackBg})` }}>
      <div className="zones-field-row attack-top" style={{ height: `${simpleRowHeight}px` }}>
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-top-left')} 
          className={`zones-field-row-left ${selectedZone === 'attack-top-left' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-top-center')} 
          className={`zones-field-row-center ${selectedZone === 'attack-top-center' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-top-right')} 
          className={`zones-field-row-right ${selectedZone === 'attack-top-right' ? 'selected' : ''}`}
        />
      </div>
      <div className="zones-field-row attack-mid" style={{ height: `${simpleRowHeight}px` }}>
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-mid-left')} 
          className={`zones-field-row-left ${selectedZone === 'attack-mid-left' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-mid-center')} 
          className={`zones-field-row-center ${selectedZone === 'attack-mid-center' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-mid-right')} 
          className={`zones-field-row-right ${selectedZone === 'attack-mid-right' ? 'selected' : ''}`}
        />
      </div>
      <div className="zones-field-row attack-bottom" style={{ height: `${simpleRowHeight}px` }}>
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-bottom-left')} 
          className={`zones-field-row-left ${selectedZone === 'attack-bottom-left' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-bottom-center')} 
          className={`zones-field-row-center ${selectedZone === 'attack-bottom-center' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('attack-bottom-right')} 
          className={`zones-field-row-right ${selectedZone === 'attack-bottom-right' ? 'selected' : ''}`}
        />
      </div>
      <div className="zones-field-row defense-top" style={{ height: `${doubleRowHeight}px` }}>
        {showDefenseTop && (
          <>
            <div 
              role="button" 
              onClick={() => onZoneSelected('defense-top-left')} 
              className={`zones-field-row-left ${selectedZone === 'defense-top-left' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('defense-top-center')} 
              className={`zones-field-row-center ${selectedZone === 'defense-top-center' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('defense-top-right')} 
              className={`zones-field-row-right ${selectedZone === 'defense-top-right' ? 'selected' : ''}`}
            />
          </>
        )}
      </div>
      <div className="zones-field-row defense-bottom" style={{ height: `${doubleRowHeight}px` }}>
        {showDefenseBottom && (
          <>
            <div 
              role="button" 
              onClick={() => onZoneSelected('defense-bottom-left')} 
              className={`zones-field-row-left ${selectedZone === 'defense-bottom-left' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('defense-bottom-center')} 
              className={`zones-field-row-center ${selectedZone === 'defense-bottom-center' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('defense-bottom-right')} 
              className={`zones-field-row-right ${selectedZone === 'defense-bottom-right' ? 'selected' : ''}`}
            />
          </>
        )}
      </div>
    </div>
  );
};

const ZonesFieldDefense = ({
  onZoneSelected,
  selectedZone,
  fieldHeight,
  simpleRowHeight,
  doubleRowHeight,
  showDefenseBottom
}) => {
  return (
    <div className="zones-field zones-field-defense" style={{ height: `${fieldHeight}px`, backgroundImage: `url(${playFieldDefenseBg})` }}>
      <div className="zones-field-row attack-top" style={{ height: `${doubleRowHeight}px` }}>
        {showDefenseBottom && (
          <>
            <div 
              role="button" 
              onClick={() => onZoneSelected('attack-top-left')} 
              className={`zones-field-row-left ${selectedZone === 'attack-top-left' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('attack-top-center')} 
              className={`zones-field-row-center ${selectedZone === 'attack-top-center' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('attack-top-right')} 
              className={`zones-field-row-right ${selectedZone === 'attack-top-right' ? 'selected' : ''}`}
            />
          </>
        )}
      </div>
      <div className="zones-field-row attack-bottom" style={{ height: `${doubleRowHeight}px` }}>
        {showDefenseBottom && (
          <>
            <div 
              role="button" 
              onClick={() => onZoneSelected('attack-bottom-left')} 
              className={`zones-field-row-left ${selectedZone === 'attack-bottom-left' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('attack-bottom-center')} 
              className={`zones-field-row-center ${selectedZone === 'attack-bottom-center' ? 'selected' : ''}`}
            />
            <div 
              role="button" 
              onClick={() => onZoneSelected('attack-bottom-right')} 
              className={`zones-field-row-right ${selectedZone === 'attack-bottom-right' ? 'selected' : ''}`}
            />
          </>
        )}
      </div>
      <div className="zones-field-row defense-top" style={{ height: `${simpleRowHeight}px` }}>
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-top-left')} 
          className={`zones-field-row-left ${selectedZone === 'defense-top-left' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-top-center')} 
          className={`zones-field-row-center ${selectedZone === 'defense-top-center' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-top-right')} 
          className={`zones-field-row-right ${selectedZone === 'defense-top-right' ? 'selected' : ''}`}
        />
      </div>
      <div className="zones-field-row defense-mid" style={{ height: `${simpleRowHeight}px` }}>
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-mid-left')} 
          className={`zones-field-row-left ${selectedZone === 'defense-mid-left' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-mid-center')} 
          className={`zones-field-row-center ${selectedZone === 'defense-mid-center' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-mid-right')} 
          className={`zones-field-row-right ${selectedZone === 'defense-mid-right' ? 'selected' : ''}`}
        />
      </div>
      <div className="zones-field-row defense-bottom" style={{ height: `${simpleRowHeight}px` }}>
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-bottom-left')} 
          className={`zones-field-row-left ${selectedZone === 'defense-bottom-left' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-bottom-center')} 
          className={`zones-field-row-center ${selectedZone === 'defense-bottom-center' ? 'selected' : ''}`}
        />
        <div 
          role="button" 
          onClick={() => onZoneSelected('defense-bottom-right')} 
          className={`zones-field-row-right ${selectedZone === 'defense-bottom-right' ? 'selected' : ''}`}
        />
      </div>
    </div>
  );
};

const ZonesField = ({ type, onZoneSelected, actionName }) => {
  const [selectedZone, setSelectedZone] = useState('');
  const appWidth = useAppWidth() - APP_MODAL_CONTENT_WIDTH_DIFF;
  const appHeight = useAppHeight();
  const fieldHeight = appWidth * (appHeight > 750 ? 1.2 : 1);
  const simpleRowHeight = fieldHeight / 5.95;
  const doubleRowHeight = simpleRowHeight * 1.5;

  const handleSelectZone = zone => {
    setSelectedZone(zone);
    onZoneSelected(zone);
  }

  return (
    <div className="zones-field-bg">
      { type === 'attack'
        ? <ZonesFieldAttack 
            onZoneSelected={handleSelectZone} 
            selectedZone={selectedZone}
            fieldHeight={fieldHeight}
            simpleRowHeight={simpleRowHeight}
            doubleRowHeight={doubleRowHeight}
            showDefenseTop={actionName !== 'offside'}
            showDefenseBottom={actionName !== 'offside' && actionName !== 'goal'}
          />
        : <ZonesFieldDefense 
            onZoneSelected={handleSelectZone} 
            selectedZone={selectedZone}
            fieldHeight={fieldHeight} 
            simpleRowHeight={simpleRowHeight}
            doubleRowHeight={doubleRowHeight}
            showDefenseBottom={actionName !== 'offside' && actionName !== 'goal'}
          />
      }
    </div>
  );
};
 
export default ZonesField;