import React from 'react';
import { useSelector } from 'react-redux';

import Menu from './Menu';
import './MenuNav.scss';

const MenuNav = () => {
  const isMenuOpen = useSelector(state => state.menu);
  
  return (
    <div className={`menu-nav ${isMenuOpen ? 'opened' : ''}`}>
        <Menu />
    </div>
  );
}
 
export default MenuNav;
