import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOnClickOutside from '@bit/iamtechnologies.iamtech-js.use-on-click-outside';

import TagTrans from '../../TagTrans';

import './Select.scss';

const defaultGetLabel = record => record.label;
const defaultGetValue = record => record.value;



const Select = ({ value, onChange, error, options, getLabel = defaultGetLabel, getValue = defaultGetValue }) => {
  const selectRef = useRef(null);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const selectedOptions = options.filter(op => value && getValue(op) === value);
  
  useOnClickOutside(selectRef, () =>setOptionsOpen(false));
  
  const labelDisplay = option => (
    option.labelTag 
    ? <TagTrans tag={option.labelTag} />
    : getLabel(option)
  );
    
  const displayValue = value && selectedOptions && selectedOptions.length ? labelDisplay(selectedOptions[0]) : '';

  return (
    <div className="form-select" role="button" ref={selectRef} onClick={() => setOptionsOpen(!optionsOpen)}>
      <div className="form-select-box">
        <span>{displayValue}</span>
        {optionsOpen 
          ? <FontAwesomeIcon icon="chevron-up" color="#1d2d62" />
          : <FontAwesomeIcon icon="chevron-down" color="#1d2d62" />
        }
      </div>
      <div className={`options ${optionsOpen ? 'open' : ''}`}>
        {options.map(op => (
          <div 
            className="options-item" 
            key={getValue(op)} 
            role="button" 
            onClick={() => {
              const event = {
                target: {
                  label: labelDisplay(op),
                  value: getValue(op)
                }
              }
              onChange(event)}}
          >
            {labelDisplay(op)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
