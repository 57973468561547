export default {
  yes: 'Yes',
  no: 'No',
  deletePlayer: 'Delete player',
  confirmDeletePlayer: 'Do you really want to remove this player?',
  deleteTeam: 'Delete team',
  confirmDeleteTeam: 'Do you really want to remove this team? You are going to loose all its data and reports',
  deleteReport: 'Delete report',
  confirmDeleteReport: 'Do you really want to remove this report?',
  all: 'All',
  // MENU
  my_teams: 'My teams',
  reports_history: 'Reports History',
  new_report: 'New report',
  pending_report: 'Pending report',
  logout: 'Logout',
  delete: 'Delete',
  edit: 'Edit',
  send: 'Send',
  exit: 'Exit',
  // GENERAL
  stats: 'Stats',
  goalsAgainst: 'Goals Against',
  season: 'Season',
  team: 'Team',
  cancel: 'Cancel',
  login: 'Login',
  create_account: 'Create account',
  no_account_signup: 'You don\'t have an account? Go to',
  yes_account_login: 'Do you already have an account? Go to',
  signup: 'Signup',
  name: 'Name',
  lastname: 'Lastname',
  club: 'Club',
  email: 'Email',
  password: 'Password',
  date: 'Date',
  home: 'Home',
  away: 'Away',
  no_results: 'No results',
  my_team: 'My team',
  player: 'Player',
  players: 'Players',
  add_player: 'Add player',
  add_players: 'Add players',
  save_player: 'Save player',
  add_team: 'Add team',
  save_team: 'Save team',
  role: 'Role',
  position: 'Position',
  options: 'Options',
  select_option: "Select option",
  save: 'Save',
  foot: 'Foot',
  head: 'Head',
  stopped_ball: 'Stopped ball',
  strategy: 'Strategy',
  direct_free_kick: 'Direct Free Kick',
  indirect_free_kick: 'Indirect Free Kick',
  penalty: 'Penalty',
  penalties: 'Penalties',
  left: 'Left',
  right: 'Right',
  number: 'Number',
  other: 'Other',
  type: 'Type',
  export_xls: 'Export XLS',
  from: 'From',
  to: 'To',
  // MATCH
  match_report: 'Match Report',
  match_data: 'Match data',
  tournament: 'Tournament',
  stadium: 'Stadium',
  rival: 'Opponent',
  home_away: 'Home/Away',
  mode: 'Mode',
  volatile_changes: 'Free changes',
  half_time: 'Minutes per half',
  go_lineup: 'Go Lineup',
  formation: 'Formation',
  selected_players: 'Selected players',
  bench: 'Bench',
  go_to_match: 'Go to match',
  half_1: '1st Half',
  half_2: '2nd Half',
  half_3: '3rd Half',
  half_4: '4th Half',
  half_5: '5th Half',
  half_6: '6th Half',
  status_about_start: '(starting)',
  status_playing: '(playing)',
  status_paused: '(paused)',
  status_between_part: '(resting)',
  status_finished: '(finished)',
  status_suspended: '(suspended)',
  summary: 'Summary',
  timeline: 'Timeline',
  add_rival_stats: 'ADD OPPONENT STATS',
  finish_part: 'Finish current part',
  finish_match: 'Finish match',
  suspend_match: 'Suspend match',
  undo_last_action: 'Undo last action',
  // LINEUP
  lineup_selection: 'Lineup selection',
  // STATS
  goals: 'Goals',
  chances: 'Total Chances',
  chances_abrv: 'Chances',
  onTarget: 'Shoots On Target',
  faults: 'Faults',
  faultsCommitted: 'Faults Committed',
  corner: 'Corner',
  corners: 'Corners',
  offsides: 'Offsides',
  yellowCards: 'Yellow Cards',
  yellowCards_abrv: 'Y. Cards',
  redCards: 'Red Cards',
  redCards_abrv: 'R. Cards',
  out: 'Out',
  assist: 'Assist',
  // POSITIONS
  position_gk: 'GK',
  position_df: 'DF',
  position_mf: 'MF',
  position_fw: 'FW',
  // ROLE POSITIONS
  role_gk: 'Goalkeeper',
  role_lb: 'Left Back',
  role_cb: 'Centre Back',
  role_rb: 'Right Back',
  role_dm: 'Defensive Midfield',
  role_lm: 'Left  Midfield',
  role_cm: 'Central Midfield',
  role_rm: 'Right Midfield',
  role_lw: 'Left Winger',
  role_rw: 'Right Winger',
  role_am: 'Attacking Midfield',
  role_lf: 'Left Forward',
  role_cf: 'Centre Forward',
  role_rf: 'Right Forward',
  role_gk_abrv: 'PT',
  role_lb_abrv: 'LB',
  role_cb_abrv: 'CB',
  role_rb_abrv: 'RB',
  role_dm_abrv: 'DM',
  role_lm_abrv: 'LM',
  role_cm_abrv: 'CM',
  role_rm_abrv: 'RM',
  role_lw_abrv: 'LW',
  role_rw_abrv: 'RW',
  role_am_abrv: 'AM',
  role_lf_abrv: 'LF',
  role_cf_abrv: 'CF',
  role_rf_abrv: 'RF',
  // ACTIONS
  action_match_start: 'Match started',
  action_match_paused: 'Match paused',
  action_match_suspended: 'Match suspended',
  action_match_resumed: 'Match resumed',
  action_match_finished: 'Match finished',
  action_change_formation: 'Change formation',
  action_part_finished: 'Part finished',
  action_own_goal: 'Own Goal',
  action_goal: 'Goal',
  action_fault: 'Fault',
  action_card: 'Card',
  action_corner: 'Corner',
  action_offside: 'Offside',
  action_substitution: 'Substitution',
  action_change_pos: 'Change Pos.',
  action_chance: 'Chance',
  action_title_goal: 'Scores!',
  action_title_fault: 'Did fault',
  action_title_card: 'Got a card',
  action_title_offside: 'Offside',
  action_title_substitution: 'Subst.',
  action_title_change_pos: 'Position change',
  action_title_chance: 'Had a chance',
  action_title_change_formation: 'Change formation',
  action_subtitle_goal: 'Select goal zone',
  action_subtitle_fault: 'Select card and fault zone',
  action_subtitle_card: 'Select card color',
  action_subtitle_offside: 'Select offside zone',
  action_subtitle_substitution: 'Select player going in',
  action_subtitle_change_pos: 'Select player to exchange position with',
  action_subtitle_chance: 'Select chance zone',
  action_subtitle_change_formation: 'Selecct new formation system',
  // LOG
  part_name: 'T',
  subs_in: 'In',
  subs_out: 'Out',
  change_formation_new: 'New formation',
  // ERRORS
  isRequired: 'This field is mandatory',
  isEmail: 'Invalid format for email',
  // TOURNAMENTS
  league: 'League',
  friendly: 'Friendly',
  other: 'Others',
  // REPORT
  initialFormation: 'Initial formation',
  substitutions: 'Substitutions',
  formations_changes: 'Formations changes',
  players_stats: 'Players Stats',
  minutes: 'Minutes',
  part: 'Part',
  home: 'Local',
  away: 'Away',
  initialLineup: 'Initial lineup',
  number_abrv: 'Nº',
  minutes_abrv: 'Mins',
  offsided_abrv: 'O.S.',
  observations: 'Observations',
  gameSystem: 'Game System',
  goals_abrv: 'G',
  goals_rival_abrv: 'G.C.',
  faults_abrv: 'F',
  // ONE LETTER
  goals_initials: 'G.',
  goals_rival_initials: 'G.A.',
  chances_initials: 'Ch.',
  faults_initials: 'F.',
  yellowCards_initials: 'T.A.',
  redCards_initials: 'T.R.',
};
