export default {
  firebase: {
    apiKey: 'AIzaSyC3ylt4-xzxPf8TCqn1nhWFJ2agbRlK4Bg',
    authDomain: 'footie-app-139ea.firebaseapp.com',
    databaseURL: 'https://footie-app-139ea.firebaseio.com',
    projectId: 'footie-app-139ea',
    storageBucket: 'footie-app-139ea.appspot.com',
    messagingSenderId: '426429160985'
  }
};
