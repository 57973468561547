import React from 'react';
import { useLanguage } from '../../customHooks';
import getTranslation from '../../logic/translations';


const TagTrans = ({ tag }) => {
  const currentLang = useLanguage();

  return <span>{getTranslation(tag, currentLang)}</span>;
};

export default TagTrans;
