import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AppContainer } from '../../Layout';
import ResponsiveImage from '../../ResponsiveImg';
import { useTeam } from '../../../customHooks';

import './Scoreboard.scss';

const Scoreboard = () => {
  const match =  useSelector(state => state.match);
  const team = useTeam(match.team);
 
  if (!team) return null;

  return (
    <div className="match-scoreboard">
      <AppContainer>
        <div className="match-scoreboard-content">
          <div className={`match-scoreboard-team home ${match.homeAway === 'home' ? 'my-team' : ''}`}>
            <ResponsiveImage imageName="home" />
            <span>{match.homeAway === 'home' ? team.name : match.rival}</span>
          </div>
          <div className="match-scoreboard-goals">
            {match.homeAway === 'home' 
              ? <><span className="my-team">{match.score}</span>&nbsp;-&nbsp;<span>{match.scoreRival}</span></>
              : <><span>{match.scoreRival}</span>&nbsp;-&nbsp;<span className="my-team">{match.score}</span></>
            }
          </div>
          <div className={`match-scoreboard-team away ${match.homeAway === 'away' ? 'my-team' : ''}`}>
            <span>{match.homeAway === 'home' ? match.rival : team.name}</span>
            <ResponsiveImage imageName="away" />
          </div>
        </div>
      </AppContainer>
    </div>
  );
}
 
export default Scoreboard;
