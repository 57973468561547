import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FormField, FormRow, SelectButtons, Input, Select } from '../Form';
import getTranslation from '../../logic/translations';
import { ROLE_OPTIONS, ROLE_POSITIONS, FOOT_OPTIONS } from '../../logic/football';


const AddPlayerForm = ({ useInput, errors, showErrors, onSubmit = () => {}, player = {}, rival, values }) => {
  const lang = useSelector(state => state.lang);
  const [positionOptions, setPositionOptions] = useState(player && player.role ? ROLE_POSITIONS[player.role] : []);
  
  useEffect(() => {
    setPositionOptions(values && values.role ? ROLE_POSITIONS[values.role] : []);
  }, [values.role]);

  return (
    <form onSubmit={onSubmit}>
      <FormField 
        label={`${getTranslation('name', lang)}${rival ? '':'*'}`} 
        name="name" 
        errors={showErrors.name ? errors.name : []}
      >
        <Input name="name" {...useInput('name', rival ? '' : 'isRequired')}/>
      </FormField>
      <FormField 
        label={`${getTranslation('lastname', lang)}`} 
        name="lastname" 
        errors={showErrors.lastname ? errors.lastname : []}
      >
        <Input name="lastname" {...useInput('lastname')}/>
      </FormField>
      <FormField 
        label={`${getTranslation('role', lang)}${rival ? '' : '*'}`} 
        name="role" 
        errors={showErrors.role ? errors.role : []}
      >
        <SelectButtons options={ROLE_OPTIONS} {...useInput('role', rival ? '' : 'isRequired')}/>
      </FormField>
      <FormField 
        label={`${getTranslation('position', lang)}`} 
        name="position" 
        errors={showErrors.position ? errors.position : []}
      >
        <Select 
          name="position"
          options={positionOptions}
          getLabel={record => getTranslation(record.labelTag, lang)} 
          {...useInput('position')}
        />
      </FormField>
      <FormRow fields="53">
        <FormField 
          label={`${getTranslation('foot', lang)}${rival ? '' : '*'}`} 
          name="foot" 
          errors={showErrors.foot ? errors.foot : []}
        >
          <SelectButtons options={FOOT_OPTIONS} {...useInput('foot', rival ? '' : 'isRequired')}/>
        </FormField>
        <FormField 
          label={`${getTranslation('number', lang)}${rival ? '*' : ''}`} 
          name="number" 
          errors={showErrors.number ? errors.number : []}
        >
          <Input name="number" {...useInput('number', rival ? 'isRequired' : '')}/>
        </FormField>
      </FormRow>
    </form>
  );
}
 
export default AddPlayerForm;{}