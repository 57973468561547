import React from 'react';
import Button from './Button';

const ButtonGreenBig = ({ children, onClick, ...otherProps }) => (
  <Button
    size="big"
    color="green"
    onClick={onClick}
    {...otherProps}
  >
    {children}
  </Button>
);

export default ButtonGreenBig;
