import es from './es';
import en from './en';

const languages = {
  es,
  en
};

export default function getTranslation(key, lang = 'es') {
  return languages[lang] && languages[lang][key] ? languages[lang][key] : '';
}
