import { useState, useEffect } from 'react';

import { getAllFiltered } from '../services/database';
import { sortPlayersByRole } from '../logic/football';

const useTeamPlayers = teamId => {
  const [teamPlayers, setTeamPlayers] = useState([]);

  useEffect(() => {
    const fetchPlayers = async () => {
      const players = await getAllFiltered('players', [{field: 'team', condition: '==', value: teamId}])
      setTeamPlayers(sortPlayersByRole(players));
    };
    teamId && fetchPlayers();
  }, [teamId]);
  
  return teamPlayers;
};

export default useTeamPlayers;
