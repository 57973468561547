import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OptionsButton = ({ onClick, optionsOpened }) => {
  const handleOptionsClick = (event) => {
    event.stopPropagation();
    onClick();
  }

  return (
    <div role="button" className={`options-button ${optionsOpened ? 'opened': ''}`} onClick={handleOptionsClick}>
      {optionsOpened ? <FontAwesomeIcon icon="times" color="#1b1f24" /> : '...'}
    </div>
  );
}
 
export default OptionsButton;