import React from 'react';
import ReactDOM from 'react-dom';

import { AppContainer } from '../Layout';

import './Modal.scss';

const Modal = ({ children, onCancel, show }) => {
  return ReactDOM.createPortal(
    <ModalPortal show={show} onCancel={onCancel}>{children}</ModalPortal>,
    document.getElementById('root-modal')
  );
};
 
const ModalPortal = ({ children, onCancel, show }) => {
  const classes = ['modal', show ? 'show' : ''];

  return (
    <div className={classes.join(' ')}> 
      <div role="button" className="modal-bg" onClick={onCancel} />
      <div className="modal-content">
        {children}
      </div>
    </div>
  )
};

export default Modal;