import React from 'react';

import ZonesField from '../../ZonesField';

const attackActions = ['us-offside', 'rival-fault', 'us-chance', 'us-goal'];
const defenseActions = ['rival-offside', 'us-fault', 'rival-chance', 'rival-goal'];
const zonesFieldComponent = ['offside', 'fault', 'chance', 'goal'];

const ActionInfo = ({ action, onUpdateAction }) => {
  if (!action) return null;

  const handleActionInfoSelected = info => {
    onUpdateAction({ ...action.actionInfo, ...info });
  }
  
  const getZoneType = (actionType, actionName) => {
    if (attackActions.includes(`${actionType}-${actionName}`)) return 'attack';
    if (defenseActions.includes(`${actionType}-${actionName}`)) return 'defense';
  }

  const renderInfoComponent = (actionType, actionName) => {
    if (zonesFieldComponent.includes(actionName)) {
      const type = getZoneType(actionType, actionName);
      return (
        <ZonesField
          actionName={actionName}
          type={type}
          onZoneSelected={zone => handleActionInfoSelected({ zone })}
        />    
      )
    };
  }

  return (
    <div className="action-info">
      {renderInfoComponent(action.type, action.action)}
    </div>
  );
}
 
export default ActionInfo;
