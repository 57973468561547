import React from 'react';

import './Card.scss';

const Card = ({ color, selected, onChange }) => {
  return (
    <div 
      role="button" 
      className={`card ${color}-card ${selected ? 'is-selected' : ''}`} 
      onClick={() => onChange({ target: { label: color, value: color } })} 
    />
  )
}
 
export default Card;