import React from 'react';

import Menu from '../../components/MenuNav/Menu';

import './Home.scss';

const Home = () => {
  return (
    <div className="home-page">
      <Menu isHome />
    </div>
  );
};

export default Home;
