import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useForm from '@bit/iamtechnologies.iamtech-js.use-form';

import { arrowsChangePos, arrowsSubs } from '../../Icon/iconSvgs';
import AddPlayerForm from '../../AddPlayer/AddPlayerForm';
import { SelectButtons, FormField, HorizontalField, Select } from '../../Form';
import { 
  SHOOT_OPTIONS,
  TARGET_OPTIONS,
  BOOLEAN_OPTIONS,
  STOPPED_BALL_TYPES,
  formations,
  getPlayersFromLineup,
  getPlayersFromBench,
  getPlayerNameAndNumber,
  getPlayerName
} from '../../../logic/football';
import getTranslation from '../../../logic/translations';
import Card from '../../Card';

const DEFAULT_VALUES = {
  goal: {
    shoot: 'foot',
    stoppedBallType: null,
    strategy: false,
    assistance: null
  },
  chance: {
    shoot: 'foot',
    onTarget: 'on-target'
  },
  card: {
    card: ''
  },
  fault: {
    card: ''
  },
  change_pos: {
    player: null
  },
  substitution_us: {
    player: null
  },
  substitution_rival: {
    name: '', 
    lastname: '',
    role: '',
    position: '',
    foot: '',
    number: ''
  },
  change_formation: {
    newFormation: ''
  }
}


const ActionControls = ({ action, onUpdateAction }) => {
  const lang = useSelector(state => state.lang);
  const match =  useSelector(state => state.match);
  const { action: actionName, type } = action;
  const actionTypeName = actionName === 'substitution' ? `${actionName}_${type}` : actionName;
  const defaultValues = DEFAULT_VALUES[actionTypeName];
  const matchLineup = type === 'us' ? match.lineup : match.rivalLineup;
  const teamPlayers = getPlayersFromLineup(matchLineup, action.player);
  const benchPlayers = getPlayersFromBench(match.bench);

  const formOptions = formations[match.mode].map(f => ({ label: f.split('').join('-'), value: f } ));

  const { values, useInput, errors, showErrors } = useForm(defaultValues);

  useEffect(() => {  
    const assistancePlayer = actionName === 'goal' && values 
      ? teamPlayers.filter(p => p.id === values.assistance)[0] 
      : null;

    const changePosPlayer = actionName === 'change_pos' && values 
      ? teamPlayers.filter(p => p.id === values.player)[0] 
      : null;

    const substitutionPlayer = actionName === 'substitution' && values 
      ? benchPlayers.filter(p => p.id === values.player)[0] 
      : null;
    if (actionTypeName === 'substitution_rival' && (!values.number)) return

    onUpdateAction({ 
      ...(actionTypeName === 'goal' && assistancePlayer ? { assistancePlayer } : {}), 
      ...(actionTypeName === 'change_pos' && changePosPlayer ? { changePosPlayer } : {}), 
      ...(actionTypeName === 'substitution_us' && substitutionPlayer ? { substitutionPlayer } : {}), 
      ...(actionTypeName === 'substitution_rival' 
        ? { substitutionPlayer: {
            ...values,
            id: `rival-${values.number}-${+new Date()}`
        } } 
        : {}
      ), 
      ...(actionTypeName !== 'substitution_rival' && values)
    });
  }, [values, action]);

  const ShootComponent = <FormField name="shoot" ><SelectButtons options={SHOOT_OPTIONS} {...useInput('shoot')}/></FormField>;
  const TargetComponent = <FormField name="target" ><SelectButtons options={TARGET_OPTIONS} {...useInput('onTarget')}/></FormField>;
  const AssistComponent = (
    <HorizontalField label={getTranslation('assist', lang)} name="assistance">
      <Select 
        options={teamPlayers} 
        {...useInput('assistance')} 
        getLabel={p => getPlayerNameAndNumber(p)} 
        getValue={p => p.id}
      />
    </HorizontalField>
  );

  const StoppedBallTypeComponent = (
    <HorizontalField label={getTranslation('type', lang)} name="stoppedBallType">
      <Select 
        options={STOPPED_BALL_TYPES} 
        {...useInput('stoppedBallType')} 
      />
    </HorizontalField>
  );

  const StrategyComponent = (
    <HorizontalField label={getTranslation('strategy', lang)} name="strategy">
      <Select 
        options={BOOLEAN_OPTIONS} 
        {...useInput('strategy')} 
      />
    </HorizontalField>
  );

  const FaultComponent = (
    <div className="cards-container-small">
      <Card color="yellow" selected={values.card === 'yellow'} {...useInput('card')} />
      <Card color="red" selected={values.card === 'red'} {...useInput('card')}/>
    </div>
  );

  const CardsComponent = (
    <div className="cards-container">
      <Card color="yellow" selected={values.card === 'yellow'} {...useInput('card')} />
      <Card color="red" selected={values.card === 'red'} {...useInput('card')}/>
    </div>
  )

  const rivalAddPlayer = (
    <AddPlayerForm 
      useInput={useInput}
      errors={errors}
      showErrors={showErrors}
      values={values}
      rival
    />
  )
  const usAddPlayer = (
    <>
      <Select 
        options={benchPlayers} 
        {...useInput('player')} 
        getLabel={p => getPlayerNameAndNumber(p)} 
        getValue={p => p.id}
      />
      <div className="player-direction">(in)</div>
    </>
  )

  const SubsComponent = (
    <div className="substitution-comp">
      <div className="player-out-name">{getPlayerName(action.player)}</div>
      <div className="player-direction">(out)</div>
      <div className="substitution-arrows">{arrowsSubs}</div>
      {type === 'us' ? usAddPlayer : rivalAddPlayer}
    </div>
  );

  const ChangePosComponent = (
    <div className="substitution-comp">
      <div className="player-out-name">{getPlayerName(action.player)}</div>
      <div className="substitution-arrows">{arrowsChangePos}</div>
      <div className="player-exchange">(changes position with)</div>
      <Select 
        options={teamPlayers} 
        {...useInput('player')} 
        getLabel={p => getPlayerNameAndNumber(p)} 
        getValue={p => p.id}
      />
    </div>
  );

  const formationComponent = (
    <FormField 
      label={`${getTranslation('formation', lang)}`} 
      name="formation" 
    >
      <SelectButtons 
        name="formation"
        options={formOptions}
        {...useInput('newFormation')}
        direction="column"
      />
    </FormField>
  )

  return (
    <div className="action-controls">
      {(actionName === 'goal' || actionName === 'chance') && ShootComponent}
      {actionName === 'goal' && values.shoot === 'stopped_ball' && StoppedBallTypeComponent}
      {actionName === 'goal' && StrategyComponent}
      {actionName === 'goal' && AssistComponent}
      {actionName === 'fault' && FaultComponent}
      {actionName === 'chance' && TargetComponent}
      {actionName === 'card' && CardsComponent}
      {actionName === 'substitution' && SubsComponent}
      {actionName === 'change_pos' && ChangePosComponent}
      {actionName === 'change_formation' && formationComponent}
    </div>
  );
}
 
export default ActionControls;
