import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getAllFiltered, deleteItem } from '../../services/database';
import { useFetch } from '../../customHooks';
import TagTrans from '../../components/TagTrans';
import { MATCH_STATUS } from '../../logic/football';
import { AppContainer, Separator } from '../../components/Layout';
import MatchListItem from '../../components/ListItem/MatchListItem';
import { FormField, Select } from '../../components/Form';
import getTranslation from '../../logic/translations';

const Archive = () => {
  const lang = useSelector(state => state.lang);
  const user = useSelector(state => state.user);
  const [team, setTeam] = useState(user.teams[0].id);
  const [isLoading, data, reFetch] = useFetch(async (setData) => {
    const matches = await getAllFiltered(
      'matches', 
      [
        { field: 'user', condition: '==', value: user.id },
        { field: 'status', condition: '==', value: MATCH_STATUS.FINISHED },
      ]
    )
    setData(matches);
  }, [user.id]);

  const handleRemoveMatch = async match => {
    await deleteItem('matches', match.id);
    reFetch();
  }

  if (isLoading) return <div>loading</div>;

  const matchesFiltered = data.filter(d => d.team === team);

  return (
    <div className="archive-page">
      <AppContainer padding mainPage>
        {user.teams.length > 0 && (
          <FormField
            label={`${getTranslation('team', lang)}`}
            name="team"
          >
            <Select
              options={user.teams}
              getLabel={t => t.name}
              getValue={t => t.id}
              value={team}
              onChange={({ target: { value } }) => setTeam(value)}
            />
          </FormField>
        )}
        <Separator />
        {matchesFiltered.length === 0
          ? <TagTrans tag="no-results" />
          : matchesFiltered.map(match => <MatchListItem type="archive" key={match.id} match={match} onDelete={handleRemoveMatch}/>)
        }
      </AppContainer>
    </div>
  );
};

export default Archive;
