import React, { useState, useRef } from 'react';
import useOnClickOutside from '@bit/iamtechnologies.iamtech-js.use-on-click-outside';

import PlayerRole from '../PlayerRole';
import { OptionsButton, ButtonGraySmall, ButtonGrayBig, ButtonGreenBig } from '../Button';
import Modal from '../../components/Modal';
import TagTrans from '../TagTrans';
import { SectionTitle, Text, ModalButtons, AppContainer } from '../Layout';

import ListItem from './ListItem';

const TeamListItem = ({ team, onEdit, onDelete, noOptions, onClick }) => {
  const optionsRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useOnClickOutside(optionsRef, () => setShowMenu(false));
  
  const handleShowConfirmDelete = () => {
    setShowConfirm(true);
  }
  
  const handleClickDelete = () => {
    setShowConfirm(false);
    onDelete(team);
  }

  return ( 
    <div className="list-item-container" ref={optionsRef}>
      <ListItem 
        onClick={onClick}
      >
        <div className="list-item-text">
          {team.name}
        </div>
        {!noOptions && <OptionsButton optionsOpened={showMenu} onClick={() => setShowMenu(!showMenu)} />}
      </ListItem>
      <div className={`list-item-options ${showMenu ? 'opened' : ''}`}>
        <ButtonGraySmall onClick={handleShowConfirmDelete}><TagTrans tag="delete" /></ButtonGraySmall>
        <ButtonGraySmall onClick={onEdit}><TagTrans tag="edit" /></ButtonGraySmall>
      </div>
      <Modal show={showConfirm} onCancel={() => setShowConfirm(false)}>
        <SectionTitle><TagTrans tag="deleteTeam" /></SectionTitle>
        <AppContainer padding>
          <Text><TagTrans tag="confirmDeleteTeam" /></Text>
        </AppContainer>
        <ModalButtons>
          <ButtonGrayBig onClick={() => setShowConfirm(false)}><TagTrans tag="no" /></ButtonGrayBig>
          <ButtonGreenBig onClick={handleClickDelete}><TagTrans tag="yes" /></ButtonGreenBig>
        </ModalButtons>
      </Modal>
    </div>
   );
}
 
export default TeamListItem;