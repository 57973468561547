import React from 'react';
import Button from './Button';

const ButtonBlueBig = ({ children, onClick, ...otherProps }) => (
  <Button
    size="small"
    color="blue"
    onClick={onClick}
    {...otherProps}
  >
    {children}
  </Button>
);

export default ButtonBlueBig;
