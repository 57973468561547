import React from 'react';

import './AppContainer.scss';

const AppContainer = ({ children, padding, paddingV, paddingH, isMainPage }) => {
  const paddingClasses = [
    padding ? 'with-padding' : '',
    paddingV ? 'with-padding-v' : '',
    paddingH ? 'with-padding-h' : '',
    isMainPage ? 'main-page' : ''
  ];

  return (
    <div className={`app-layout-container ${paddingClasses.join(' ')}`}>{children}</div>
  );
};

export default AppContainer;
