import React from 'react';

import './ListItem.scss';

const ListItem = ({ children, onClick, highlighted }) => {
  const classes = [
    'list-item',
    onClick ? 'isLink' : '',
    highlighted ? 'highlighted' : ''
  ];


  return (
    <div role="button" onClick={onClick} className={classes.join(' ')}>
      {children}
    </div>
  );
}
 
export default ListItem;