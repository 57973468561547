import moment from 'moment';

const DATE_STRING_FORMAT = 'YYYY-MM-DD'

export function dateToString(date) {
  return moment(date).format(DATE_STRING_FORMAT);
}

export function getToday() {
  return moment().format(DATE_STRING_FORMAT);
}
