import React from 'react';
import Button from './Button';

const ButtonDarkBig = ({ children, onClick, ...otherProps }) => (
  <Button
    size="big"
    color="gray-dark"
    onClick={onClick}
    {...otherProps}
  >
    {children}
  </Button>
);

export default ButtonDarkBig;
