import { useState, useEffect } from 'react'

const getHeight = () => window.innerHeight;

export default function useAppHeight() {
  const [height, setHeight] = useState(getHeight());

  function handleSizeChange() {
    return setHeight(getHeight());
  }

  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);

    return () => {
      window.removeEventListener("resize", handleSizeChange);
    };
  }, []);

  return height;
}
