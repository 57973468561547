import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useForm from '@bit/iamtechnologies.iamtech-js.use-form';

import { addItem, upsertItem } from '../../services/database';
import TagTrans from '../TagTrans';
import { FormField, Input } from '../Form';
import { ButtonGreenBig, ButtonGrayBig } from '../Button';
import { SectionTitle, ModalButtons, AppContainer, Box } from '../Layout';
import getTranslation from '../../logic/translations';


const AddTeam = ({ onFinish, team = {} }) => {
  const lang = useSelector(state => state.lang);
  const user = useSelector(state => state.user);
  
  const defaultValues = {
    name: team && team.name ? team.name : '',
  };
  
  const { values, useInput, isValid, errors, showErrors, setValues } = useForm(defaultValues);

  useEffect(() => { 
    setValues(defaultValues);
  }, [team]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValid) {
      if(team && team.id) {
        const updateTeam = { ...team, ...values };
        await upsertItem('teams', updateTeam, team.id)
      } else {
        const newTeam = { ...values, user: user.id };
        await addItem('teams', newTeam);
      }
      onFinish();
      setValues(defaultValues);
    }
  }

  return (
    <>
      <SectionTitle><TagTrans tag="add_team" /></SectionTitle>
      <AppContainer padding>
        <Box>
          <form onSubmit={handleSubmit}>
            <FormField 
              label={`${getTranslation('name', lang)}*`} 
              name="name" 
              errors={showErrors.name ? errors.name : []}
            >
              <Input name="name" {...useInput('name', 'isRequired')}/>
            </FormField>
            </form>
        </Box>
        <ModalButtons>
          <ButtonGrayBig onClick={() => onFinish()}><TagTrans tag="cancel" /></ButtonGrayBig>
          <ButtonGreenBig onClick={handleSubmit}>
            {team && team.id 
              ? <TagTrans tag="save_team" />
              : <>+ <TagTrans tag="add_team" /></>
            }</ButtonGreenBig>
        </ModalButtons>
      </AppContainer>
    </>
  );
}
 
export default AddTeam;