import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useForm from '@bit/iamtechnologies.iamtech-js.use-form';

import getTranslation from '../../logic/translations';
import { login } from '../../services/auth';
import { Box, FlexCenter, AppContainer, Separator } from '../../components/Layout';
import { FormField, Input } from '../../components/Form';
import { ButtonGreenBig } from '../../components/Button';
import TagTrans from '../../components/TagTrans';

import './Login.scss';
import loginBG from './loging-back.jpg';
import Logo from '../../components/Logo';

const Login = ({ history }) => {
  const lang = useSelector(state => state.lang);
  const defaultValues = {
    email: '',
    password: ''
  };

  const { values, useInput, isValid, errors } = useForm(defaultValues);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValid) {
      const { email, password } = values;
      const result = await login(email, password);
      if (result) {
        history.push('/home');
      }
    }
  };

  return (
    <div className="login-page" style={{backgroundImage:`url(${loginBG})`}}>
    <AppContainer  isMainPage>
      <Logo/>
        <Box classModifier="login-box">
          <form onSubmit={handleSubmit}>
            <FormField label={`${getTranslation('email', lang)}*`} name="email" errors={errors.email}>
              <Input name="email" type="email" {...useInput('email', 'isEmail,isRequired')}/>
            </FormField>
            <FormField label={`${getTranslation('password', lang)}*`} name="password" errors={errors.password}>
              <Input name="password" type="password" {...useInput('password', 'isRequired')}/>
            </FormField>
            <FlexCenter>
              <ButtonGreenBig 
                onClick={handleSubmit}
                disabled={!isValid}
              >
                <TagTrans tag="login" />
              </ButtonGreenBig>
            </FlexCenter>
          </form>
          <Separator />
          <p>
            <TagTrans tag="no_account_signup"/>&nbsp;
            <Link to="/signup">singup</Link>
          </p>
        </Box>
      </AppContainer>
    </div>
  );
};

export default Login;
