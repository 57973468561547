import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';


import { MATCH_STATUS } from '../../logic/football';
import { OptionsButton, ButtonGraySmall, ButtonGrayBig, ButtonGreenBig } from '../Button';
import Modal from '../../components/Modal';
import TagTrans from '../TagTrans';
import Icon from '../Icon';
import { SectionTitle, Text, ModalButtons, AppContainer } from '../Layout';

import ListItem from './ListItem';

const MatchListItem = ({ match, type, onEdit, onDelete }) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleShowConfirmDelete = () => {
    setShowConfirm(true);
  }
  
  const handleClickDelete = () => {
    setShowConfirm(false);
    onDelete(match);
  }

  const handleSend = () => {

  };

  const handleMatchClick = () => {
    const destination = match.status === MATCH_STATUS.FINISHED ? `/match-report/${match.id}` : `/match/${match.id}`;
    history.push(destination);
  }

  return ( 
    <div className="list-item-container">
      <ListItem 
        key={match.id} 
        onClick={handleMatchClick}
      >
        <div className="list-item-text">Vs. {match.rival}</div>
        <div className="list-item-icons">
          <Icon name={match.homeAway} />
        </div>
        <OptionsButton onClick={() => setShowMenu(!showMenu)} />
      </ListItem>
      <div className={`list-item-options ${showMenu ? 'opened' : ''}`}>
        <ButtonGraySmall onClick={handleShowConfirmDelete}><TagTrans tag="delete" /></ButtonGraySmall>
        {type === 'archive' && <ButtonGraySmall onClick={handleSend}><TagTrans tag="send" /></ButtonGraySmall>}
        {/* <ButtonGraySmall onClick={onEdit}><TagTrans tag="edit" /></ButtonGraySmall> */}
      </div>
      <Modal show={showConfirm} onCancel={() => setShowConfirm(false)}>
        <SectionTitle><TagTrans tag="deleteReport" /></SectionTitle>
        <AppContainer padding>
          <Text><TagTrans tag="confirmDeleteReport" /></Text>
        </AppContainer>
        <ModalButtons>
          <ButtonGrayBig onClick={() => setShowConfirm(false)}><TagTrans tag="no" /></ButtonGrayBig>
          <ButtonGreenBig onClick={handleClickDelete}><TagTrans tag="yes" /></ButtonGreenBig>
        </ModalButtons>
      </Modal>
    </div>
   );
}
 
export default MatchListItem;