import React from 'react';
import Button from './Button';

const ButtonGrayBig = ({ children, onClick, ...otherProps }) => (
  <Button
    size="big"
    color="gray"
    onClick={onClick}
    {...otherProps}
  >
    {children}
  </Button>
);

export default ButtonGrayBig;
