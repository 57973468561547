import React from 'react';
import { useSelector } from 'react-redux';

import { useAppWidth } from '../../customHooks';
import { ButtonPlayField } from '../../components/Button';
import { formatPlayerName, getPlayerCards, getMaxPlayersByLine } from '../../logic/football';

import './PlayField.scss';
import playFieldBg from './play-field.png';

const PlayField = ({ lineup, onPlayerClick, noBg = false }) => {
  const match =  useSelector(state => state.match);
  const appWidth = useAppWidth();
  const playFieldHeight = Math.ceil(appWidth * 1.1);

  return (
    <div className={`play-field ${noBg ? 'no-bg' : ''}`} style={{ height: `${playFieldHeight}px` }}>
      <div className="play-field-lines" style={{ backgroundImage: `url(${playFieldBg})` }}></div>
      <div className="play-field-players">
        {lineup.map((lineupLine, i) => (
          <div className="play-field-players-line" key={i}>
            {lineupLine.map((player, j) => {
              const playerCards = getPlayerCards(match.log, player ? player.id : null);
              const playerDisabled = playerCards.red > 0 || playerCards.yellow > 1;
              const playerClases = [
                'play-field-players-line-player',
                `players-in-line-${lineupLine.length}`,
                playerDisabled ? 'player-disabled' : ''
              ];
              const playerName = formatPlayerName(player);
              return (
                <div className={playerClases.join(' ')} key={`${i}-${j}`}>
                  {player 
                    ? <>
                        <ButtonPlayField cards={playerCards} onClick={() => onPlayerClick({ i, j })}>{player.number ? player.number : i+j+31}</ButtonPlayField>
                        {playerName && (
                          <div className="play-field-players-line-player-name">
                            {playerName}
                          </div>
                        )}
                      </>
                    : <ButtonPlayField onClick={() => onPlayerClick({ i, j })}>+</ButtonPlayField>}
                </div>
              )
            })}
          </div> 
        ))}
      </div>
    </div>
  );
}
 
export default PlayField;