import React from 'react';
import { useSelector } from 'react-redux';

import { AppContainer } from '../../Layout';
import TagTrans from '../../TagTrans';
import { substitution } from '../../Icon/iconSvgs'
import {
  getMatchSubstitutions,
  getMathFormationChanges,
  getMatchPlayersStats,
  getPlayerNameAndNumber,
  getPlayerClassColor
} from '../../../logic/football';

import './TeamReport.scss';

const TeamReport = ({ match }) => {
  const matchRedux = useSelector(state => state.match);
  const reportMatch = matchRedux || match;

  if (!reportMatch || !reportMatch.id) return null;
  const substitutions = getMatchSubstitutions(reportMatch);
  const formations = getMathFormationChanges(reportMatch);
  const playerStats = getMatchPlayersStats(reportMatch);

  return (
    <>
      <div className="team-report-title"><TagTrans tag="bench" /></div>
      <AppContainer padding>
        {reportMatch.bench.map(benchPlayer => (
          <div className="team-report-bench-player" key={benchPlayer.id}>
            {benchPlayer.number && <span>{benchPlayer.number}.&nbsp;</span>}
            <span>{benchPlayer.name}</span>
            <span>&nbsp;{benchPlayer.lastname}</span>
          </div>
        ))}
      </AppContainer>
      <div className="team-report-title"><TagTrans tag="substitutions" /></div>
      <AppContainer padding>
        {substitutions.map(({ playerIn, playerOut, part, minute }) => (
          <div className="team-report-info-item" key={`${playerOut.id}-${playerIn.id}`}>
            <span>{part}T</span>
            <span>{minute}'</span>
            <span className="team-report-info-item-player">{getPlayerNameAndNumber(playerIn)}</span>
            <span className="team-report-info-item-icon">{substitution}</span>
            <span className="team-report-info-item-player">{getPlayerNameAndNumber(playerOut)}</span>
          </div>
        ))}
      </AppContainer>
      <div className="team-report-title"><TagTrans tag="formations_changes" /></div>
      <AppContainer padding>
        {formations.map(({ newFormation, oldFormation, part, minute }) => (
          <div className="team-report-info-item" key={`${newFormation}-${oldFormation}`}>
            <span>{part}T</span>
            <span>{minute}'</span>
            <span className="team-report-info-item-player">{oldFormation.split('').join('-')}</span>
            <span className="team-report-info-item-icon">{substitution}</span>
            <span className="team-report-info-item-player">{newFormation.split('').join('-')}</span>
          </div>
        ))}
      </AppContainer>
      <div className="team-report-title"><TagTrans tag="players_stats" /></div>
      <AppContainer padding>
        <table className="team-report-info-table">
          <thead>
            <tr>
              <th><TagTrans tag="player" /></th>
              <th><TagTrans tag="minutes" /></th>
              <th><TagTrans tag="goals" /></th>
              <th><TagTrans tag="chances_abrv" /></th>
              <th><TagTrans tag="faults" /></th>
            </tr>
          </thead>
          <tbody>
            {playerStats.map(player => (
              <tr key={player.id}>
                <td className={getPlayerClassColor(player)}>{getPlayerNameAndNumber(player)}</td>
                <td className={player.stats.minutesPlayed === 0 ? 'zero-value' : ''}>{player.stats.minutesPlayed}</td>
                <td className={player.stats.goal === 0 ? 'zero-value' : ''}>{player.stats.goal}</td>
                <td className={player.stats.chance === 0 ? 'zero-value' : ''}>{player.stats.chance}</td>
                <td className={player.stats.fault === 0 ? 'zero-value' : ''}>{player.stats.fault}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </AppContainer>
    </>
  )
}

export default TeamReport;
