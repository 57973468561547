import React, { useState, useEffect, useRef } from 'react';

import './Button.scss';

const Button = ({ children, onClick, color = 'gray', size = 'big', disabled = false }) => {
  const [animating, setAnimating] = useState(false);
  let timeoutHandler = useRef(false);
  
  const handleClick = (event) => {
    if (disabled) return;
    
    onClick && onClick(event);
    setAnimating(true);
    timeoutHandler.current = setTimeout(() => setAnimating(false), 150);
  };
  
  useEffect(() => {
    return () => clearTimeout(timeoutHandler.current);
  }, [timeoutHandler]);
  
  const animatinClassName = animating ? 'fade' : '';
  const disabledClassName = disabled ? 'disabled' : '';

  return (
    <button
      className={`footie-button ${size} ${color} ${animatinClassName} ${disabledClassName}`}
      type="button"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
