import React from 'react';
import { useSelector } from 'react-redux';

import { useTeam } from '../../../customHooks';
import { defaultSummary } from '../../../logic/football';

import SummaryRow from './SummaryRow';
import './Summary.scss';

const Summary = () => {
  const match = useSelector(state => state.match);
  const team = useTeam(match.team, true);

  if (!team) return null;

  const summary = {
    ...defaultSummary,
    ...match.summary
  };

  return (
    <div className="match-summary">
      <div className="match-summary-content">
        <div className="match-summary-teams">
          <div className={`match-summary-teams-item ${match.homeAway === 'home' ? 'my-team-text' : ''}`}>
            {match.homeAway === 'home' ? team.name : match.rival}
          </div>
          <div className={`match-summary-teams-item ${match.homeAway === 'away' ? 'my-team-text' : ''}`}>
            {match.homeAway === 'away' ? team.name : match.rival}
          </div>
        </div>
        <div className="match-summary-stats">
          <SummaryRow stat={summary.goals} statName="goals" mode={match.homeAway} />
          <SummaryRow stat={summary.onTarget} statName="onTarget" mode={match.homeAway} />
          <SummaryRow stat={summary.chances} statName="chances" mode={match.homeAway} />
          <SummaryRow stat={summary.faults} statName="faultsCommitted" mode={match.homeAway} />
          <SummaryRow stat={summary.corners} statName="corners" mode={match.homeAway} />
          <SummaryRow stat={summary.offsides} statName="offsides" mode={match.homeAway} />
          <SummaryRow stat={summary.penalties} statName="penalties" mode={match.homeAway} />
          <SummaryRow stat={summary.yellowCards} statName="yellowCards" mode={match.homeAway} />
          <SummaryRow stat={summary.redCards} statName="redCards" mode={match.homeAway} />
        </div>
      </div>
    </div>
  );
}

export default Summary;
