import firebase from 'firebase/app';
import 'firebase/firestore';

function parseDoc(doc) {
  return {
    id: doc.id,
    ...doc.data()
  };
}

let dbConnection;
function getDbInstance() {
  if (!dbConnection || dbConnection._isTerminated) {
    dbConnection = firebase.firestore();
  }
  return dbConnection;
}

async function getAll(collection) {
  const db = getDbInstance();
  const collectionData = await db.collection(collection).get();

  const results = [];
  collectionData.forEach((document) => {
    results.push(parseDoc(document));
  });

  return results;
}

async function getAllFiltered(collection, filters, order) {
  const db = getDbInstance();
  let collectionRef = db.collection(collection);

  if (filters) {
    filters.forEach(f => collectionRef = collectionRef.where(f.field, f.condition, f.value));
  }
  if (order) collectionRef = collectionRef.orderBy(order);

  const collectionData = await collectionRef.get();

  const results = [];
  collectionData.forEach((document) => {
    results.push(parseDoc(document));
  });

  return results;
}

function getAllRealTime({ collection, filters, order, callback }) {
  const db = getDbInstance();
  const dbCollection = db.collection(collection);
  const collectionFiltered = dbCollection.where(filters.field, filters.condition, filters.value);
  const collectionOrdered = order ? collectionFiltered.orderBy(order) : collectionFiltered;
  return collectionOrdered.onSnapshot((collectionData) => {
    const results = [];
    collectionData.forEach((document) => {
      results.push(parseDoc(document));
    });
    callback(results)
  })
}

async function addItem(collection, item) {
  const db = getDbInstance();
  const result = await db.collection(collection).add(item)
  return result.id;
}

async function upsertItem(collection, item, id) {
  const db = getDbInstance();
  const result = await db.collection(collection).doc(id).set(item);
  return !result;
}

async function insertItem(collection, item) {
  if (item.id) {
    return await upsertItem(collection, item, item.id);
  } else {
    return await addItem(collection, item);
  }
}

async function getItem(collection, itemId) {
  const db = getDbInstance();
  const document = await db.collection(collection).doc(itemId).get();

  if (document.exists) {
    return parseDoc(document);
  }
  return null;
}

async function deleteItem(collection, itemId) {
  const db = getDbInstance();
  const result = await db.collection(collection).doc(itemId).delete();
  return !result;
}

export {
  getAll,
  getAllFiltered,
  addItem,
  getItem,
  insertItem,
  getAllRealTime,
  deleteItem,
  upsertItem
}