import React from 'react';

import TagTrans from '../../TagTrans';
import { AppContainer } from '../../Layout';

import './TabsMenu.scss';

const TabsMenu = ({ onTabSelect, currentTab, archive }) => {

  return (
    <div className={`match-tabs ${archive ? 'archive-tabs' : ''}`}>
      <div className="match-tabs-menu">
        <div
          role="button"
          onClick={() => onTabSelect('my-team')}
          className={`match-tabs-menu-item ${currentTab === 'my-team' ? 'active' : ''}`}
        >
          <TagTrans tag="my_team" />
        </div>
        <div
          role="button"
          onClick={() => onTabSelect('summary')}
          className={`match-tabs-menu-item ${currentTab === 'summary' ? 'active' : ''}`}
        >
          <TagTrans tag="summary" />
        </div>
        {archive
          ? <div
            role="button"
            onClick={() => onTabSelect('timeline')}
            className={`match-tabs-menu-item ${currentTab === 'timeline' ? 'active' : ''}`}
          >
            <TagTrans tag="timeline" />
          </div>
          : <div
            role="button"
            onClick={() => onTabSelect('rival')}
            className={`match-tabs-menu-item ${currentTab === 'rival' ? 'active' : ''}`}
          >
            <TagTrans tag="rival" />
          </div>}
      </div>
    </div>
  );
}

export default TabsMenu;
