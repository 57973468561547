import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import NewReport from './pages/NewReport';
import MyTeams from './pages/MyTeams';
import Team from './pages/Team';
import Match from './pages/Match';
import MatchReport from './pages/MatchReport';
import Pending from './pages/Pending';
import Archive from './pages/Archive';
import Stats from './pages/Stats';

const Routes = () => {
  const user = useSelector(state => state.user);

  return (
    <Switch>
      <PrivateRoute path="/match/:id"><Match /></PrivateRoute>
      <PrivateRoute path="/team/:id"><Team /></PrivateRoute>
      <PrivateRoute path="/my-teams"><MyTeams /></PrivateRoute>
      <PrivateRoute path="/new-report"><NewReport /></PrivateRoute>
      <PrivateRoute path="/pending-reports"><Pending /></PrivateRoute>
      <PrivateRoute path="/archive"><Archive /></PrivateRoute>
      <PrivateRoute path="/stats"><Stats /></PrivateRoute>
      <Route path="/match-report/:id"><MatchReport /></Route>
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/home" component={Home} />
      <Redirect to={{ pathname: user ? 'home' : 'login' }} />
    </Switch>
  );
}
const PrivateRoute = ({ children, ...rest }) => {
  const user = useSelector(state => state.user);

  return (
    <Route {...rest} render={() => user ? children : <Redirect to={{ pathname: "/login" }} />} />
  );
}

export default Routes;
