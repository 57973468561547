import React from 'react';

import { home, away } from './iconSvgs.js'

const ICONS = {
  home, away, 
}

const Icon = ({ name }) => {
  return (
    <div className="app-icon">{ICONS[name]}</div>
  );
}
 
export default Icon;