import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getItem } from '../../../services/database';
import { AppContainer } from '../../Layout';
import ResponsiveImage from '../../ResponsiveImg';
import { useTeam } from '../../../customHooks';
import TagTrans from '../../TagTrans';

import './ScoreboardArchive.scss';

const ScoreboardArchive = () => {
  const match = useSelector(state => state.match);
  const team = useTeam(match.team, true);

  if (!team) return null;

  return (
    <div className="match-scoreboard-archive">
      <AppContainer>
        <div className="match-scoreboard-content">
          <div className={`match-scoreboard-team home ${match.homeAway === 'home' ? 'my-team' : ''}`}>
            <ResponsiveImage imageName="home" />
            <span>{match.homeAway === 'home' ? team.name : match.rival}</span>
          </div>
          <div className="match-scoreboard-center">
            <div className="match-scoreboard-status">
              <TagTrans tag={`status_${match.status}`} />
            </div>
            <div className="match-scoreboard-goals">
              {match.homeAway === 'home'
                ? <><span className="my-team">{match.score}</span>&nbsp;-&nbsp;<span>{match.scoreRival}</span></>
                : <><span>{match.scoreRival}</span>&nbsp;-&nbsp;<span className="my-team">{match.score}</span></>
              }
            </div>
          </div>
          <div className={`match-scoreboard-team away ${match.homeAway === 'away' ? 'my-team' : ''}`}>
            <ResponsiveImage imageName="away" />
            <span>{match.homeAway === 'home' ? match.rival : team.name}</span>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default ScoreboardArchive;
