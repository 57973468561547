export default {
  yes: 'Sí',
  no: 'No',
  deletePlayer: 'Eliminar jugador',
  confirmDeletePlayer: '¿Realmente quieres eliminar este jugador?',
  deleteTeam: 'Eliminar equipo',
  confirmDeleteTeam: '¿Realmente quieres eliminar este equipo? Perderás todos sus datos y reportes',
  deleteReport: 'Eliminar informe',
  confirmDeleteReport: '¿Realmente quieres eliminar este informe?',
  all: 'Todos',
  // MENU
  my_teams: 'Mis equipos',
  reports_history: 'Historial de informes',
  new_report: 'Nuevo informe',
  pending_report: 'Informe suspendido',
  logout: 'Cerrar sesión',
  delete: 'Eliminar',
  edit: 'Editar',
  send: 'Enviar',
  exit: 'Salir',
  // GENERAL
  stats: 'Estadísticas',
  season: 'Temporada',
  team: 'Equipo',
  cancel: 'Cancelar',
  login: 'Entrar',
  create_account: 'Crear cuenta',
  no_account_signup: '¿No tienes cuenta? Ir al',
  yes_account_login: 'Ya tienes cuenta? Ir al',
  signup: 'Registrarse',
  name: 'Nombre',
  lastname: 'Apellidos',
  club: 'Club',
  email: 'Email',
  password: 'Contraseña',
  date: 'Fecha',
  home: 'En casa',
  away: 'Fuera',
  no_results: 'No hay resultados',
  my_team: 'Mi equipo',
  player: 'Jugador',
  players: 'Jugadores',
  add_player: 'Añadir jugador',
  add_players: 'Añadir jugadores',
  save_player: 'Guardar jugador',
  add_team: 'Añadir equipo',
  save_team: 'Guardar equipo',
  role: 'Rol',
  position: 'Posición',
  options: 'Opciones',
  select_option: "Selecciona una opción",
  save: 'Guardar',
  foot: 'Pie',
  head: 'Cabeza',
  stopped_ball: 'Balón parado',
  strategy: 'Estrategia',
  direct_free_kick: 'Tiro libre directo',
  indirect_free_kick: 'Tiro libre indirecto',
  penalty: 'Penalti',
  penalties: 'Penaltis',
  left: 'Izq.',
  right: 'Dcho.',
  number: 'Número',
  other: 'Otro',
  type: 'Tipo',
  export_xls: 'Exportar XLS',
  from: 'De',
  to: 'A',
  // MATCH
  match_report: 'Informe de partido',
  match_data: 'Datos del partido',
  tournament: 'Torneo',
  stadium: 'Estadio',
  rival: 'Rival',
  home_away: 'En casa/Fuera',
  mode: 'Modalidad',
  volatile_changes: 'Cambios volátiles',
  half_time: 'Minutos por parte',
  go_lineup: 'Ir a la alineación',
  formation: 'Formación',
  selected_players: 'Seleccionados',
  bench: 'Banquillo',
  go_to_match: 'Ir al partido',
  half_1: '1ra Parte',
  half_2: '2da Parte',
  half_3: '3ra Parte',
  half_4: '4ta Parte',
  half_5: '5ta Parte',
  half_6: '6ta Parte',
  status_about_start: '(comenzando)',
  status_playing: '(jugando)',
  status_paused: '(pausa)',
  status_between_part: '(descanso)',
  status_finished: '(finalizado)',
  status_suspended: '(suspendido)',
  summary: 'Resumen',
  timeline: 'Registro',
  add_rival_stats: 'Añadir acción rival',
  finish_part: 'Finalizar parte',
  finish_match: 'Finalizar partido',
  suspend_match: 'Suspender partido',
  undo_last_action: 'Deshacer última acción',
  // LINEUP
  lineup_selection: 'Selección de alineación',
  // STATS
  goals: 'Goles',
  goalsAgainst: 'Goles en contra',
  chances: 'Ocasiones totales',
  chances_abrv: 'Ocasiones',
  onTarget: 'Tiros a puerta',
  faults: 'Faltas',
  faultsCommitted: 'Faltas cometidas',
  corner: 'Corner',
  corners: 'Corners',
  offsides: 'Fueras de juego',
  yellowCards: 'Tarjetas amarillas',
  yellowCards_abrv: 'T. amarillas',
  redCards: 'Tarjetas rojas',
  redCards_abrv: 'T. rojas',
  out: 'fuera',
  assist: 'Asist',
  // POSITIONS
  position_gk: 'PT',
  position_df: 'DF',
  position_mf: 'MD',
  position_fw: 'DL',
  // ROLE POSITIONS
  role_gk: 'Portero',
  role_lb: 'Lateral izquierdo',
  role_cb: 'Defensa central',
  role_rb: 'lateral derecho',
  role_dm: 'Pivote',
  role_lm: 'Centrocampista izquierdo',
  role_cm: 'Mediocentro',
  role_rm: 'Centrocampista derecho',
  role_lw: 'Extremo izquierdo',
  role_rw: 'Extremo derecho',
  role_am: 'Mediapunta',
  role_lf: 'Delantero izquierdo',
  role_cf: 'Delantero centro',
  role_rf: 'Delantero derecho',
  role_gk_abrv: 'PT',
  role_lb_abrv: 'LI',
  role_cb_abrv: 'CT',
  role_rb_abrv: 'LD',
  role_dm_abrv: 'PIV',
  role_lm_abrv: 'BI',
  role_cm_abrv: 'MC',
  role_rm_abrv: 'BD',
  role_lw_abrv: 'Ext. Iz.',
  role_rw_abrv: 'Ext. D.',
  role_am_abrv: 'MP',
  role_lf_abrv: 'DL ',
  role_cf_abrv: 'DL',
  role_rf_abrv: 'DL',
  // ACTIONS
  action_match_start: 'Inicio partido',
  action_match_paused: 'Partido pausado',
  action_match_suspended: 'Partido suspendido',
  action_match_resumed: 'Reinicio partido',
  action_match_finished: 'Fin de partido',
  action_change_formation: 'Cambio de formacion',
  action_part_finished: 'Parte finalizada',
  action_own_goal: 'Autogol',
  action_goal: 'Gol',
  action_fault: 'Falta',
  action_card: 'Tarjeta',
  action_corner: 'Corner',
  action_offside: 'Offside',
  action_substitution: 'Sustitución',
  action_change_pos: 'Cambio Pos.',
  action_chance: 'Ocasión',
  action_title_goal: 'marca!',
  action_title_fault: 'comete falta',
  action_title_card: 'obtiene tarjeta',
  action_title_offside: 'fuera de juego',
  action_title_substitution: 'sustuido',
  action_title_change_pos: 'cambio de posición',
  action_title_chance: 'ocasión de gol',
  action_title_change_formation: 'Cambio de formación',
  action_subtitle_goal: 'Selecciona la zona del gol',
  action_subtitle_fault: 'Selecciona tarjeta y la zona de la falta',
  action_subtitle_card: 'Selecciona el color de la tarjeta',
  action_subtitle_offside: 'Selecciona la zona del fuera de juego',
  action_subtitle_substitution: 'Selecciona al jugador que entra',
  action_subtitle_change_pos: 'Selecciona el jugador que intercambia posición',
  action_subtitle_chance: 'Selecciona la zona de la ocasión',
  action_subtitle_change_formation: 'Selecciona la nueva formación',
  // LOG
  part_name: 'P',
  subs_in: 'Entra',
  subs_out: 'Sale',
  change_formation_new: 'Nueva formación',
  // ERRORS
  isRequired: 'Campo obligatorio',
  isEmail: 'Email con formato inválido',
  // TOURNAMENTS
  league: 'Liga',
  friendly: 'Amistoso',
  other: 'Otros',
  // REPORT
  initialFormation: 'Formación inicial',
  substitutions: 'Sustituciones',
  formations_changes: 'Cambios de formación',
  players_stats: 'Estadísticas de jugadores',
  minutes: 'Minutos',
  part: 'Parte',
  home: 'Local',
  away: 'Visitante',
  initialLineup: 'Alineación inicial',
  number_abrv: 'Nº',
  minutes_abrv: 'Mins',
  offsided_abrv: 'F.J.',
  observations: 'Observaciones',
  gameSystem: 'Sistema juego',
  goals_abrv: 'G.',
  goals_rival_abrv: 'G.C.',
  faults_abrv: 'F',
  // ONE LETTER
  goals_initials: 'G.',
  goals_rival_initials: 'G.C.',
  chances_initials: 'Oc.',
  faults_initials: 'F.',
  yellowCards_initials: 'Y.C.',
  redCards_initials: 'R.C.',
};
