import getTranslation from '../translations';

const rolesHash = {
  gk: 1,
  df: 2,
  mf: 3,
  fw: 4
}

export const DEFAULT_BASE_NUMBER = 30;

export const ROLE_OPTIONS = [
  { value: 'gk', labelTag: 'position_gk', order: 0 },
  { value: 'df', labelTag: 'position_df', order: 1 },
  { value: 'mf', labelTag: 'position_mf', order: 2 },
  { value: 'fw', labelTag: 'position_fw', order: 3 }
];

export const ROLE_POSITIONS = {
  gk: [],
  df: [
    { value: 'lb', labelTag: 'role_lb' },
    { value: 'cb', labelTag: 'role_cb' },
    { value: 'rb', labelTag: 'role_rb' }
  ],
  mf: [
    { value: 'dm', labelTag: 'role_dm' },
    { value: 'lm', labelTag: 'role_lm' },
    { value: 'cm', labelTag: 'role_cm' },
    { value: 'rm', labelTag: 'role_rm' },
    { value: 'lw', labelTag: 'role_lw' },
    { value: 'rw', labelTag: 'role_rw' },
    { value: 'am', labelTag: 'role_am' }
  ],
  fw: [
    { value: 'lf', labelTag: 'role_lf' },
    { value: 'cf', labelTag: 'role_cf' },
    { value: 'rf', labelTag: 'role_rf' }
  ]
};

export const FOOT_OPTIONS = [
  { value: 'left', labelTag: 'left' },
  { value: 'right', labelTag: 'right' },
];

export const SHOOT_OPTIONS = [
  { value: 'foot', labelTag: 'foot' },
  { value: 'head', labelTag: 'head' },
  { value: 'stopped_ball', labelTag: 'stopped_ball' },
];

export const STOPPED_BALL_TYPES = [
  { value: 'corner', labelTag: 'corner' },
  { value: 'direct_free_kick', labelTag: 'direct_free_kick' },
  { value: 'indirect_free_kick', labelTag: 'indirect_free_kick' },
  { value: 'penalty', labelTag: 'penalty' },
  { value: 'other', labelTag: 'other' },
]

export const BOOLEAN_OPTIONS = [
  { value: 'true', labelTag: 'yes' },
  { value: 'false', labelTag: 'no' }
]

export const TARGET_OPTIONS = [
  { value: 'on-target', labelTag: 'onTarget' },
  { value: 'out', labelTag: 'out' },
];

export const formations = {
  f11: ['1541', '1532', '1523', '1451', '14141', '14231', '14321', '1442', '14411', '14132', '14222', '1433', '1343', '13331'],
  f7: ['133', '1312', '1321', '142', '1222', '1231', '151']
}

export const MATCH_STATUS = {
  ABOUT_START: 'about_start',
  PLAYING: 'playing',
  PAUSED: 'paused',
  SUSPENDED: 'suspended',
  BETWEEN_PART: 'between_part',
  FINISHED: 'finished'
}

export const MATCH_ACTIONS = {
  STARTED: 'match_start',
  PAUSED: 'match_paused',
  RESUMED: 'match_resumed',
  SUSPEND: 'match_suspended',
  PART_FINISHED: 'part_finished',
  MATCH_FINISHED: 'match_finished',
  OWN_GOAL: 'own_goal',
  CORNER: 'corner',
  OFFSIDE: 'offside',
  FAULT: 'fault',
  CHANCE: 'chance',
  GOAL: 'goal',
  CARD: 'card',
  CHANGE: 'change',
  CHANGE_POS: 'change_pos',
  SUBSTITUTION: 'substitution',
  CHANGE_FORMATION: 'change_formation',
  UNDO_LAST_ACTION: 'undo_last_action'
}

export const MODE_OPTIONS = [
  { value: 'f7', label: 'F7' },
  { value: 'f11', label: 'F11' }
];

export const getHomeAwayOptions = (lang) => [
  { value: 'home', label: getTranslation('home', lang) },
  { value: 'away', label: getTranslation('away', lang) }
];

export const getTournamentOptions = (lang) => [
  { value: 'league', label: getTranslation('league', lang) },
  { value: 'friendly', label: getTranslation('friendly', lang) },
  { value: 'other', label: getTranslation('other', lang) },
]

export const defaultSummary = {
  goals: { total: 0, us: 0, rival: 0 },
  penalties: { total: 0, us: 0, rival: 0 },
  chances: { total: 0, us: 0, rival: 0 },
  onTarget: { total: 0, us: 0, rival: 0 },
  faults: { total: 0, us: 0, rival: 0 },
  corners: { total: 0, us: 0, rival: 0 },
  offsides: { total: 0, us: 0, rival: 0 },
  yellowCards: { total: 0, us: 0, rival: 0 },
  redCards: { total: 0, us: 0, rival: 0 }
}

export const rivalPlayerStatsTypes = ['goal'];
export const playerStatsTypes = ['goal', 'chance', 'fault', 'card', 'corner', 'offside'];
export const playerDefaultStats = {
  goal: 0,
  goalRival: 0,
  chance: 0,
  fault: 0,
  yellowCards: 0,
  redCards: 0,
  corner: 0,
  offside: 0,
}
export const rivalPlayerDefaultStats = {
  goalRival: 0,
  chanceRival: 0,
  faultRival: 0,
  yellowCardsRival: 0,
  redCardsRival: 0,
  corner: 0,
  offside: 0,
}

export function getDefaultFormation(mode) {
  return mode === 'f11' ? '1442' : '133';
}

export function sortPlayersByRole(players) {
  players.sort((a, b) => {
    return rolesHash[a.role] - rolesHash[b.role];
  })

  return players;
}

export function addCardToPlayerOnMatchLineup(matchLineup, playerId, card) {
  return matchLineup.map(lineupPlayer => {
    if (lineupPlayer.id === playerId) {
      if (!lineupPlayer.cards) lineupPlayer.cards = {};
      if (!lineupPlayer.cards[card]) lineupPlayer.cards[card] = 0;
      lineupPlayer.cards[card] += 1;
    }
    return lineupPlayer;
  })
}

export function removeCardToPlayerOnMatchLineup(matchLineup, playerId, card) {
  return matchLineup.map(lineupPlayer => {
    if (lineupPlayer.id === playerId) {
      lineupPlayer.cards[card] -= 1;
    }
    return lineupPlayer;
  })
}

export function getPlayersFromLineup(lineup, exclude) {
  const allPlayers = lineup && lineup.length ? lineup.flat().filter(p => !!p) : [];
  return allPlayers.filter(player => !exclude || exclude.id !== player.id);
}

export function getPlayersFromBench(bench, exclude) {
  const allPlayers = bench && bench.length ? bench : [];
  return allPlayers.filter(player => !exclude || exclude.id !== player.id);
}

export function getEmptyLineup(mode, formation) {
  const formationLines = formation.split('');
  const allPlayers = Array(mode === 'f7' ? 7 : 11).fill(null, 0);
  return formationLines.map((line, i) => {
    return allPlayers.splice(0, line);
  });
}

export function getLineupLayout({ mode, formation, lineup = [] }) {
  const emptyLineup = getEmptyLineup(mode, formation);

  if (lineup && lineup.length) {
    const allPlayers = lineup.flat();
    const lineupFilled = emptyLineup.map((lineupLine, i) => (
      lineupLine.map((lineupColumn, j) => {
        let positionPlayerIndex = -1;
        allPlayers.forEach((p, index) => {
          if (p && p.positionRow === i && p.positionColumn === j) positionPlayerIndex = index;
        });
        if (positionPlayerIndex >= 0) {
          return allPlayers.splice(positionPlayerIndex, 1)[0];
        }
        return lineupColumn;
      })
    ));

    if (allPlayers.length > 0) {
      return lineupFilled.map((lineupLine, i) => (
        lineupLine.map((lineupColumn, j) => (
          allPlayers[0] && !lineupColumn ? allPlayers.shift() : lineupColumn
        ))
      ))
    }
    return lineupFilled;
  } else {
    return emptyLineup;
  }
}

export function getPlayerNameAndNumber(player) {
  return `${player.number || ''}${player.number && (player.name || player.lastname) ? '. ' : ''}${player.name} ${player.lastname}`
}

export function getPlayerName(player) {
  return player ? `${player.name} ${player.lastname}` : '';
}

export function formatPlayerName(player) {
  if (!player) return '';
  if (!player.name && !player.lastname) return '';
  if (player.name && !player.lastname) return player.name;

  const nameInitial = player.name.slice(0, 1).toUpperCase();

  return `${nameInitial}. ${player.lastname}`;
}

export function formatPlayerNameAndNumber(player) {
  if (!player) return '';
  if (!player.name && !player.lastname && !player.number) return '';
  if (player.name && !player.lastname) return `${player.number ? `${player.number} ` : ''}${player.name}`;

  const nameInitial = player.name.slice(0, 1).toUpperCase();

  return `${player.number ? `${player.number} ` : ''}${nameInitial}. ${player.lastname}`;
}

export function getLineupPositionName(lineup, i, j) {
  if (i === 0) {
    return ''
  } else {
    if (i === 1) {
      if (j === 0) return 'lb';
      else if (j === lineup[i].length - 1) return 'rb';
      else return 'cb';

    } else if (i === 2) {
      if (lineup[i] < 3) return 'dm';
      else if (j === 0) return 'lm';
      else if (j === lineup[i].length - 1) return 'rm';
      else return 'cm';

    } else if (i === 3 && lineup.length > 4) {
      if (lineup[i] < 3) return 'am';
      else if (j === 0) return 'lw';
      else if (j === lineup[i].length - 1) return 'rw';
    } else if (i === 3) {
      if (lineup[i] < 3) return 'cf';
      else if (j === 0) return 'lf';
      else if (j === lineup[i].length - 1) return 'rf';
    }
  }
  console.log('UNKNOW POSITION: ', lineup, i, j);
  return 'up';
}

export function getUnnumberedPlayerNumber(lineup, baseNumber) {
  let numberExists = false;
  lineup.forEach(line => (
    line.forEach(position => {
      if (position && position.number === baseNumber) {
        numberExists = true;
      }
    })
  ));

  if (!numberExists) return baseNumber;
  return getUnnumberedPlayerNumber(lineup, baseNumber + 1);
}

export function getPartTimeFromSeconds(partSeconds) {
  const minutes = Math.floor(partSeconds / 60);
  const seconds = partSeconds % 60;

  return {
    minutes: minutes < 10 ? `0${minutes}` : minutes,
    seconds: seconds < 10 ? `0${seconds}` : seconds,
  };
}

export function getTimePlayed(currentMatch) {
  if (currentMatch.status === MATCH_STATUS.BETWEEN_PART || currentMatch.status === MATCH_STATUS.FINISHED) return 0;
  if (!currentMatch.log || !currentMatch.log.length) return 0;

  const partLog = currentMatch.log.filter(l => l.part === currentMatch.currentPart);
  if (!partLog.length) return 0;

  let previousTimestamp;
  let secondsPlayed = 0;
  let closedCalculation = false;

  for (let i = partLog.length - 1; i >= 0; i--) {
    const log = partLog[i];
    if (log.action === MATCH_ACTIONS.STARTED || log.action === MATCH_ACTIONS.RESUMED) {
      previousTimestamp = log.timestamp;
      closedCalculation = false;

    } else if (log.action === MATCH_ACTIONS.PAUSED) {
      secondsPlayed += (log.timestamp - previousTimestamp) / 1000;
      closedCalculation = true;
    }
  }

  if (!closedCalculation && previousTimestamp) {
    const now = +(new Date());
    secondsPlayed += (now - previousTimestamp) / 1000;
  }
  return Math.round(secondsPlayed);
}

export function addMatchStart(currentMatch) {
  const { log } = currentMatch;

  return {
    ...currentMatch,
    status: MATCH_STATUS.PLAYING,
    log: [
      {
        action: MATCH_ACTIONS.STARTED,
        minute: currentMatch.currentPartTime,
        part: currentMatch.currentPart,
        timestamp: +(new Date())
      },
      ...log,
    ]
  };
}

export function addMatchPause(currentMatch) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    status: MATCH_STATUS.PAUSED,
    log: [
      {
        action: MATCH_ACTIONS.PAUSED,
        minute: minutes,
        part: currentMatch.currentPart,
        timestamp: +(new Date())
      },
      ...log,
    ]
  };
}

export function addMatchResume(currentMatch) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    status: MATCH_STATUS.PLAYING,
    log: [
      {
        action: MATCH_ACTIONS.RESUMED,
        minute: minutes,
        part: currentMatch.currentPart,
        timestamp: +(new Date())
      },
      ...log,
    ]
  };
}

export function addFinishPart(currentMatch) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));
  const currentPart = parseInt(currentMatch.currentPart);

  return {
    ...currentMatch,
    status: MATCH_STATUS.BETWEEN_PART,
    currentPart: currentPart + 1,
    currentPartTime: 0,
    log: [
      {
        action: MATCH_ACTIONS.PART_FINISHED,
        minute: minutes,
        part: currentPart,
        timestamp: +(new Date())
      },
      ...log,
    ]
  };
}

export function addFinishMatch(currentMatch) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    status: MATCH_STATUS.FINISHED,
    log: [
      {
        action: MATCH_ACTIONS.MATCH_FINISHED,
        minute: minutes,
        part: currentMatch.currentPart,
        timestamp: +(new Date())
      },
      ...log,
    ]
  };
}

export function addSuspendMatch(currentMatch) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    status: MATCH_STATUS.SUSPENDED,
    log: [
      {
        action: MATCH_ACTIONS.SUSPEND,
        minute: minutes,
        part: currentMatch.currentPart,
        timestamp: +(new Date())
      },
      ...log,
    ]
  };
}

export function addUsOwnGoal(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    score: currentMatch.score,
    scoreRival: currentMatch.scoreRival + 1,
    summary: {
      ...summary,
      goals: {
        total: currentMatch.summary.goals.total + 1,
        us: currentMatch.summary.goals.us,
        rival: currentMatch.summary.goals.rival + 1
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.OWN_GOAL,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalOwnGoal(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    score: currentMatch.score + 1,
    scoreRival: currentMatch.scoreRival,
    summary: {
      ...summary,
      goals: {
        total: currentMatch.summary.goals.total + 1,
        us: currentMatch.summary.goals.us + 1,
        rival: currentMatch.summary.goals.rival
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.OWN_GOAL,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsCorner(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    summary: {
      ...summary,
      corners: {
        total: currentMatch.summary.corners.total + 1,
        us: currentMatch.summary.corners.us + 1,
        rival: currentMatch.summary.corners.rival
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CORNER,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalCorner(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,

    summary: {
      ...summary,
      corners: {
        total: currentMatch.summary.corners.total + 1,
        us: currentMatch.summary.corners.us,
        rival: currentMatch.summary.corners.rival + 1
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CORNER,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsOffside(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    summary: {
      ...summary,
      offsides: {
        total: currentMatch.summary.offsides.total + 1,
        us: currentMatch.summary.offsides.us + 1,
        rival: currentMatch.summary.offsides.rival
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.OFFSIDE,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalOffside(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    summary: {
      ...summary,
      offsides: {
        total: currentMatch.summary.offsides.total + 1,
        us: currentMatch.summary.offsides.us,
        rival: currentMatch.summary.offsides.rival + 1
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.OFFSIDE,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsFault(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  return {
    ...currentMatch,
    summary: {
      ...summary,
      faults: {
        total: currentMatch.summary.faults.total + 1,
        us: currentMatch.summary.faults.us + 1,
        rival: currentMatch.summary.faults.rival
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.FAULT,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalFault(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));
  //TODO: add card
  return {
    ...currentMatch,
    summary: {
      ...summary,
      faults: {
        total: currentMatch.summary.faults.total + 1,
        us: currentMatch.summary.faults.us,
        rival: currentMatch.summary.faults.rival + 1
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.FAULT,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsChance(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const chanceOnTarget = actionInfo.actionInfo.onTarget === 'on-target' ? true : false;
  return {
    ...currentMatch,
    summary: {
      ...summary,
      chances: {
        total: currentMatch.summary.chances.total + 1,
        us: currentMatch.summary.chances.us + 1,
        rival: currentMatch.summary.chances.rival
      },
      onTarget: {
        total: currentMatch.summary.onTarget.total + (chanceOnTarget ? 1 : 0),
        us: currentMatch.summary.onTarget.us + (chanceOnTarget ? 1 : 0),
        rival: currentMatch.summary.onTarget.rival
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CHANCE,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalChance(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const chanceOnTarget = actionInfo.actionInfo.onTarget === 'on-target' ? true : false;
  return {
    ...currentMatch,
    summary: {
      ...summary,
      chances: {
        total: currentMatch.summary.chances.total + 1,
        us: currentMatch.summary.chances.us,
        rival: currentMatch.summary.chances.rival + 1
      },
      onTarget: {
        total: currentMatch.summary.onTarget.total + (chanceOnTarget ? 1 : 0),
        us: currentMatch.summary.onTarget.us,
        rival: currentMatch.summary.onTarget.rival + (chanceOnTarget ? 1 : 0)
      }
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CHANCE,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsGoal(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const penalty = actionInfo.actionInfo.shoot === 'penalty';

  return {
    ...currentMatch,
    score: currentMatch.score + 1,
    scoreRival: currentMatch.scoreRival,
    summary: {
      ...summary,
      penalties: {
        total: penalty ? currentMatch.summary.penalties.total + 1 : currentMatch.summary.penalties.total,
        us: penalty ? currentMatch.summary.penalties.us + 1 : currentMatch.summary.penalties.us,
        rival: currentMatch.summary.penalties.rival
      },
      goals: {
        total: currentMatch.summary.goals.total + 1,
        us: currentMatch.summary.goals.us + 1,
        rival: currentMatch.summary.goals.rival
      },
      chances: {
        total: currentMatch.summary.chances.total + 1,
        us: currentMatch.summary.chances.us + 1,
        rival: currentMatch.summary.chances.rival
      },
      onTarget: {
        total: currentMatch.summary.onTarget.total + 1,
        us: currentMatch.summary.onTarget.us + 1,
        rival: currentMatch.summary.onTarget.rival
      },
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.GOAL,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalGoal(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const penalty = actionInfo.actionInfo.shoot === 'penalty';

  return {
    ...currentMatch,
    score: currentMatch.score,
    scoreRival: currentMatch.scoreRival + 1,
    summary: {
      ...summary,
      penalties: {
        total: penalty ? currentMatch.summary.penalties.total + 1 : currentMatch.summary.penalties.total,
        rival: penalty ? currentMatch.summary.penalties.rival + 1 : currentMatch.summary.penalties.rival,
        us: currentMatch.summary.penalties.us,
      },
      goals: {
        total: currentMatch.summary.goals.total + 1,
        us: currentMatch.summary.goals.us,
        rival: currentMatch.summary.goals.rival + 1
      },
      chances: {
        total: currentMatch.summary.chances.total + 1,
        us: currentMatch.summary.chances.us,
        rival: currentMatch.summary.chances.rival + 1
      },
      onTarget: {
        total: currentMatch.summary.onTarget.total + 1,
        us: currentMatch.summary.onTarget.us,
        rival: currentMatch.summary.onTarget.rival + 1
      },
    },
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.GOAL,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsCard(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const { card } = actionInfo.actionInfo

  const summaryCards = card === 'red'
    ? {
      redCards: {
        total: currentMatch.summary.redCards.total + 1,
        us: currentMatch.summary.redCards.us + 1,
        rival: currentMatch.summary.redCards.rival
      }
    }
    : {
      yellowCards: {
        total: currentMatch.summary.yellowCards.total + 1,
        us: currentMatch.summary.yellowCards.us + 1,
        rival: currentMatch.summary.yellowCards.rival
      }
    };

  const { lineup } = currentMatch;
  const playerId = actionInfo.player.id;
  const newLineup = addCardToPlayerOnMatchLineup(lineup, playerId, card)

  return {
    ...currentMatch,
    summary: {
      ...summary,
      ...summaryCards
    },
    lineup: newLineup,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CARD,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalCard(currentMatch, actionInfo) {
  const { log, summary } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const { card } = actionInfo.actionInfo

  const summaryCards = card === 'red'
    ? {
      redCards: {
        total: currentMatch.summary.redCards.total + 1,
        us: currentMatch.summary.redCards.us,
        rival: currentMatch.summary.redCards.rival + 1
      }
    }
    : {
      yellowCards: {
        total: currentMatch.summary.yellowCards.total + 1,
        us: currentMatch.summary.yellowCards.us,
        rival: currentMatch.summary.yellowCards.rival + 1
      }
    };

  const { rivalLineup } = currentMatch;
  const playerId = actionInfo.player.id;
  const newLineup = addCardToPlayerOnMatchLineup(rivalLineup, playerId, card)

  return {
    ...currentMatch,
    summary: {
      ...summary,
      ...summaryCards
    },
    rivalLineup: newLineup,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CARD,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsSubstitution(currentMatch, actionInfo) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));
  const { substitutionPlayer } = actionInfo.actionInfo;
  const currentPlayerId = actionInfo.player.id;

  const currentLineup = currentMatch.lineup;
  let currentPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === currentPlayerId) currentPlayerIndex = i;
  })
  if (currentPlayerIndex >= 0) currentLineup[currentPlayerIndex] = substitutionPlayer;

  const currentBench = currentMatch.bench;
  let benchPlayerIndex = -1;
  currentBench.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === substitutionPlayer.id) benchPlayerIndex = i;
  });
  if (benchPlayerIndex >= 0) currentBench.splice(benchPlayerIndex, 1);

  if (currentMatch.volatileChanges) {
    currentBench.push(actionInfo.player);
  }

  return {
    ...currentMatch,
    lineup: currentLineup,
    bench: currentBench,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.SUBSTITUTION,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalSubstitution(currentMatch, actionInfo) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));
  const { substitutionPlayer } = actionInfo.actionInfo;

  const currentPlayerId = actionInfo.player.id;

  const currentLineup = currentMatch.rivalLineup;
  let currentPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === currentPlayerId) currentPlayerIndex = i;
  })
  if (currentPlayerIndex >= 0) currentLineup[currentPlayerIndex] = substitutionPlayer;

  // const currentBench = currentMatch.bench;
  // let benchPlayerIndex = -1;
  // currentBench.forEach((lineupPlayer, i) => {
  //   if (lineupPlayer.id === substitutionPlayer.id) benchPlayerIndex = i;
  // });
  // if (benchPlayerIndex >= 0) currentBench.splice(benchPlayerIndex, 1);

  // if (currentMatch.volatileChanges) {
  //   currentBench.push(actionInfo.player);
  // }

  return {
    ...currentMatch,
    rivalLineup: currentLineup,
    // bench: currentBench,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.SUBSTITUTION,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsChangePos(currentMatch, actionInfo) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const { changePosPlayer } = actionInfo.actionInfo;
  const currentPlayer = actionInfo.player;

  const currentLineup = currentMatch.lineup;
  let currentPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === currentPlayer.id) currentPlayerIndex = i;
  })

  let changePosPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === changePosPlayer.id) changePosPlayerIndex = i;
  })

  if (currentPlayerIndex >= 0 && changePosPlayerIndex >= 0) {
    currentLineup[currentPlayerIndex] = changePosPlayer;
    currentLineup[changePosPlayerIndex] = currentPlayer;
  }

  return {
    ...currentMatch,
    lineup: currentLineup,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CHANGE_POS,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalChangePos(currentMatch, actionInfo) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));

  const { changePosPlayer } = actionInfo.actionInfo;
  const currentPlayer = actionInfo.player;

  const currentLineup = currentMatch.rivalLineup;
  let currentPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === currentPlayer.id) currentPlayerIndex = i;
  })

  let changePosPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === changePosPlayer.id) changePosPlayerIndex = i;
  })

  if (currentPlayerIndex >= 0 && changePosPlayerIndex >= 0) {
    currentLineup[currentPlayerIndex] = changePosPlayer;
    currentLineup[changePosPlayerIndex] = currentPlayer;
  }

  return {
    ...currentMatch,
    rivalLineup: currentLineup,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CHANGE_POS,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addUsChangeFormation(currentMatch, actionInfo) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));
  const { newFormation } = actionInfo.actionInfo

  return {
    ...currentMatch,
    formation: newFormation,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CHANGE_FORMATION,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

export function addRivalChangeFormation(currentMatch, actionInfo) {
  const { log } = currentMatch;
  const { minutes } = getPartTimeFromSeconds(getTimePlayed(currentMatch));
  const { newFormation } = actionInfo.actionInfo

  return {
    ...currentMatch,
    rivalFormation: newFormation,
    log: [
      {
        ...actionInfo,
        action: MATCH_ACTIONS.CHANGE_FORMATION,
        minute: minutes,
        part: currentMatch.currentPart
      },
      ...log
    ]
  };
}

/*************************************/
/********** UNDO ACTIONS *************/
/*************************************/

const undoActionFunctions = {
  us_own_goal: undoUsOwnGoal,
  rival_own_goal: undoRivalOwnGoal,
  us_goal: undoUsGoal,
  rival_goal: undoRivalGoal,
  us_corner: undoUsCorner,
  rival_corner: undoRivalCorner,
  us_offside: undoUsOffside,
  rival_offside: undoRivalOffside,
  us_fault: undoUsFault,
  rival_fault: undoRivalFault,
  us_chance: undoUsChance,
  rival_chance: undoRivalChance,
  us_card: undoUsCard,
  rival_card: undoRivalCard,
  us_substitution: undoUsSubstitution,
  rival_substitution: undoRivalSubstitution,
  us_change_pos: undoUsChangePos,
  rival_change_pos: undoRivalChangePos,
  us_change_formation: undoUsChangeFormation,
  rival_change_formation: undoRivalChangeFormation,
}

function removeSummaryStat(summary, stat, type, number = 1) {
  return {
    ...summary,
    [stat]: {
      total: summary[stat].total - number,
      us: summary[stat].us - (type === 'us' ? number : 0),
      rival: summary[stat].rival - (type === 'rival' ? number : 0),
    }
  }
}

function undoUsChangeFormation(currentMatch) {
  const { initialFormation } = currentMatch;

  const changeFormationActions = currentMatch.log
    .slice(1)
    .filter(logItem => logItem.type === 'us' && logItem.action === MATCH_ACTIONS.CHANGE_FORMATION);

  const lastFormation = changeFormationActions && changeFormationActions[0] ? changeFormationActions[0] : initialFormation;

  return {
    ...currentMatch,
    formation: lastFormation
  };
}

function undoRivalChangeFormation(currentMatch) {
  const { initialRivalFormation } = currentMatch;

  const changeFormationActions = currentMatch.log
    .slice(1)
    .filter(logItem => logItem.type === 'rival' && logItem.action === MATCH_ACTIONS.CHANGE_FORMATION);

  const lastFormation = changeFormationActions && changeFormationActions[0] ? changeFormationActions[0] : initialRivalFormation;

  return {
    ...currentMatch,
    formation: lastFormation
  };
}

function undoUsChangePos(currentMatch, lastAction) {
  const { changePosPlayer } = lastAction.actionInfo;
  const currentPlayer = lastAction.player;
  const currentLineup = currentMatch.lineup;

  let currentPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === currentPlayer.id) currentPlayerIndex = i;
  })

  let changePosPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === changePosPlayer.id) changePosPlayerIndex = i;
  })

  if (currentPlayerIndex >= 0 && changePosPlayerIndex >= 0) {
    currentLineup[currentPlayerIndex] = currentPlayer;
    currentLineup[changePosPlayerIndex] = changePosPlayer;
  }

  return { ...currentMatch, lineup: currentLineup };
}

function undoRivalChangePos(currentMatch, lastAction) {
  // const { substitutionPlayer } = lastAction.actionInfo;
  // const currentPlayer = lastAction.player;
  // const currentLineup = currentMatch.lineup;

  // let currentPlayerIndex = -1;
  // currentLineup.forEach((lineupPlayer, i) => {
  //   if (lineupPlayer.id === substitutionPlayer.id) currentPlayerIndex = i;
  // })
  // if (currentPlayerIndex >= 0) currentLineup[currentPlayerIndex] = currentPlayer;

  // const currentBench = currentMatch.bench;
  // currentBench.push(substitutionPlayer);

  // return { ...currentMatch, lineup: currentLineup, bench: currentBench };

  return currentMatch;
}

function undoUsSubstitution(currentMatch, lastAction) {
  const { substitutionPlayer } = lastAction.actionInfo;
  const currentPlayer = lastAction.player;
  const currentLineup = currentMatch.lineup;

  let currentPlayerIndex = -1;
  currentLineup.forEach((lineupPlayer, i) => {
    if (lineupPlayer.id === substitutionPlayer.id) currentPlayerIndex = i;
  })
  if (currentPlayerIndex >= 0) currentLineup[currentPlayerIndex] = currentPlayer;

  const currentBench = currentMatch.bench;
  currentBench.push(substitutionPlayer);

  return { ...currentMatch, lineup: currentLineup, bench: currentBench };
}

function undoRivalSubstitution(currentMatch, lastAction) {
  // const { substitutionPlayer } = lastAction.actionInfo;
  // const currentPlayer = lastAction.player;
  // const currentLineup = currentMatch.lineup;

  // let currentPlayerIndex = -1;
  // currentLineup.forEach((lineupPlayer, i) => {
  //   if (lineupPlayer.id === substitutionPlayer.id) currentPlayerIndex = i;
  // })
  // if (currentPlayerIndex >= 0) currentLineup[currentPlayerIndex] = currentPlayer;

  // const currentBench = currentMatch.bench;
  // currentBench.push(substitutionPlayer);

  // return { ...currentMatch, lineup: currentLineup, bench: currentBench };

  return currentMatch;
}

function undoUsCard(currentMatch, lastAction) {
  const { summary } = currentMatch;
  const { card } = lastAction.actionInfo;
  const { lineup } = currentMatch;
  const playerId = lastAction.player.id;

  const newSummary = card === 'red'
    ? removeSummaryStat(summary, 'redCards', 'us')
    : removeSummaryStat(summary, 'yellowCards', 'us')

  const newLineup = removeCardToPlayerOnMatchLineup(lineup, playerId, card);

  return { ...currentMatch, summary: newSummary, lineup: newLineup, };
}

function undoRivalCard(currentMatch, lastAction) {
  const { summary } = currentMatch;
  const { card } = lastAction.actionInfo;
  // const { lineup } = currentMatch;
  // const playerId = lastAction.player.id;

  const newSummary = card === 'red'
    ? removeSummaryStat(summary, 'redCards', 'rival')
    : removeSummaryStat(summary, 'yellowCards', 'rival')

  // const newLineup = removeCardToPlayerOnMatchLineup(lineup, playerId, card);

  // return { ...currentMatch, summary: newSummary, lineup: newLineup, };
  return { ...currentMatch, summary: newSummary };
}

function undoUsChance(currentMatch, lastAction) {
  const { summary } = currentMatch;
  const chanceOnTarget = lastAction.actionInfo.onTarget === 'on-target' ? true : false;

  let newSummary = removeSummaryStat(summary, 'chances', 'us')
  if (chanceOnTarget) {
    newSummary = removeSummaryStat(newSummary, 'onTarget', 'us')
  }

  return { ...currentMatch, summary: newSummary };
}

function undoRivalChance(currentMatch, lastAction) {
  const { summary } = currentMatch;
  const chanceOnTarget = lastAction.actionInfo.onTarget === 'on-target' ? true : false;

  let newSummary = removeSummaryStat(summary, 'chances', 'rival')
  if (chanceOnTarget) {
    newSummary = removeSummaryStat(newSummary, 'onTarget', 'rival')
  }

  return { ...currentMatch, summary: newSummary };
}

function undoUsFault(currentMatch) {
  const { summary } = currentMatch;

  return { ...currentMatch, summary: removeSummaryStat(summary, 'faults', 'us') };
}

function undoRivalFault(currentMatch) {
  const { summary } = currentMatch;

  return { ...currentMatch, summary: removeSummaryStat(summary, 'faults', 'rival') };
}

function undoUsOffside(currentMatch) {
  const { summary } = currentMatch;
  return { ...currentMatch, summary: removeSummaryStat(summary, 'offsides', 'us') };
}

function undoRivalOffside(currentMatch) {
  const { summary } = currentMatch;
  return { ...currentMatch, summary: removeSummaryStat(summary, 'offsides', 'rival') };
}

function undoUsCorner(currentMatch) {
  const { summary } = currentMatch;
  return { ...currentMatch, summary: removeSummaryStat(summary, 'corners', 'us') };
}

function undoRivalCorner(currentMatch) {
  const { summary } = currentMatch;
  return { ...currentMatch, summary: removeSummaryStat(summary, 'corners', 'rival') };
}

function undoUsGoal(currentMatch, lastAction) {
  const { summary } = currentMatch;
  const penalty = lastAction.actionInfo.shoot === 'penalty';

  let newSummary = removeSummaryStat(summary, 'goals', 'us');
  if (penalty) {
    newSummary = removeSummaryStat(newSummary, 'penalties', 'us');
  }

  return {
    ...currentMatch,
    score: currentMatch.score - 1,
    summary: newSummary
  }
}

function undoRivalGoal(currentMatch, lastAction) {
  const { summary } = currentMatch;
  const penalty = lastAction.actionInfo.shoot === 'penalty';

  let newSummary = removeSummaryStat(summary, 'goals', 'rival');
  if (penalty) {
    newSummary = removeSummaryStat(newSummary, 'penalties', 'rival');
  }

  return {
    ...currentMatch,
    scoreRival: currentMatch.scoreRival - 1,
    summary: newSummary
  }
}

function undoUsOwnGoal(currentMatch) {
  const { summary } = currentMatch;
  return {
    ...currentMatch,
    scoreRival: currentMatch.scoreRival - 1,
    summary: removeSummaryStat(summary, 'goals', 'rival') //inverted - it's OWN goal
  }
}

function undoRivalOwnGoal(currentMatch) {
  const { summary } = currentMatch;
  return {
    ...currentMatch,
    score: currentMatch.score - 1,
    summary: removeSummaryStat(summary, 'goals', 'us') //inverted - it's OWN goal
  }
}

export function undoLastAction(currentMatch) {
  const { log } = currentMatch;
  const lastAction = log[0];

  if (!lastAction) return currentMatch;

  const actionName = `${lastAction.type}_${lastAction.action}`;
  const undoActionFunction = undoActionFunctions[actionName]

  if (!undoActionFunction || !log || log.length === 0) return currentMatch;

  const matchUpdated = undoActionFunction(currentMatch, lastAction);

  return {
    ...matchUpdated,
    log: [
      ...currentMatch.log.slice(1)
    ]
  }

}

const defaultCards = { yellow: 0, red: 0 };
export function getPlayerCards(log, playerId) {
  if (!log || log.length === 0 || !playerId) return defaultCards;

  return log.reduce((cards, l) => {
    if (l.action === 'card' && l.player.id === playerId) {
      const cardType = l.actionInfo.card;
      return {
        ...cards,
        [cardType]: cards[cardType] + 1
      }
    }
    return cards;
  }, defaultCards);
}


export function getInitialLineup(match, type = 'us') {
  let newLineup = [...match.lineup];
  match.log.forEach(logItem => {
    if (logItem.action !== 'substitution' || logItem.type !== type) return;
    const oldPlayer = logItem.player;
    const newPlayer = logItem.actionInfo.substitutionPlayer;

    const newPlayerLineupIndex = newLineup.findIndex(player => player.id === newPlayer.id);
    newLineup = [...newLineup.slice(0, newPlayerLineupIndex), oldPlayer, ...newLineup.slice(newPlayerLineupIndex + 1)];
  })
  return newLineup;
}

export function getMatchSubstitutions(match, type = 'us') {
  if (!match || !match.log) return [];
  return match.log
    .filter(logItem => logItem.action === 'substitution' && logItem.type === type)
    .map(logItem => ({
      playerIn: logItem.actionInfo.substitutionPlayer,
      playerOut: logItem.player,
      part: logItem.part,
      minute: logItem.minute
    }))
}

export function getMathFormationChanges(match, type = 'us') {
  if (!match || !match.log) return [];
  let previousFormation = match.initialFormation;
  return match.log
    .filter(logItem => logItem.action === 'change_formation' && logItem.type === type)
    .reverse()
    .map(logItem => {
      const formationItem = {
        oldFormation: previousFormation,
        newFormation: logItem.actionInfo.newFormation,
        part: logItem.part,
        minute: logItem.minute
      }
      previousFormation = logItem.actionInfo.newFormation;
      return formationItem;
    })
    .reverse();
}

export function getMatchPlayersStats(match, type = 'us') {
  if (!match || !match.log) return [];
  let allPlayers = [...(type === 'us' ? match.lineup : match.rivalLineup)];
  allPlayers = [...allPlayers, ...(type === 'us' ? match.bench : [])];

  return allPlayers.map(player => {
    const stats = match.log
      .filter(logItem => (
        logItem.type === type
        && playerStatsTypes.includes(logItem.action)
        && logItem.player.id === player.id
      ))
      .reduce((totalStats, log) => {
        const statName = log.action === 'card' ? `${log.actionInfo.card}Cards` : log.action

        return {
          ...totalStats,
          [statName]: totalStats[statName] + 1
        }

      }, playerDefaultStats);

    const statsFromRival = match.log
      .filter(logItem => (
        logItem.type !== type
        && rivalPlayerStatsTypes.includes(logItem.action)
      ))
      .reduce((totalStats, log) => {
        const statName = log.action === 'card' ? `${log.actionInfo.card}CardsRival` : `${log.action}Rival`

        if (log.action === 'goal' && (player.role !== 'gk' || !isPlayerPlaying(match, player, type, log.part, log.minute))) return totalStats;

        return {
          ...totalStats,
          [statName]: totalStats[statName] + 1
        }

      }, rivalPlayerDefaultStats);

    const minutesPlayed = getPlayerMinutesPlayed(match, player, type)
    return {
      ...player,
      stats: {
        ...(type === 'us' && stats),
        ...(type !== 'us' && statsFromRival),
        minutesPlayed
      }
    }
  })
}

function isPlayerPlaying(match, player, type, part, minute) {
  const initialLineup = getInitialLineup(match, type)
  let isPlaying = initialLineup.findIndex(lineupPlayer => lineupPlayer.id === player.id) >= 0
  let isPlayingAtGivenPartAndMinute = isPlaying

  const chronologicalLog = match.log.slice().reverse();
  chronologicalLog.forEach(logItem => {
    // Player is substituted
    if (isPlaying && logItem.action === MATCH_ACTIONS.SUBSTITUTION && logItem.player.id === player.id) {
      isPlaying = false;
      isPlayingAtGivenPartAndMinute = Number(part) < Number(logItem.part) || (Number(part) === Number(logItem.part) && Number(minute) <= Number(logItem.minute));
    }
    // Player enters in substitution
    if (!isPlaying && logItem.action === MATCH_ACTIONS.SUBSTITUTION && logItem.actionInfo.substitutionPlayer.id === player.id) {
      isPlaying = true;
      isPlayingAtGivenPartAndMinute = Number(part) > Number(logItem.part) || (Number(part) === Number(logItem.part) && Number(minute) >= Number(logItem.minute));
    }
  });

  return isPlayingAtGivenPartAndMinute
}

function getPlayerMinutesPlayed(match, player, type) {
  const initialLineup = getInitialLineup(match, type)
  let isPlaying = initialLineup.findIndex(lineupPlayer => lineupPlayer.id === player.id) >= 0
  let totalMinutes = 0;
  let lastActionMinute = 0;
  let currentPart = 0;

  const chronologicalLog = match.log.slice().reverse();
  chronologicalLog.forEach(logItem => {
    // Math starts
    if (logItem.action === MATCH_ACTIONS.STARTED) {
      currentPart = logItem.part;
      lastActionMinute = 0;
      return;
    }
    // Match finishes and didn't had last part finished
    if (isPlaying && logItem.action === MATCH_ACTIONS.MATCH_FINISHED) {
      if (logItem.part === currentPart) {
        totalMinutes += parseInt(logItem.minute) - lastActionMinute;
      }
      return;
    }
    // Match part finishes
    if (isPlaying && logItem.action === MATCH_ACTIONS.PART_FINISHED) {
      currentPart += 1;
      totalMinutes += parseInt(logItem.minute) - lastActionMinute;
      return;
    }
    // Player is substituted
    if (isPlaying && logItem.action === MATCH_ACTIONS.SUBSTITUTION && logItem.player.id === player.id) {
      totalMinutes += parseInt(logItem.minute) - lastActionMinute;
      isPlaying = false;
      return;
    }
    // Player enters in substitution
    if (!isPlaying && logItem.action === MATCH_ACTIONS.SUBSTITUTION && logItem.actionInfo.substitutionPlayer.id === player.id) {
      isPlaying = true;
      lastActionMinute = logItem.minute;
      return;
    }
  })

  if (match.status === MATCH_STATUS.PLAYING && isPlaying) {
    totalMinutes += parseInt(match.currentPartTime) - lastActionMinute;
  }

  return totalMinutes;
}

export function getPlayerClassColor(player) {
  if (player.stats.yellowCards > 1 || player.stats.redCards > 0) return 'red';
  if (player.stats.yellowCards > 0) return 'yellow';
  return '';
}