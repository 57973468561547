import React from 'react';
import Button from './Button';

const ButtonPlayField = ({ children, onClick, cards, ...otherProps }) => {
  const buttonColor = ['playfield']

  if (cards) {
    buttonColor.push(cards.red > 0 || cards.yellow > 1? 'red-card' : (cards.yellow === 1 ? 'yellow-card' : ''));
  }

  return (
    <Button
      size="round"
      color={buttonColor.join(' ')}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

export default ButtonPlayField;
