import { useState, useEffect } from 'react';

const useFetch = (fetchFunction, dependencies = []) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const doFetch = async () => {
    setLoading(true);
    await fetchFunction(setData);
    setLoading(false);
  };

  useEffect(() => {
    doFetch();
  }, dependencies);

  const reFetch = () => doFetch();

  return [loading, data, reFetch];
}

export default useFetch;