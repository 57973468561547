import React from 'react';

import './Text.scss';

const Text = ({ children }) => {
  return (
    <p className="app-text">{children}</p>
  )
}
 
export default Text;