import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { upsertItem } from '../../services/database';
import { setMatch } from '../../redux/actions/matchActions';
import { useTeamPlayers } from '../../customHooks';
import getTranslation from '../../logic/translations';
import { formations, getLineupLayout, getLineupPositionName, DEFAULT_BASE_NUMBER, getUnnumberedPlayerNumber } from '../../logic/football';
import { SectionTitle, AppContainer, ModalButtons } from '../../components/Layout';
import { HorizontalField, Select, Input } from '../../components/Form';
import TagTrans from '../../components/TagTrans';
import PlayField from '../../components/PlayField';
import PlayersList from '../../components/PlayersList';
import { ButtonDarkBig, ButtonGreenBig } from '../../components/Button';

const getLineupPlayers = lineup => lineup ? lineup.flat().filter(player => !!player) : [];
const removePlayerFromBench = (bench, player) => bench.filter(p => p.id !== player.id)

const Lineup = ({ onGoNext }) => {
  const match = useSelector(state => state.match);
  const lang = useSelector(state => state.lang);
  const teamPlayers = useTeamPlayers(match.team);
  const dispatch = useDispatch();

  const formOptions = formations[match.mode].map(f => ({ label: f.split('').join('-'), value: f } ));
  
  const defaultFormation = formOptions[0];
  const [addingPlayerPosition, setAddingPlayerPosition] = useState({});
  const [formation, setFormation] = useState(defaultFormation);
  const [selectedPlayers, setSelectedPlayers] = useState(match.totalPlayers || 0);
  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [showAddBench, setShowAddBench] = useState(false);

  const defaultLineup = getLineupLayout({ mode: match.mode, formation:defaultFormation.value });
  const [lineup, setLineup] = useState(defaultLineup);
  const [bench, setBench] = useState(match.bench || []);

  useEffect(() => {
    const newLineup = getLineupLayout({ mode: match.mode, formation: formation.value, lineup });
    setLineup(newLineup);
  }, [formation, match.mode]);

  useEffect(() => {
    const totalPlayers = getLineupPlayers(lineup);
    setSelectedPlayers(totalPlayers.length + bench.length)
  }, [lineup, bench])


  const handlePlayerClick = (position) => {
    setShowAddPlayer(true);
    setAddingPlayerPosition(position);
  }

  const addPlayerToLineup = player => {
    const newLineup = lineup.map(line => (
      line.map(position => position && position.id === player.id ? null : position)
    ));
    const { i, j } = addingPlayerPosition;
    player.position = getLineupPositionName(newLineup, i, j);
    player.positionRow = i;
    player.positionColumn = j;
    if (!player.number) player.number = getUnnumberedPlayerNumber(newLineup, DEFAULT_BASE_NUMBER + i + j);
    newLineup[i][j] = player;
    setLineup(newLineup);
    setBench([...removePlayerFromBench(bench, player)]);
    setShowAddPlayer(false);
  }

  const addMultiplePlayersToBench = players => {
    setShowAddBench(false);
    setBench(players);
  }

  const goToMatch = async () => {
    const matchUpdated = {
      ...match,
      lineup: getLineupPlayers(lineup),
      formation: formation.value,
      initialFormation: formation.value,
      bench
    };
    const result = await upsertItem('matches', matchUpdated, matchUpdated.id);
    if (result) {
      dispatch(setMatch({ ...match }));
      onGoNext(matchUpdated.id)
    }
  }

  const getNoLineupPlayers = useCallback((teamPlayers) => {
    const lineupPlayers = getLineupPlayers(lineup);
    return (
      lineupPlayers && lineupPlayers.length > 0
        ? teamPlayers.filter(teamPlayer => (
          !lineupPlayers.some(lineupPlayer => (
            lineupPlayer.id === teamPlayer.id
          ))
        ))
        : teamPlayers
    )
  }, [lineup])

  return (
    <div className="lineup-selection">
      <SectionTitle><TagTrans tag="lineup_selection" /></SectionTitle>
      <AppContainer paddingH>
        <div className="controls">
          <HorizontalField label={getTranslation('formation', lang)} name="formation" width={60}>
            <Select 
              name="formation"
              options={formOptions} 
              value={formation.value} 
              onChange={({ target }) => setFormation(target)} 
            />
          </HorizontalField>
          <HorizontalField 
            label={getTranslation('selected_players', lang)} 
            labelSize="tiny" 
            name="selected-players"
            width={35}
          >
            <Input 
              name="selected-players"
              value={selectedPlayers} 
              readOnly
              disabled
              size="tiny"
            />
          </HorizontalField>
        </div>
      </AppContainer>
      <AppContainer>
        <PlayField lineup={lineup} onPlayerClick={handlePlayerClick} />
      </AppContainer>
      <ModalButtons>
        <ButtonDarkBig onClick={() => setShowAddBench(true)}>
          <TagTrans tag="bench"/>&nbsp;({bench.length})
        </ButtonDarkBig>
        <ButtonGreenBig onClick={goToMatch} disabled={!lineup || !lineup[0] || !lineup[0][0]}>
          <TagTrans tag="go_to_match" />
        </ButtonGreenBig>
      </ModalButtons>
      <PlayersList 
        show={showAddPlayer} 
        onCancel={() => setShowAddPlayer(false)} 
        onPlayerClick={player => addPlayerToLineup(player)}
        players={teamPlayers}
      />
      <PlayersList 
        show={showAddBench} 
        onCancel={() => setShowAddBench(false)} 
        onPlayersSelected={addMultiplePlayersToBench}
        players={getNoLineupPlayers(teamPlayers)}
        selectedPlayers={match.bench}
        multiple
      />
    </div>
  );
}
 
export default Lineup;
