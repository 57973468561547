import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useForm from '@bit/iamtechnologies.iamtech-js.use-form';

import AddPlayerForm from './AddPlayerForm';
import { addItem, upsertItem } from '../../services/database';
import TagTrans from '../TagTrans';
import { ButtonGreenBig, ButtonGrayBig } from '../Button';
import { SectionTitle, ModalButtons, AppContainer, Box, Separator } from '../Layout';


const AddPlayer = ({ onFinish, team, player = {}, rival = false }) => {
  const user = useSelector(state => state.user);
  
  const defaultValues = {
    name: player && player.name ? player.name : '',
    lastname: player && player.lastname ? player.lastname : '',
    role: player && player.role? player.role : '',
    position: player && player.position ? player.position : '',
    foot: player && player.foot ? player.foot : 'right',
    number: player && player.number ? player.number : ''
  };
  
  const { values, useInput, isValid, errors, showErrors, setValues } = useForm(defaultValues);

  useEffect(() => { 
    setValues(defaultValues);
  }, [player && player.name, player && player.lastname, player && player.role, player && player.foot, player && player.number]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValid) {
      if (rival) {
        onFinish({
          ...values,
          id: `rival-${values.number}-${+new Date()}`
        });
      } else {
        if (player && player.id) {
          const updatePlayer = { ...player, ...values };
          await upsertItem('players', updatePlayer, player.id)
        } else {
          const newPlayer = { ...values, team, user: user.id };
          await addItem('players', newPlayer);
        }
        onFinish();
      }
      setValues(defaultValues);
    }
  }

  return (
    <>
      <SectionTitle><TagTrans tag="add_player" /></SectionTitle>
      <AppContainer padding>
        <Box>
          <AddPlayerForm 
            useInput={useInput}
            errors={errors}
            showErrors={showErrors}
            onSubmit={handleSubmit} 
            player={player}
            values={values}
            rival
          />
          <Separator />
        </Box>
        <ModalButtons>
          <ButtonGrayBig onClick={() => onFinish()}><TagTrans tag="cancel" /></ButtonGrayBig>
          <ButtonGreenBig onClick={handleSubmit}>
            {player && player.id 
              ? <TagTrans tag="save_player" />
              : <>+ <TagTrans tag="add_player" /></>
            }</ButtonGreenBig>
        </ModalButtons>
      </AppContainer>
    </>
  );
}
 
export default AddPlayer;