import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useForm from '@bit/iamtechnologies.iamtech-js.use-form';

import { registerAuthObserver, signup } from '../../services/auth';
import { getItem, upsertItem, addItem } from '../../services/database';
import { Box, FlexCenter, AppContainer, Separator } from '../../components/Layout';
import { FormField, Input } from '../../components/Form';
import { ButtonGreenBig } from '../../components/Button';
import getTranslation from '../../logic/translations';
import TagTrans from '../../components/TagTrans';

import loginBG from './loging-back.jpg';
import './Signup.scss';

const Signup = ({ history }) => {
  const lang = useSelector(state => state.lang);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    name: '',
    club: '',
    email: '',
    password: ''
  };

  const customErrorAttribute = {
    className: 'has-error'
  };

  const { values, useInput, isValid, errors } = useForm(defaultValues, customErrorAttribute);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValid) {
      setIsLoading(true);
      const { email, password, name, club } = values;
      const { success, data } = await signup(email, password);

      if (success) {
        const profileResult = await upsertItem('profiles', { name, email }, data )
        
        if (profileResult) {
          const clubResult = await addItem('teams', { name: club, user: data  });
          if (clubResult) history.push('/home')
        }
      }
    }
  };

  return (
    <div className="login-page" style={{backgroundImage:`url(${loginBG})`}}>
      <AppContainer padding isMainPage>
        <div className="signup-page">
          <Box isLoading={isLoading}>
            <form onSubmit={handleSubmit}>
              <FormField label={`${getTranslation('name', lang)}*`} name="name" errors={errors.name}>
                <Input name="name" {...useInput('name', 'isRequired')}/>
              </FormField>
              <FormField label={`${getTranslation('club', lang)}*`} name="club" errors={errors.club}>
                <Input name="club" {...useInput('club', 'isRequired')}/>
              </FormField>
              <FormField label={`${getTranslation('email', lang)}*`} name="email" errors={errors.email}>
                <Input name="email" type="email" {...useInput('email', 'isEmail,isRequired')}/>
              </FormField>
              <FormField label={`${getTranslation('password', lang)}*`} name="password" errors={errors.password}>
                <Input name="password" type="password" {...useInput('password', 'isRequired')}/>
              </FormField>
              <FlexCenter>
                <ButtonGreenBig 
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  <TagTrans tag="signup" />
                </ButtonGreenBig>
              </FlexCenter>
            </form>
            <Separator />
            <p>
              <TagTrans tag="yes_account_login"/>&nbsp;
              <Link to="/login">login</Link>
            </p>
          </Box>
        </div>
      </AppContainer>
    </div>
  );
};

export default Signup;
