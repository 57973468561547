import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import MatchData from './MatchData';
import Lineup from './Lineup';

import './NewReport.scss';


const NewReport = ({ history }) => {
  const [currentStage, setCurrentStage] = useState(0);

  const handleGoNext = () => setCurrentStage(stage => stage + 1);

  const goToMatch = matchId => history.push(`/match/${matchId}`);

  return (
    <div className="newreport-page">
      <div className="sections-container">
        <div className="sections-slider "style={{left:`${currentStage * -100}%`}}>
          <div className="section">
            {currentStage === 0 && <MatchData onGoNext={handleGoNext} />}
          </div>
          <div className="section">
            {currentStage === 1 && <Lineup onGoNext={goToMatch} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewReport);
